import {mapActions, mapGetters, mapState} from "vuex";
import Vue from "vue";

export default {
    data() {
        return {
            extended_resource_titles: [
                'UI-Structure',
                'Access-Group',
                'User-Settings',
            ]
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState({
            system: (state) => state.system,
            extended_schemas: (state) => state.swagger.extended_schemas
        }),
    },
    methods: {
        async get_swagger(return_swagger = false, force_api_call = true) {
            console.log("<<< Getting swagger >>>")
            if (force_api_call === true || window.location.host === 'localhost:8005') {
                let result = await this.api_get(
                    {
                        url: '/system-api-docs/' + this.system,
                        params: null,
                    }
                )
                if (return_swagger) {
                    return result.data
                }

                this.set_state_property({
                    state_property: 'local_storage_document',
                    module: 'swagger',
                    data: this.format_swagger(result.data)
                })
            } else {
                return await this.get_static_swagger(return_swagger)
            }
        },
        async get_static_swagger(return_swagger) {
            try {
                let version_response = await this.api_get(
                    {
                        url: '/version'
                    }
                )
                let version = version_response['data']

                let static_api_doc_url = `${process.env.VUE_APP_PUBLIC_LIBRARY}/api-docs/api-doc-${version}.json`
                let static_api_doc_response = await Vue.axios.get(
                    static_api_doc_url,
                    {
                        headers: {
                            'Cache-Control': 'no-cache'
                        },
                        params: undefined
                    })

                if (return_swagger) {
                    return static_api_doc_response['data']
                }

                this.set_state_property(
                    {
                        state_property: 'local_storage_document',
                        module: 'swagger',
                        data: this.format_swagger(static_api_doc_response['data'])
                    }
                )
            } catch (error) {
                return await this.get_swagger(return_swagger, true)
            }
        },
        async get_extended_swagger_schema(resource_title) {
            if (this.extended_schemas[resource_title]) return
            let swagger = await this.get_swagger(true)

            this.update_extended_schema({
                resource_title: resource_title,
                schema: swagger.components.schemas[resource_title]
            })
        },
        format_swagger(swagger) {
            delete swagger.components.schemas['UI-Structure']['properties']['structure']['properties']
            delete swagger.components.schemas['Access-Group']['properties']['access_rights']['properties']
            delete swagger.components.schemas['User-Settings']['properties']['settings']

            this.remove_schemas_with_no_access(swagger)

            return swagger
        },
        swagger_paths(resource) {
            let swagger_paths = {
                'resource_methods': {},
                'item_methods': {}
            }
            if (!this.swagger || !this.swagger.paths) return swagger_paths
            swagger_paths['resource_methods'] = this.swagger.paths[`/${resource}`] || {}
            let item_path = "/" + resource + "/{"
            for (let path in this.swagger.paths) {
                if (path.startsWith(item_path)) {
                    swagger_paths['item_methods'] = this.swagger.paths[path]
                    break
                }
            }

            return swagger_paths
        },
        reference_in_other_resource(resource, in_resource) {
            return this.swagger['x-domain-dependencies'][resource][in_resource]
        },
        remove_schemas_with_no_access(swagger) {
            let schemas_to_remove = Object.keys(swagger.components.schemas)
                .filter(x => {
                    if (x.endsWith('__id') || x.startsWith('Flow')) return false

                    let resource = null
                    for (let [path, path_dict] of Object.entries(swagger.paths)) {
                        let resource_title_in_tags = (path_dict?.get?.tags || []).includes(x)
                        if (resource_title_in_tags) {
                            resource = path.split('/')[1]
                        }
                    }


                    return !this.has_method_access(resource, 'GET')
                })

            schemas_to_remove.forEach(x => {
                delete swagger.components.schemas[x]
            })
        },
        ...mapActions({
            update_extended_schema: 'swagger/update_extended_schema',
            set_state_property: 'set_state_property'
        }),
    },
}
