import { format, isValid } from 'date-fns'

export default {
    data() {
        return {}
    },
    methods: {
        format_date(value) {
            const date = new Date(value)
            return value && isValid(date) ? format(date, 'yyyy-MM-dd') : value
        },
        format_date_time(value) {
            const date = new Date(value)
            return value && isValid(date) ? format(date,'yyyy-MM-dd HH:mm') : value
        },
    }
}
