import common_mixin from "@/mixins/common/mixin";
import {mapGetters, mapState} from "vuex";

export default {
    mixins: [common_mixin],
    data() {
        return {}
    },
    computed: {
        access_right() {
            return this.resource_access_right(this.resource)
        },
        item_ui_structure() {
            return this.custom_ui_structure ? this.custom_ui_structure : this.ui_structure[this.resource]
        },
        allow_create() {
            return this.has_post_access(this.resource)
        },
        allow_edit() {
            return this.has_patch_access(this.resource) || this.has_put_item_access(this.resource)
        },
        allow_delete() {
            return this.has_delete_access(this.resource)
        },
        resource_title() {
            let resource_title = this.resource_to_resource_title(this.resource)
            return resource_title || ''
        },
        resource_schema() {
            if (!this.resource_title || !this.swagger.components.schemas || !this.swagger['x-model-rules']) return undefined

            if (this.resource_titles_in_extended_schemas.includes(this.resource_title)) {
                return JSON.parse(JSON.stringify(this.extended_schemas[this.resource_title]))
            }
            return JSON.parse(JSON.stringify(this.swagger.components.schemas[this.resource_title]))
        },
        resource_attribute_settings() {
            if (!this.ui_structure[this.resource].attribute_settings) return {}
            return this.ui_structure[this.resource].attribute_settings
        },
        allowed_sort_items() {
            if (!this.item_ui_structure) return []
            let sort_items = this.item_ui_structure['sort']
            return this.key_locale_array(sort_items, [this.resource_title, 'item'])
        },
        allowed_text_search() {
            return this.item_ui_structure ? this.item_ui_structure['text_search'] || [] : []
        },
        allowed_filter_items() {
            if (!this.item_ui_structure || !this.resource_schema) return []
            let filter_properties = this.item_ui_structure['search_filters']

            let key_locale_array = this.key_locale_array(filter_properties, [this.resource_title, 'item'])
            let properties = this.resource_schema['properties']

            return key_locale_array.map(obj => {
                return Object.assign({}, obj, {'property': this.get_property(properties, obj.key)})
            })
        },
        card() {
            if (!this.item_ui_structure) return {}

            if (this.inner_object) {
                if (!this.item_ui_structure['inner_objects'][this.inner_object]) return {}
                return this.item_ui_structure['inner_objects'][this.inner_object]['card']
            }

            return this.item_ui_structure['card']
        },
        dialog() {
            if (!this.item_ui_structure) return {}


            if (this.inner_object) {
                return this.item_ui_structure?.inner_objects?.[this.inner_object]?.dialog
            }

            return this.item_ui_structure.dialog
        },
        display_favorite_action() {
            if (!this.swagger || !this.swagger.components.schemas) return false
            let favorites_schema = this.swagger.components.schemas['User-Favorite']['properties']['favorites']['properties']

            return this.resource in favorites_schema
        },
        sub_menu() {
            return this.item_ui_structure['sub_menu'] || []
        },
        favorite_ids() {
            if (!this.user_favorite || !this.user_favorite.favorites || !this.user_favorite.favorites[this.resource]) return []
            return this.user_favorite.favorites[this.resource].map(x => {
                return x['id']
            })
        },
        flow_action_reference() {
            if (!this.exists(this.swagger) || !this.exists(this.internal_value)) return undefined
            let reference = this.find_reference_in_other_resource("flow--actions", "reference")
            if (!reference) return false
            return {
                [reference]: this.internal_value._id
            }
        },
        resource_translate_location() {
            return this.resource_title?.toLowerCase()?.replaceAll('-', '_') || ''
        },

        ...mapGetters({
            'ui_structure': 'ui_structure',
            'swagger_loaded': 'swagger/loaded',
            'resource_domains_with_no_access_control': 'swagger/resource_domains_with_no_access_control'
        }),
        ...mapState({
            'user_favorite': state => state.user_favorite,
            'user_extended_data': state => state.user_extended_data,
            'swagger': state => state.swagger.local_storage_document,
            'resource_titles_in_extended_schemas': state => state.swagger.resource_titles_in_extended_schemas
        })
    },
    methods: {
        validate(value, property, original_value = '') {
            let status = []

            // If value is null, type is string, type is not an enum and value cannot be nullable => do not check required
            let skip_required_rule = (
                value === null &&
                property.type === 'string' &&
                !property.nullable &&
                !property.enum
            )

            // TODO: handle nullable strings
            if (property?.is_required && !skip_required_rule) {
                status.push(this.required_rule(value))
            }
            if (property?.enum && value === null && original_value) {
                status.push(this.$t(`common.value_not_allowed`))
            }
            if (property?.minLength) {
                status.push(this.min_length_rule(value || '', property.minLength))
            }
            if (property?.maxLength) {
                status.push(this.max_length_rule(value, property.maxLength))
            }
            if (property?.pattern) {
                status.push(this.field_rule(value, property.pattern, property.name))
            }
            if (property?.type === 'number') {
                status.push(this.number_rule(value))
            }
            if (property?.type === 'integer') {
                status.push(this.integer_rule(value))
            }

            let error_messages = status.filter(x => x !== true)
            if (error_messages.length) return error_messages[0]
            return true
        },
        get_icon(key, default_icon) {
            return key in this.item_ui_structure['icons'] ? this.item_ui_structure['icons'][key] : default_icon
        },
        get_card_key(key_config, item) {
            if (key_config['distinct']) {
                return key_config['distinct']
            } else if (key_config['locale']) {
                return key_config['locale']
            } else if (key_config['first_existing']) {
                for (let key of key_config['first_existing']) {
                    let value = this.get_value(item, key)
                    if (value) return key
                }
            }
            return undefined
        },
        get_card_value(item, key_config, properties) {
            if (!key_config) return ''

            if (key_config['distinct']) {
                return this.get_value(item, key_config['distinct'], properties)
            } else if (key_config['locale']) {
                return this.translate(key_config['locale'])
            } else if (key_config['first_existing']) {
                for (let key of key_config['first_existing']) {
                    let value = this.get_value(item, key, properties)
                    if (value || value === false) return value
                }
            }
        },
        get_card_value_label(item, key_config) {
            if (!key_config) return ''
            if (key_config['locale']) return key_config['locale']

            let key = undefined
            if (key_config['distinct']) {
                key = key_config['distinct']
            } else if (key_config['first_existing']) {
                for (let k of key_config['first_existing']) {
                    key = k
                    if (this.get_value(item, k)) break
                }
            } else {
                return undefined
            }
            if (key === undefined) {
                return undefined
            }

            if (key.startsWith('_')) key = key.substring(1)

            return this.locale_key(key, [this.resource_translate_location, 'item'])
        },
        get_value(item, key, properties) {
            try {
                let value = item
                let keys = key.split('.')
                if (keys.length === 1) {
                    value = value[keys[0]]
                } else {
                    keys.forEach((k) => {
                        value = value[k]
                    })
                }
                let translate = this.is_enum(key, properties)
                value = !translate ? value : this.locale_key(value, [this.resource_translate_location, 'item'])
                return value
            } catch (error) {
                return ''
            }
        },

        get_property(properties, key) {
            try {
                let property = properties
                let keys = key.split('.')
                if (keys.length === 1) {
                    property = property[keys[0]]
                } else {
                    keys.forEach((k) => {
                            while ('properties' in property || 'items' in property) {
                                property = property['properties'] || property['items']
                            }
                            property = property[k]
                        }
                    )
                }

                if (property['$ref']) {
                    property['type'] = '$ref'
                }
                return property
            } catch (error) {
                return ''
            }
        },
        is_enum(key, properties) {
            let property = this.get_property(properties, key)
            return property instanceof Object ? 'enum' in property : false
        },
        is_chip(key, resource) {
            if ('enum' in this.ui_structure[resource]) {
                if (key in this.ui_structure[resource].enum) {
                    return true
                }
            }
            return false
        },
        get_enum_color(key, value, resource) {
            try {
                if (this.ui_structure[resource].enum) {
                    if (key in this.ui_structure[resource].enum) {
                        let split = value.split(".")
                        return this.ui_structure[resource].enum[key][split[split.length - 1]].color
                    }
                }
            } catch (e) {
                return null
            }
            return null
        },
        key_value_is_boolean(key, schema_properties) {
            return this.get_property(schema_properties, key)['type'] === 'boolean'
        },
        format_item_object(obj, properties, copy_obj = true) {
            if (!properties) return obj
            let additional_datetime_properties = ["_updated", "_created"]
            let obj_copy = copy_obj ? this.deep_copy(obj) : obj

            for (let key in obj_copy) {
                if (!properties[key] && additional_datetime_properties.indexOf(key) === -1) continue

                if (typeof obj_copy[key] === "object" && key in properties) {
                    this.format_item_object(obj_copy[key], properties[key]['properties'], false)
                } else {
                    // let is_format_as_currency_property = this.deep_get(properties, `${key}.description`) === "#format_as_currency"
                    let re = new RegExp("#format_as_currency:?([A-Za-z]*)?")
                    let property_key_description = this.deep_get(properties, `${key}.description`)
                    let is_format_as_currency_property = re.test(property_key_description)
                    let is_private_key_or_date_formatted_property = key.startsWith('_') || this.deep_get(properties, `${key}.format`) === "date-time"

                    if (typeof obj_copy[key] === "string" && is_private_key_or_date_formatted_property) {
                        obj_copy[key] = this.format_date_time(obj_copy[key])
                    } else if (typeof obj[key] === "number" && is_format_as_currency_property) {
                        let currency = re.exec(property_key_description)
                        if (currency || currency[1]) {
                            let custom_currency = currency[1]
                            obj_copy[key] = this.format_as_currency(obj_copy[key], custom_currency)
                        } else {
                            obj_copy[key] = this.format_as_currency(obj_copy[key], this.currency)
                        }
                    }
                }
            }
            return obj_copy
        },
        get_embedded_properties(properties, start_path, all_available) {
            let embedded_properties = {}
            for (let key in properties) {
                if (key.startsWith('_')) continue
                let property = properties[key]
                let embedded_path = start_path.length ? `${start_path}.${key}` : key
                if ('$ref' in property) {
                    if (all_available || this.used_in_card((embedded_path))) {
                        embedded_properties[embedded_path] = 1
                    }
                }
                if ('properties' in property) {

                    embedded_properties = Object.assign(embedded_properties, this.get_embedded_properties(property['properties'], embedded_path, all_available))
                }
                if ('items' in property) {
                    embedded_properties = Object.assign(embedded_properties, this.get_embedded_properties(property['items']['properties'], embedded_path, all_available))
                }
            }
            return embedded_properties
        },
        used_in_card(embedded_attr) {
            if (!this.card) return false
            let header_key_config = this.card['header']
            let body = this.card['body']
            let footer_key_config = this.card['footer']

            let part_of_left_col = false
            let part_of_right_col = false

            if (body) {
                if (body['left_column']) {
                    body['left_column'].forEach(key_config => {
                        if (this.attribute_in_key_config(embedded_attr, key_config)) part_of_left_col = true
                    })
                }
                if (body['right_column']) {
                    body['right_column'].forEach(key_config => {
                        if (this.attribute_in_key_config(embedded_attr, key_config)) part_of_right_col = true
                    })
                }
            }

            return (
                this.attribute_in_key_config(embedded_attr, header_key_config) ||
                this.attribute_in_key_config(embedded_attr, footer_key_config) ||
                part_of_left_col ||
                part_of_right_col
            )
        },
        attribute_in_key_config(attribute, key_config) {
            if (!key_config) return false
            let keys = undefined
            if (key_config['distinct']) {
                keys = [key_config['distinct']]
            } else if (key_config['first_existing']) {
                keys = key_config['first_existing']
            } else {
                keys = []
            }

            for (let key of keys) {
                if (key.indexOf('attribute') > -1) {
                    return true
                }
            }

            return false
        },
        integer_rule(number) {
            if (!number) return true
            number = String(number)
            return number.indexOf('.') > -1 ? this.$t(`regex.invalid_integer`) : true
        },
        number_rule(number) {
            if (!number) return true
            number = String(number)
            number = number.replace(',', '.')
            let re = new RegExp(/^-?\d*?\.?\d+\.?$/)
            return re.test(number) && number.split('.').length < 3 ? true : this.$t(`regex.invalid_number`)
        },
        required_rule(input) {
            //TODO: string with value '' shall return true, not false
            if (input === null || input === undefined) return this.$t(`common.required`)
            return true
        },
        min_length_rule(input, min_length) {
            return input?.length < min_length ? this.$t(`common.min_length`, {count: min_length}) : true
        },
        max_length_rule(input, max_length) {
            return input?.length > max_length ? this.$t(`common.max_length`, {count: max_length}) : true
        },
        field_rule(input, pattern, name) {
            let re = new RegExp(pattern)
            return re.test(input) ? true : this.translate(`regex.unmatched_${name}`)
        },
        field_placeholder() {
            if (!this.property.pattern) return ''
            return this.translate(`placeholder.${this.property.name}`)
        },
        field_hint() {
            if (!this.property.pattern) return ''
            return this.translate(`hint.${this.property.name}`)
        },
        resource_to_resource_title(resource) {
            if (!resource || !this.swagger || !this.swagger.paths) return undefined
            let resource_copy = JSON.parse(JSON.stringify(resource))
            resource_copy = resource_copy.startsWith('/') ? resource_copy : `/${resource_copy}`

            if (!this.swagger.paths[resource_copy]) return undefined
            let path = this.swagger.paths[resource_copy]

            return path.get['tags'][0]
        },
        resource_title_to_resource(resource_title) {
            let resource = null
            let path_keys = Object.keys(this.swagger.paths)

            path_keys.forEach(key => {
                let path = this.swagger.paths[key]
                let schema = path.get ? path.get.responses['200'].schema : null
                if (schema && schema.type === 'array') {
                    if (schema.items.$ref.split('/').slice(-1)[0] === resource_title) {
                        resource = key
                    }
                }
            })

            return resource
        },
        property_reference_to_resource(reference) {
            let resource_parameter = reference.split('/').pop()
            let resource_parameter_name = this.swagger.components['parameters'][resource_parameter]['name']
            for (let path in this.swagger.paths) {
                if (path.endsWith(`{${resource_parameter_name}}`)) {
                    return path.split(`{${resource_parameter_name}}`)[0].replaceAll('/', '')
                }
            }
            return undefined
        },
        resource_access_right(resource) {
            let resource_access_right = resource && this.user_extended_data && this.user_extended_data['access_rights'] && resource in this.user_extended_data['access_rights']
            return resource_access_right ? this.user_extended_data['access_rights'][resource] : undefined
        },
        get_resource_icon(resource) {
            let default_icon = "mdi-information"
            if (!this.ui_structure[resource]) return default_icon
            let icon = this.ui_structure[resource]['icon'] || default_icon
            return icon
        },
        is_required(property) {
            if (!property) return false
            //TODO: must revise dependency required before adding it...
            return (property.is_required || property.dependency_required) && !property.excluded
        },

        where(search_text, search_filter, custom_text_search = null) {
            let where = {
                $and: [],
                $or: []
            }

            // Search filter should normally be an array according to user settings resource filters. But we handle objects as well
            if (search_filter && !Array.isArray(search_filter)) {
                where = {
                    $and: search_filter['$and'] || [],
                    $or: search_filter['$or'] || []
                }

                search_filter = JSON.parse(JSON.stringify(search_filter))

                if ('$and' in search_filter) delete search_filter['$and']
                if ('$or' in search_filter) delete search_filter['$or']
                search_filter = this.object_to_array_search_filter(search_filter)
            }

            if (search_text && this.allowed_text_search) {
                let regex_search_text = this.regex_string(search_text)
                if (this.validate_regex(regex_search_text)) {
                    for (let property_path of (custom_text_search || this.allowed_text_search)) {
                        where.$or.push(
                            {
                                [property_path]: {'$regex': regex_search_text, '$options': 'i'}
                            }
                        )
                    }
                }

            }

            if (search_filter) {
                for (let filter of search_filter) {
                    if (Object.keys(filter).length <= 1) continue

                    let property_filter = undefined
                    if (filter.value !== undefined) {
                        property_filter = this.format_filter_value(filter.value)
                    } else if (filter.ref) {
                        property_filter = filter.ref
                    } else if (Object.keys(filter).filter(x => ['regex', 'lt', 'lte', 'gt', 'gte', 'in', 'ne', 'exists'].indexOf(x) > -1).length) {
                        property_filter = {}
                        if (filter.regex) {
                            let filter_regex = this.regex_string(filter.regex)
                            if (this.validate_regex(filter_regex)) {
                                property_filter.$regex = filter_regex
                                property_filter.$options = 'i'
                            }
                        }

                        if (filter.in) {
                            property_filter.$in = filter.in
                        }

                        for (let key of ['lt', 'lte', 'gt', 'gte', 'ne', 'exists']) {
                            let key_filter = filter[key]

                            if (this.is_object(key_filter)) {
                                let key_filter_value = key_filter['number'] || key_filter['datetime']
                                if (key_filter_value !== undefined) {
                                    property_filter[`$${key}`] = key_filter_value
                                }
                            } else if (key_filter !== undefined && key_filter !== null) {
                                property_filter[`$${key}`] = key_filter
                            }

                        }


                    }

                    if (property_filter !== undefined) {
                        where.$and.push(
                            {
                                [filter.property]: property_filter
                            }
                        )
                    }
                }
            }

            let keys_to_delete = []
            for (let key of Object.keys(where)) {
                if (!where[key].length) keys_to_delete.push(key)
            }

            keys_to_delete.forEach(k => delete where[k])

            return where
        },
        sort(order_by, order_type) {
            let sign = order_type > 0 || order_type === 'ascending' ? '' : '-'
            return order_by ? `${sign}${order_by}` : ''
        },
        object_to_array_search_filter(object_search_filter) {
            if (!object_search_filter || !Object.keys(object_search_filter).length) return []
            return Object.entries(object_search_filter).map(([property, value]) => {
                let new_search_filter_entry = {
                    property: property
                }

                if (this.is_object(value)) {
                    for (let [filter_param, filter_value] of Object.entries(value)) {
                        new_search_filter_entry[filter_param.replaceAll('$', '')] = filter_value
                    }
                } else {
                    new_search_filter_entry['value'] = value
                }

                return new_search_filter_entry
            })
        },
        format_filter_value(value) {
            if (value === 'boolean_true') return true
            if (value === 'boolean_false') return false
            return value
        },
        format_property_schema_for_array(property) {
            let formatted_property = {
                description: property.items.description,
                name: property.name,
                pattern: property.pattern,
                type: property.items.type,
                $ref: property.items.$ref,
                access_right: property.access_right,
                properties: property.items.properties,
                excluded: property.excluded
            }

            if (property.enum) formatted_property['enum'] = property.enum
            if (property?.items?.enum) formatted_property['enum'] = property.items.enum

            return formatted_property
        },
        find_reference_in_other_resource(target_resource, path_to_reference = "") {
            let target_resource_title = this.deep_get(this.swagger, `paths./${target_resource}.get.tags.0`)
            if (!target_resource_title) return undefined

            let target_swagger_schema_path = `components.schemas.${target_resource_title}.properties`
            if (path_to_reference) {
                path_to_reference = path_to_reference.split(".").join(".properties.")
                target_swagger_schema_path += `.${path_to_reference}.properties`
            }

            let swagger_schema = this.deep_get(this.swagger, target_swagger_schema_path)
            let resource_id_reference = this.deep_get(this.swagger, `components.schemas.${this.resource_title}.properties._id`)
            if (resource_id_reference === null) return undefined
            let reference_key = Object.keys(swagger_schema).find(x => this.object_equals(swagger_schema[x], resource_id_reference))

            return reference_key ? reference_key : undefined
        },
        async toggle_favorite(item) {
            let index = this.favorite_ids.indexOf(item._id)
            let domain_action = undefined

            let dotted_path = `favorites.${this.resource}`
            let resource_favorites = this.deep_get(this.user_favorite, dotted_path)

            if (index > -1) {
                domain_action = 'favorite_remove'
                resource_favorites.splice(index, 1)
            } else {
                domain_action = 'favorite'
                if (!resource_favorites) {
                    resource_favorites = []
                }

                resource_favorites.push(
                    {
                        id: item._id,
                        added: new Date().toGMTString(),
                        title: this.user_favorite_title(item)
                    }
                )
            }
            await this.update_favorite(dotted_path, resource_favorites)
            this.user_activity('item', 'domain', this.resource, domain_action)
        },
        user_favorite_title(item) {
            let title = this.get_card_value(item, this.item_ui_structure.card.header)
            return title ? title.toString() : ''
        },
        async update_favorite(dotted_path, resource_favorites) {
            let user_favorite_copy = JSON.parse(JSON.stringify(this.user_favorite))
            dotted_path = dotted_path.startsWith('favorites') ? dotted_path : `favorites.${dotted_path}`

            try {
                let result = await this.api_patch({
                    url: `/user--favorites/${user_favorite_copy['_id']}`,
                    if_match: user_favorite_copy["_etag"],
                    data: {
                        [dotted_path]: resource_favorites
                    }
                })

                Object.keys(result.data).forEach(key => {
                    user_favorite_copy[key] = result.data[key]
                })
                this.deep_set(user_favorite_copy, dotted_path, resource_favorites)
                this.set_state_property({
                    state_property: 'user_favorite',
                    data: user_favorite_copy
                })
            } catch (e) {
                await this.get_user_favorite()
                throw e
            }
        },
        create_item_schema(item) {
            if (!this.resource_schema) return undefined

            let schema = JSON.parse(JSON.stringify(this.resource_schema))
            let model_rules = this.swagger['x-model-rules'][this.resource_title]
            if (model_rules) {
                this.apply_model_rules(item, schema['properties'], model_rules)
            }


            this.apply_access_right(schema['properties'], this.access_right)

            this.apply_required_rules(schema)
            return schema
        },
        apply_model_rules(item, properties, model_rule) {
            /*This function requires that the is reset inbetween computations*/
            for (let [prop, prop_rule] of Object.entries(model_rule)) {
                if (prop_rule?.properties && properties?.[prop] && item?.[prop]) {
                    let inner_properties = properties[prop].type === 'array' ? properties[prop].items.properties : properties[prop].properties
                    if (inner_properties === undefined) continue

                    this.apply_model_rules(item[prop], inner_properties, prop_rule.properties)
                }


                if (item[prop] && prop_rule['excludes']) {
                    prop_rule.excludes = Array.isArray(prop_rule.excludes) ? prop_rule.excludes : [prop_rule.excludes]
                    prop_rule.excludes
                        .filter(x => properties[x])
                        .forEach(exclude_prop => {
                            properties[exclude_prop]["excluded"] = true

                            /* Update dependencies of exclude property*/
                            if (model_rule[exclude_prop] && model_rule[exclude_prop]['dependencies']) {
                                model_rule[exclude_prop].dependencies = Array.isArray(model_rule[exclude_prop].dependencies) ? model_rule[exclude_prop].dependencies : [model_rule[exclude_prop].dependencies]
                                model_rule[exclude_prop].dependencies
                                    .filter(x => properties[x])
                                    .forEach(exclude_prop_dependency_prop => {
                                        properties[exclude_prop_dependency_prop]["excluded"] = true
                                    })
                            }
                        })
                }

                if (prop_rule['dependencies']) {
                    prop_rule.dependencies = Array.isArray(prop_rule.dependencies) ? prop_rule.dependencies : [prop_rule.dependencies]
                    prop_rule.dependencies
                        .filter(x => properties[x])
                        .forEach(depend_prop => {
                            if (item[depend_prop] && !properties[prop]['dependency_required']) {
                                properties[prop]['dependency_required'] = true
                            }

                            /*Check chained dependencies if they are excluded and self exclude prop if they exist*/
                            if (model_rule[depend_prop] && model_rule[depend_prop]['excludes']) {
                                model_rule[depend_prop].excludes = Array.isArray(model_rule[depend_prop].excludes) ? model_rule[depend_prop].excludes : [model_rule[depend_prop].excludes]
                                let any_excluded_dependencies = false
                                model_rule[depend_prop].excludes
                                    .filter(x => properties[x])
                                    .forEach(dependency_exclude_prop => {
                                        if (item[dependency_exclude_prop]) {
                                            any_excluded_dependencies = true
                                        }
                                    })
                                if (any_excluded_dependencies && !properties[prop]['excluded']) {
                                    properties[prop]['excluded'] = true
                                }
                            }

                        })
                }


            }
        },
        apply_access_right(properties) {
            let no_access_required = this.resource_domains_with_no_access_control.indexOf(this.resource) > -1
            if (!this.access_right && !no_access_required) {
                return
            }

            for (let p in properties) {
                let property = properties[p]

                let attribute_access = this.access_right && 'attribute_access' in this.access_right ? this.access_right.attribute_access : undefined

                let access = (
                    no_access_required || this.access_right['full_attribute_access'] ? 'write' :
                        attribute_access ? attribute_access[p] || 'none' : 'none'
                )
                if ((property['readOnly'] || (!this.allow_edit && !this.create_new)) && access === 'write')
                    access = 'read'

                property['access_right'] = access
                let sub_properties = (
                    property.type === 'object' ? property.properties :
                        property.type === 'array' ? property.items.properties : undefined
                )
                if (sub_properties) this.recursively_set_access(sub_properties, access)
            }
        },
        recursively_set_access(sub_properties, access) {
            for (let p in sub_properties) {
                let property = sub_properties[p]

                let property_access = access === 'none' ? 'none' : property['readOnly'] ? 'read' : access
                property['access_right'] = property_access
                let sub_sub_properties = (
                    property.type === 'object' ? property.properties :
                        property.type === 'array' ? property.items.properties : undefined
                )
                if (sub_sub_properties) this.recursively_set_access(sub_sub_properties, property_access)

            }
        },
        apply_required_rules(schema) {
            let properties = schema['properties']
            let required = schema['required'] || []
            if (!properties) return
            Object.keys(properties).forEach(key => {
                let property = properties[key]
                properties[key]['is_required'] = required.indexOf(key) > -1;

                if (property.type === 'array' && property['items'].type === 'object') {
                    this.apply_required_rules(property['items'])
                } else if (property.type === 'object') {
                    this.apply_required_rules(property)
                }
            })
        },
        get_key_value({distinct, locale, first_existing}, item, schema_properties) {
            if (distinct) {
                return {
                    key: distinct,
                    value: this.get_value(item, distinct, schema_properties),
                    label: this.locale_key(distinct, [this.resource_translate_location, 'item'])
                }
            } else if (locale) {
                return {
                    key: locale,
                    value: this.$t(locale),
                    label: locale
                }
            } else if (first_existing) {
                for (let key of first_existing) {
                    let value = this.get_value(item, key, schema_properties)
                    if (value) return {
                        key,
                        value,
                        label: this.locale_key(key, [this.resource_translate_location, 'item'])
                    }
                }
            }
            return {key: undefined, value: undefined, label: undefined}
        },
        get_body_value_data(key_config, item, schema_properties) {
            if (!key_config) {
                return ''
            }

            let {key, value, label} = this.get_key_value(key_config, item, schema_properties)
            let is_boolean = this.key_value_is_boolean(key, schema_properties)

            let is_chip = this.is_chip(key, this.resource)
            let color = null
            if (is_chip) {
                color = this.get_enum_color(key, value, this.resource)
            }
            return {
                key,
                value,
                label,
                is_boolean,
                is_chip,
                color
            }
        },
        validate_attribute_settings(value, dotted_path) {
            let resource_attribute_settings = this.resource_attribute_settings
            if (!resource_attribute_settings?.length) return null

            let attribute_settings = resource_attribute_settings.filter(x => x.attribute === dotted_path)
            if (!attribute_settings?.length) return null

            for (let x of Object.values(attribute_settings[0].setting)) {
                let condition_fulfilled = true
                for (let activation_condition of Object.keys(x.activation)) {
                    let settings_condition = {
                        exists: function (value, activation_setting_value) {
                            return value && activation_setting_value
                        },
                        lower_than: function (value, activation_setting_value) {
                            return value < activation_setting_value
                        },
                        lower_than_equals: function (value, activation_setting_value) {
                            return value <= activation_setting_value
                        },
                        higher_than: function (value, activation_setting_value) {
                            return value > activation_setting_value
                        },
                        higher_than_equals: function (value, activation_setting_value) {
                            return value >= activation_setting_value
                        },
                        equals: function (value, activation_setting_value) {
                            return value === activation_setting_value
                        },
                    }
                    if (this.get_property(this.resource_schema.properties, dotted_path)?.format === 'date-time' &&
                        activation_condition !== 'exists' && value && x.activation[activation_condition]) {
                        value = new Date(value).getTime()
                        x.activation[activation_condition] = new Date(x.activation[activation_condition]).getTime()
                    }
                    condition_fulfilled &= settings_condition[activation_condition](value, x.activation[activation_condition])
                }
                if (condition_fulfilled) {
                    let attribute_setting = {
                        name: attribute_settings[0].attribute,
                        color: x?.color ? x.color : undefined,
                        icon: x?.icon ? x.icon : undefined,
                    }
                    return attribute_setting
                }
            }
            return null
        },
    },
}
