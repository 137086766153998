<template>
    <div>
        <v-navigation-drawer
            v-model="internal_value"
            :width="$vuetify.breakpoint.xsOnly ? '100vw' : '550px'"
            :style="sidebar_css"
            :permanent="permanent"
            temporary
            absolute
            right
        >
            <!-- TODO: must autofocus stuff in menus AFTER after menu has appeared in the menu -->

            <v-toolbar
                color="primary"
                dark
                height="56px"
            >
                <v-btn
                    icon
                    fab
                    @click="internal_value = false"
                >
                    <v-icon>
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>

            <slot name="default">
                <component
                    ref="right_navigation_drawer_component"
                    :is="component"
                    @close="internal_value = false"
                />
            </slot>
        </v-navigation-drawer>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import locale_mixin from "@/mixins/common/locale_mixin";
import {snakeCase} from "lodash"

export default {
    name: "TheRightNavigationDrawer",
    props: {
        value: Boolean,
        component: String
    },
    mixins: [locale_mixin],
    components: {
        SearchMenu: () => import(/* webpackChunkName: "Search"*/ "@/components/search_menu/Index"),
        Favorites: () => import(/* webpackChunkName: "Favorites"*/ "@/components/favorites/Index"),
        Settings: () => import(/* webpackChunkName: "Settings"*/ "@/components/settings/Index"),
        Help: () => import(/* webpackChunkName: "Help"*/ "@/components/help/Index"),
    },
    data() {
        return {
            permanent: false,
            internal_value: null
        }
    },
    computed: {
        user_name(){
            let text = this.user_extended_data.name
            if (this.user_extended_data.organization_name) {
                text += ' - ' + this.user_extended_data.organization_name
            }
            return text
        },
        title() {
            if (this.component==="Settings"){
                return this.user_name
            }
            if (this.component) return this.translate(`common.${snakeCase(this.component)}`)

            return 'common.navigation_drawer'
        },
        sidebar_css() {
            if (this.$vuetify.breakpoint.xsOnly) {
                return 'top: 0px;'
            } else if (this.$vuetify.breakpoint.smOnly) {
                return 'top: 56px; max-height: calc(100vh - 56px);'
            } else {
                return 'top: 64px; max-height: calc(100vh - 64px);'
            }
        },
        sidebar_css_height() {
            return this.$vuetify.breakpoint.xsOnly ? '100vh - 56px ' : '100vh - 56px - 64px'
        },
        ...mapGetters([]),
        ...mapState([
            'partner_import_filters',
            'user_extended_data',
        ]),
    },
    watch: {
        internal_value: {
            handler(val) {
                this.$emit('input', val)

                this.update_permanent()
            },
            deep: true
        },
        value(val) {
            this.internal_value = val
        },
        component() {
            this.update_permanent()
        }
    },
    methods: {
        update_permanent() {
            if (!this.internal_value) {
                this.permanent = false
                return
            }

            if (this.component === 'Settings' && this.partner_import_filters) {
                this.permanent = true
            }
        },
        ...mapActions([
            'set_state_property'
        ]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
