<template>
    <v-menu
        v-if="multiple_dependencies"
        top
        nudge-right="10"
        offset-x
    >
        <template v-slot:activator="{on, attrs}">
            <v-card
                v-bind="attrs"
                v-on="on"
                color="transparent"
                class="pa-2"
                :width="fullWidth ? '100%' : ''"
                tile flat
            >
                <v-row
                    no-gutters
                    align="center"
                >
                    <v-badge
                        :content="total_results || '0'"
                        offset-y="10"
                        offset-x="10"
                        color="accent"
                    >
                        <v-tooltip
                            bottom
                            class="app-layout--level-600"
                        >
                            <template v-slot:activator="{on, attrs}">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ icon }}
                                </v-icon>


                            </template>
                            <span
                                v-text="$t(resource_title)"
                            />
                        </v-tooltip>


                    </v-badge>
                    <v-col v-if="showLabel">
                        <v-card-text class="pa-0 ml-2">
                            {{ $t(resource_title) }}
                        </v-card-text>
                    </v-col>
                    <v-icon class="ml-4">
                        mdi-chevron-right
                    </v-icon>

                </v-row>

            </v-card>
        </template>

        <v-list>
            <v-list-item
                v-for="([property_path, value]) of Object.entries(this.link_results)"
                :key="property_path"
                link
                dense
            >
                <v-badge
                    :content="value || '0'"
                    offset-y="10"
                    offset-x="10"
                    color="accent"
                >
                    <v-tooltip
                        bottom
                        class="app-layout--level-600"
                    >
                        <template v-slot:activator="{on, attrs}">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ icon }}
                            </v-icon>
                        </template>
                    </v-tooltip>
                </v-badge>
                <v-list-item-title
                    @click="$emit('click', property_path)"
                    class="ml-3"
                >
                    {{ $t('common.' + property_path.split('.').pop()) }}

                </v-list-item-title>
                <v-btn
                    v-if="display_create_new"
                    @click="$emit('create_new', undefined)"
                    class="ml-4"
                    style="max-width: 22px; max-height: 22px"
                    x-small
                    color="primary"
                    elevation="1"
                    fab
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
    <v-tooltip
        v-else-if="single_dependency"
        class="app-layout--level-600"
        bottom
    >
        <template v-slot:activator="{on, attrs}">
            <v-card
                @click="$emit('click', undefined)"
                color="transparent" flat tile
                :width="fullWidth ? '100%' : ''"
                class="pa-2"
            >
                <v-row
                    no-gutters
                    align="center"
                >
                    <v-badge
                        :content="total_results || '0'"
                        offset-y="10"
                        offset-x="10"
                        color="accent"
                    >

                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ icon }}
                        </v-icon>

                    </v-badge>
                    <v-col v-if="showLabel">
                        <v-card-text class="pa-0 ml-2">
                            {{ $t(resource_title) }}
                        </v-card-text>
                    </v-col>
                    <v-btn
                        v-if="display_create_new"
                        @click="$emit('create_new', undefined)"
                        class="ml-4"
                        style="max-width: 25px; max-height: 25px"
                        x-small
                        color="primary"
                        elevation="1"
                        fab
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-row>
            </v-card>

        </template>
        <span>
                {{ $t(resource_title) }}
            </span>
    </v-tooltip>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import item_mixin from "@/mixins/item/mixin"

export default {
    name: "ItemDialogDependenciesLink",
    props: {
        value: {
            'type': [String, Object, Number, Boolean, Array]
        },
        resource: String,
        link_results: Object,
        icon: String,
        multiple_dependencies: Boolean,
        single_dependency: Boolean,
        showLabel: Boolean,
        fullWidth: Boolean,
        display_create_new: Boolean
    },
    mixins: [item_mixin],
    components: {},
    data() {
        return {
            internal_value: undefined
        }
    },
    computed: {
        resource_title() {
            return this.locale_key(
                this.resource_to_resource_title(this.internal_value).toLocaleLowerCase().replaceAll('-', '_'),
                [this.resource_to_resource_title(this.internal_value).toLocaleLowerCase().replaceAll('-', '_'), 'item']
            )
        },
        total_results() {
            let total = 0
            for (let value of Object.values(this.link_results)) {
                total += value
            }
            return total
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
