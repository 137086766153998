var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.multiple_dependencies)?_c('v-menu',{attrs:{"top":"","nudge-right":"10","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"pa-2",attrs:{"color":"transparent","width":_vm.fullWidth ? '100%' : '',"tile":"","flat":""}},'v-card',attrs,false),on),[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-badge',{attrs:{"content":_vm.total_results || '0',"offset-y":"10","offset-x":"10","color":"accent"}},[_c('v-tooltip',{staticClass:"app-layout--level-600",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icon)+" ")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.resource_title))}})])],1),(_vm.showLabel)?_c('v-col',[_c('v-card-text',{staticClass:"pa-0 ml-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.resource_title))+" ")])],1):_vm._e(),_c('v-icon',{staticClass:"ml-4"},[_vm._v(" mdi-chevron-right ")])],1)],1)]}}],null,false,2758740319)},[_c('v-list',_vm._l((Object.entries(this.link_results)),function(ref){
var property_path = ref[0];
var value = ref[1];
return _c('v-list-item',{key:property_path,attrs:{"link":"","dense":""}},[_c('v-badge',{attrs:{"content":value || '0',"offset-y":"10","offset-x":"10","color":"accent"}},[_c('v-tooltip',{staticClass:"app-layout--level-600",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icon)+" ")])]}}],null,true)})],1),_c('v-list-item-title',{staticClass:"ml-3",on:{"click":function($event){return _vm.$emit('click', property_path)}}},[_vm._v(" "+_vm._s(_vm.$t('common.' + property_path.split('.').pop()))+" ")]),(_vm.display_create_new)?_c('v-btn',{staticClass:"ml-4",staticStyle:{"max-width":"22px","max-height":"22px"},attrs:{"x-small":"","color":"primary","elevation":"1","fab":""},on:{"click":function($event){return _vm.$emit('create_new', undefined)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)}),1)],1):(_vm.single_dependency)?_c('v-tooltip',{staticClass:"app-layout--level-600",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',{staticClass:"pa-2",attrs:{"color":"transparent","flat":"","tile":"","width":_vm.fullWidth ? '100%' : ''},on:{"click":function($event){return _vm.$emit('click', undefined)}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-badge',{attrs:{"content":_vm.total_results || '0',"offset-y":"10","offset-x":"10","color":"accent"}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),(_vm.showLabel)?_c('v-col',[_c('v-card-text',{staticClass:"pa-0 ml-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.resource_title))+" ")])],1):_vm._e(),(_vm.display_create_new)?_c('v-btn',{staticClass:"ml-4",staticStyle:{"max-width":"25px","max-height":"25px"},attrs:{"x-small":"","color":"primary","elevation":"1","fab":""},on:{"click":function($event){return _vm.$emit('create_new', undefined)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.resource_title))+" ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }