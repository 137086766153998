import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './registerServiceWorker'
import i18n from './i18n'
import VueTour from 'vue-tour'

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {faUserSecret, faUser, faCircle, faCheck} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import VueAxios from 'vue-axios'

import './styles/custom/index.sass'

library.add(fab)
library.add(faUserSecret, faUser, faCircle, faCheck)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

Vue.config.productionTip = false

require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)
Vue.use(VueAxios, axios)

if (!String.format) {
    String.format = function (format) {
        let args = Array.prototype.slice.call(arguments, 1)
        return format.replace(/{(\d+)}/g, (match, number) => {
            return typeof args[number] != 'undefined' ? args[number] : match
        })
    }
}

// Must be last in main.js
new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')


/*let $ = function(sel){
    return document.querySelector(sel)
} */

/*let $$ = function(sel){
    return document.querySelectorAll(sel)
}*/

/*$$('link').forEach(link => {
    console.log(link.href)
})
console.log('links', $$('link'))*/
