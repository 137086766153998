<template>
    <div>
        <v-footer
            v-if="dialogs && dialogs.length && swagger_loaded"
            class="pa-0 app-layout--level-500 app-object--click-disabled"
            color="transparent"
            fixed
        >
            <v-row
                v-if="($vuetify.breakpoint.mdOnly && dialogs.length < 6) || ($vuetify.breakpoint.lgOnly && dialogs.length < 7) || ($vuetify.breakpoint.xlOnly && dialogs.length < 11)"
                no-gutters justify="center"
            >
                <v-col cols="12">
                    <v-row no-gutters>
                        <ItemDialogManagerMinimizedCard
                            v-for="(dialog, index) in dialogs"
                            :key="`minimized_${index}`"
                            :value="dialog"
                            :maximized="current_dialog && dialog.item['_id'] === current_dialog.item['_id']"
                            @maximize="maximize(dialog)"
                            @minimize="minimize(dialog)"
                            @close="close(dialog)"
                            width="175px"
                            class="app-layout--level-500 app-object--click-enabled"
                        />
                    </v-row>
                </v-col>
            </v-row>
            <v-menu
                v-else
                top
                offset-x
            >
                <template #activator="{on, attrs}">
                    <v-badge
                        :value="dialogs.length > 0"
                        :content="dialogs.length"
                        :offset-x="22"
                        :offset-y="20"
                        overlap
                        color="accent"
                    >
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="app-object--click-enabled"
                            text
                        >
                            <v-icon>
                                mdi-table
                            </v-icon>
                        </v-btn>
                    </v-badge>

                </template>

                <v-card
                    width="225px"
                >
                    <v-row no-gutters justify="space-between" align="center">
                        <v-card-title>
                            {{ $t('common.dialogs') }}
                        </v-card-title>
                        <v-btn
                            @click="clear_all"
                            class="mr-1"
                            icon
                        >
                            <v-icon>
                                mdi-delete-outline
                            </v-icon>
                        </v-btn>
                    </v-row>

                    <v-divider></v-divider>

                    <v-card
                        height="325px"
                        style="overflow-y: auto"
                    >
                        <v-row no-gutters>
                            <v-col
                                cols="12"
                                v-for="(dialog, index) in dialogs"
                                :key="`minimized_${index}`"
                                class="mt-1"
                            >
                                <MinimizedCard
                                    :value="dialog"
                                    :maximized="current_dialog && dialog.item['_id'] === current_dialog.item['_id']"
                                    @maximize="maximize(dialog)"
                                    @close="close(dialog)"
                                    class="app-object--click-enabled"
                                    tile
                                />
                            </v-col>
                        </v-row>
                    </v-card>

                </v-card>
            </v-menu>
        </v-footer>

        <ItemDialog
            v-if="current_dialog"
            v-model="current_dialog.item"
            :resource="current_dialog.resource"
            :create_new="current_dialog.create_new"
            :init_editing="current_dialog.init_editing"
            :key="`${current_dialog.item._id}_${current_dialog.create_new}`"
            @close="close(current_dialog)"
            @minimize="minimize(current_dialog)"
            @reset_init_editing="reset_init_editing(current_dialog)"
            display
        />
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import MinimizedCard from "@/components/item_dialog_manager/MinimizedCard";
import ItemDialog from "@/components/item_dialog/Index"
import ItemDialogManagerMinimizedCard from "@/components/item_dialog_manager/MinimizedCard";

export default {
    name: "ItemDialogManager",
    props: {
        value: {
            'type': [String, Object, Number, Boolean, Array]
        }
    },
    mixins: [],
    components: {ItemDialogManagerMinimizedCard, MinimizedCard, ItemDialog},
    data() {
        return {
            internal_value: undefined,
            // validate_state_interval: undefined
        }
    },
    computed: {
        ...mapGetters({
            swagger_loaded: 'swagger/loaded'
        }),
        ...mapState({
            dialogs: state => state.dialog_manager.dialogs,
            current_dialog: state => state.dialog_manager.current,
        }),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        }
    },
    methods: {
        close(dialog) {
            this.remove_dialog(dialog)
        },
        minimize(dialog) {
            this.minimize_dialog(dialog)
        },
        maximize(dialog) {
            this.maximize_dialog(dialog)
            this.validate_current()
        },
        reset_init_editing(dialog) {
            dialog.init_editing = false
            this.edit_dialog(dialog)
        },
        clear_all() {
            this.reset()
        },
        validate_state() {
            this.validate_dialogs()
            this.validate_current()
        },
        validate_dialogs() {
            if (!this.dialogs) {
                this.set_state_property({
                    module: "dialog_manager",
                    state_property: "dialogs",
                    data: []
                })
                return
            }

            let indexes_with_errors = []
            let dialog_copy = JSON.parse(JSON.stringify(this.dialogs))
            dialog_copy.forEach((dialog, index) => {
                let error = false
                let fields = ["item", "resource"]
                fields.forEach(key => {
                    if (!dialog[key]) {
                        error = true
                    }
                })

                if (!error && !dialog['create_new'] && !dialog['item']['_id']) {
                    error = true
                }

                if (error) {
                    indexes_with_errors.push(index)
                }
            })
            if (indexes_with_errors) {
                for (let i = indexes_with_errors.length - 1; i >= 0; i--) {
                    let index = indexes_with_errors[i]
                    dialog_copy.splice(index, 1)
                }

                this.set_state_property({
                    module: "dialog_manager",
                    state_property: "dialogs",
                    data: dialog_copy
                })
            }

        },
        validate_current() {
            if (!this.current_dialog) return
            let error = false
            let fields = ["item", "resource"]
            fields.forEach(key => {
                if (!this.current_dialog[key]) {
                    error = true
                }
            })

            if (!error && !this.current_dialog['create_new'] && !this.current_dialog['item']['_id']) {
                error = true
            }

            if (error) {
                this.set_state_property({
                    module: "dialog_manager",
                    state_property: "current",
                    data: null
                })
            }
        },
        ...mapActions({
            set_state_property: "set_state_property",
            remove_dialog: 'dialog_manager/remove_dialog',
            minimize_dialog: 'dialog_manager/minimize_dialog',
            maximize_dialog: 'dialog_manager/maximize_dialog',
            edit_dialog: 'dialog_manager/edit_dialog',
            reset: 'dialog_manager/reset',
        }),
    },
    beforeCreate() {
    },
    created() {
        this.validate_state()

        // this.validate_state_interval = setInterval(this.validate_state, 10 * 1000)
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
        // clearInterval(this.validate_state_interval)
    },
}
</script>
<style scoped>
</style>
