<template>
    <div id="content_title">
        <v-card
            :width="width || '100%'"
            class="pt-4 pl-0 pb-2 "
            color="transparent"
            flat
        >
            <v-row justify="space-between" align="center" no-gutters>
                <v-col
                  class="font-weight-bold app-text--highlighted  text-h5"
                >
                    {{ translate(title) }}
                </v-col>

                <slot name="buttons">
                    <!-- Slotted content goes here                -->
                </slot>
            </v-row>
        </v-card>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import locale_mixin from "@/mixins/common/locale_mixin";

export default {
    name: "ContentTitle",
    props: {
        'title': String,
        width: String
    },
    mixins: [locale_mixin],
    components: {},
    data() {
        return {
            // template: false,
        }
    },
    computed: {
        // template() {
        //     return this.something;
        // },
        ...mapGetters([
            // ...
        ]),
        ...mapState([
            // ...
        ]),
    },
    watch: {
        // template(v) {
        //     do stuff;
        // }
    },
    methods: {
        // template() {
        //
        // },
        ...mapActions([
            // ...
        ]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
