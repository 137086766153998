<template>
    <v-card-actions
        @click.stop=""
        class="pa-0"
    >
        <v-btn
            v-if="display_favorite_action && !inner_object && !create_new"
            :color="is_favorite ? '#e70641 !important' : location === 'dialog' ? undefined: 'primary !important'"
            @click="toggle_favorite(internal_value)"
            icon
        >
            <v-icon>
                {{ is_favorite ? 'mdi-heart' : 'mdi-heart-outline' }}
            </v-icon>
        </v-btn>

        <ItemActionMenu
            v-if="exists(swagger) && !is_editing && !inner_object"
            v-model="internal_value"
            :key="internal_value['_id']"
            :resource="resource"
            :color="location === 'dialog' ? undefined : 'primary'"
            :display_action_menu="display_action_menu"
            @display_action_menu="$emit('display_action_menu', $event)"
            @display_snackbar="$emit('display_snackbar', $event)"
            @open="$emit('open')"
        />

        <v-dialog
            v-if="create_new && !inner_object"
            v-model="display_copy_dialog"
            :width="responsive_width('', '60vw', '35vw', '30vw','25vw')"
            :fullscreen="$vuetify.breakpoint.xsOnly"
        >
            <template
                v-slot:activator="{on, attrs}"
            >
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                >
                    <v-icon>
                        mdi-content-copy
                    </v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-toolbar
                    :rounded="false"
                    color="primary"
                    dark
                >
                    <v-toolbar-title>
                        {{ $t('common.copy') }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-actions class="mt-1">
                    <AppAutocomplete
                        v-model="item_to_copy_from"
                        :resource="resource"
                        prepend-inner-icon="mdi-magnify"
                        label="item.copy_search_label"
                        clearable
                        return_object
                    />
                </v-card-actions>
                <v-card-text>
                    {{
                        String.format($t('item.copy_help_text'), $t(locale_key(resource.toLowerCase().replace('-', '_'), [resource_translate_location, 'item'])))
                    }}
                </v-card-text>
                <v-divider class="mt-2"/>
                <v-row class="pa-2" justify="end" no-gutters>
                    <v-btn
                        class="mr-2"
                        @click="display_copy_dialog = false"
                    >
                        {{ $t('common.abort') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="copy_item"
                    >
                        {{ $t('common.copy') }}
                    </v-btn>
                </v-row>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="delete_item_dialog"
            max-width="450px"
            min-width="300px"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >
                    <v-toolbar-title>
                        {{ $t('common.delete') }}

                    </v-toolbar-title>
                </v-toolbar>
                <v-card-title>
                    {{ $t('delete.are_you_sure') }}
                </v-card-title>
                <v-card-actions>
                    <v-row
                        justify="space-around"
                        no-gutters
                    >
                        <v-col cols="6">
                            <v-btn
                                @click="delete_item_dialog = false"
                                color="primary"
                                width="100%"
                                class="mr-2"
                            >
                                {{ $t('common.no') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                @click="delete_item"
                                width="100%"
                                class="ml-2"
                            >
                                {{ $t('common.yes') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>

            </v-card>

        </v-dialog>

        <div v-if="location === 'dialog'">
            <v-btn
                v-if="is_editing && allow_delete && !create_new"
                @click="delete_item_dialog =  true"
                icon
            >
                <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>

            <v-btn
                v-if="!is_editing && (allow_edit || allow_delete)"
                @click="$emit('edit')"
                icon
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <v-btn
                v-if="!create_new"
                @click="$emit('minimize')"
                icon
            >
                <v-icon
                    class="pt-2"
                >
                    mdi-window-minimize
                </v-icon>
            </v-btn>

            <v-btn
                v-if="!small_screen"
                @click="$emit('toggle_fullscreen', !fullscreen)"
                icon
            >
                <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
                <v-icon v-else>mdi-fullscreen</v-icon>
            </v-btn>

            <v-btn
                @click="$emit('close')"
                icon
            >
                <v-icon>
                    mdi-close-box
                </v-icon>
            </v-btn>
        </div>
    </v-card-actions>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import item_mixin from "@/mixins/item/mixin"
import ItemActionMenu from "@/components/item_action_menu/Index";
import AppAutocomplete from "@/components/app_autocomplete/Index";

export default {
    name: "ItemButtons",
    props: {
        'value': Object,
        'resource': String,
        'is_editing': Boolean,
        'inner_object': String,
        'create_new': Boolean,
        location: String,
        display_action_menu: Boolean,
        fullscreen: Boolean,
        embedded_object: Boolean,
    },
    mixins: [item_mixin],
    components: {AppAutocomplete, ItemActionMenu,},
    data() {
        return {
            lazy_value: undefined,
            display_copy_dialog: false,
            item_to_copy_from: {},
            delete_item_dialog: false,
        }
    },
    computed: {
        is_favorite() {
            return this.favorite_ids.indexOf(this.internal_value._id) > -1
        },
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.$emit('input', this.lazy_value)
            },
        },
        ...mapGetters({}),
        ...mapState({
            'user_favorite': state => state.user_favorite,
            'swagger': state => state.swagger.local_storage_document
        }),
    },
    watch: {
        value(val) {
            this.lazy_value = val
        },

    },
    methods: {
        delete_item() {
            this.delete_item_dialog = false
            this.$emit('delete_item')
        },
        copy_item() {
            let item_to_copy_from = JSON.parse(JSON.stringify(this.item_to_copy_from))
            item_to_copy_from = this.obj_filter(item_to_copy_from, {'mode': 'reverse', 'starts_with': '_'})

            this.$emit('copy_item', item_to_copy_from)
            this.display_copy_dialog = false
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.lazy_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
