<template>
    <v-menu
        v-model="menu_open_computed"
        offset-x
    >
        <template v-slot:activator="{ on, attrs }">
            <v-hover v-model="menu_button_hover" close-delay="500">
                <v-list-item dense
                             height="100%"
                             v-bind="attrs"
                             v-on="on"
                             class="font-weight-light app-object--rounded  text-capitalize v-btn"
                             :class="menu_selected(item, $route) ? 'v-list-item--active primary--text' :
                                                                {'v-btn--active': menu_open_computed}"
                >
                    <v-list-item-title>
                        {{ translate(item['locale_name']) }}
                    </v-list-item-title>
                    <v-list-item-icon>
                        <v-icon>
                            mdi-chevron-right
                        </v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-hover>
        </template>
        <v-hover v-model="menu_content_hover" close-delay="500">
            <v-list dense style="padding: 0">
                <v-list-item
                    v-for="(list_menu_item, list_menu_index) in item['list_menu']"
                    :key="list_menu_index"
                    :to="list_menu_item"
                    :class="menu_selected(list_menu_item, $route)
                                            ? 'v-btn--active primary--text font-weight-regular text-capitalize'
                                            : 'font-weight-regular text-capitalize'">
                    <v-list-item-title>
                        {{ translate(list_menu_item['locale_name']) }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-hover>
    </v-menu>
</template>

<script>
import item_mixin from "@/mixins/item/mixin";

export default {
    name: "TheHeaderMenuSubItem",
    props: {
        value: Number,
        item: Object,
        index: Number
    },
    mixins: [item_mixin],
    computed: {
        menu_open_computed: {
            get() {
                return this.menu_button_hover || this.menu_content_hover
            },
            set(val) {
                this.menu_button_hover = val
                this.menu_content_hover = val
            }
        },
    },
    data() {
        return {
            menu_button_hover: false,
            menu_content_hover: false,
        }
    },
    watch: {
        menu_open_computed(val) {
            if (val) {
                this.$emit("index", val)
            }
            this.$emit("index_list", val)
        },
        value(val) {
            if (val !== this.index) {
                this.menu_content_hover = false
                this.menu_button_hover = false
            }
        }
    },
    methods: {
        checker(arr) {
            if (arr.length === 0) {
                return false
            }
            return arr.some(v => v === true)
        },
        created() {
            this.internal_value = this.value
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
