<template>
    <div
        v-if="is_editing || create_new"
    >
        <v-menu
            v-if="property.format === 'date-time'"
            v-model="display_date_picker"
            :close-on-content-click="false"
            offset-y
        >
            <template v-slot:activator="{on, attrs}">
                <v-row no-gutters justify="space-between">
                    <v-col :cols="removable && (is_editing || create_new) ? '10': '12'">

                        <v-text-field
                            v-on="on"
                            v-bind="attrs"
                            :value="format_date_time(value)"
                            :label="translate(label)"
                            :rules="(is_editing || create_new) ? [validate(value, property)] : []"
                            :error-messages="translate(error_message)"
                            :placeholder="field_placeholder()"
                            :hint="field_hint()"
                            :readonly="is_readonly"
                            :filled="is_readonly"
                            aria-autocomplete="none"
                            outlined
                            dense
                        />
                    </v-col>
                    <v-col v-if="removable && (is_editing || create_new)" cols="1" class="mr-4">
                        <v-btn
                            @click="$emit('remove_value_from_array')"
                            color="error"
                            class="ma-1"
                            fab
                            x-small
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

            <v-date-picker
                :value="format_date_time(value)"
                :rules="(is_editing || create_new) ? [validate(value, property)] : []"
                :error-messages="translate(error_message)"
                @input="update_input_debounced(new Date($event).toGMTString())"
                @change="display_date_picker = false"
                style="width: 100%"
            />
        </v-menu>

        <v-row v-else no-gutters justify="space-between">
            <v-col :cols="removable && (is_editing || create_new) ? '11': '12'">
                <v-text-field
                    :value="value"
                    :type="computed_type"
                    :label="translate(label)"
                    :rules="(is_editing || create_new) ? [validate(value, property)] : []"
                    :placeholder="field_placeholder()"
                    :hint="field_hint()"
                    :readonly="is_readonly"
                    :filled="is_readonly"
                    :error-messages="translate(error_message)"
                    :aria-autocomplete="property.type === 'string' ? 'both' : 'none'"
                    :clearable="(is_editing || create_new) && !is_readonly"
                    @input="update_input_debounced(format_value($event))"
                    outlined
                    dense
                />
            </v-col>
            <v-col v-if="removable && (is_editing || create_new)" cols="1">
                <v-btn
                    @click="$emit('remove_value_from_array')"
                    color="error"
                    class="ma-1"
                    fab
                    x-small
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

            </v-col>
        </v-row>

    </div>
    <div
        v-else
    >
        <v-card-text
            class="pa-0"
        >
            <ItemBodyValue
                :value="display_value"
                :label="label"
                :isChip="display_config.is_chip"
                :color="display_config.color"
                :icon="display_config.icon"
                left
            />
        </v-card-text>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import debounce from "lodash/debounce"
import common_mixin from "@/mixins/common/mixin";
import item_mixin from "@/mixins/item/mixin"
import ItemBodyValue from "@/components/item_body_value/Index";

export default {
    name: "ItemDialogTabDataStringDateNumberInteger",
    mixins: [common_mixin, item_mixin],
    props: {
        'value': {
            type: [String, Number],
        },
        resource: String,
        'property': {
            type: Object,
            required: true
        },
        'is_editing': {
            type: Boolean,
            default: false
        },
        "create_new": {
            type: Boolean,
            default: false
        },
        'removable': {
            type: Boolean,
            value: false
        },
        'label': {
            type: String,
            required: true
        },
        'currency': {
            type: String
        },
        'error_message': {
            type: String,
            default: ''
        },
        dotted_path: String,
    },
    components: {ItemBodyValue},
    data() {
        return {
            display_date_picker: false,
        }
    },
    computed: {
        display_value() {
            if (this.property.format === 'date-time') {
                return this.format_date(this.value)
            }
            if (this.is_currency_field && !(this.is_editing || this.create_new)) {
                let custom_currency = this.custom_currency()
                return this.format_as_currency(this.value, custom_currency ? custom_currency : this.currency)
            }
            return this.value
        },
        is_readonly() {
            return (!this.is_editing && !this.create_new) || this.property.excluded || this.property.access_right === 'read'
        },
        is_currency_field() {
            if (this.property.type !== 'number') return false
            return this.property.description ? this.property.description.indexOf('#format_as_currency') > -1 : false
        },
        computed_type() {
            if (this.is_currency_field) return 'string'
            if (this.property.type === 'integer' || this.property.type === 'number') return 'number'
            return this.property.type
        },
        attribute_setting_dotted_path() {
            return this.dotted_path ? `${this.dotted_path}.${this.property.name}` : this.property.name
        },
        attribute_setting() {
            return this.validate_attribute_settings(this.value, this.attribute_setting_dotted_path)
        },
        display_config() {
            return {
                is_chip: this.attribute_setting && this.attribute_setting?.name === this.property.name ? true : false,
                color: this.attribute_setting && this.attribute_setting.color ? this.attribute_setting.color : 'grey',
                icon: this.attribute_setting && this.attribute_setting.icon ? this.attribute_setting.icon : undefined
            }
        },
        ...mapGetters([
            // ...
        ]),
        ...mapState([
            // ...
        ]),
    },
    watch: {},
    methods: {
        update_input_debounced: debounce(function (value) {
            this.$emit('input', value)
        }, 250),
        format_value(value) {
            if (this.property.type === 'integer') value = parseInt(value)
            if (this.property.type === 'number') value = Number(value)
            return value
        },
        custom_currency() {
            let re = new RegExp("#format_as_currency:([A-Za-z]*)")
            let currency = re.exec(this.property.description)
            if (!currency || !currency[1]) {
                return undefined
            }
            return currency[1]
        },
        ...mapActions([
            // ...
        ]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
