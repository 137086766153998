import Vue from 'vue'
import Vuex from 'vuex'
import VueJwtDecode from 'vue-jwt-decode'
import createPersistedState from 'vuex-persistedstate'
import dialog_manager from "@/store/dialog_manager";
import swagger from "@/store/swagger"
import window_manager from "@/store/window_manager";
import {differenceInDays} from 'date-fns'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        /**************************************
         Application
         */
        application_details: null,
        remember_logon_email: "",
        remember_logon_sms: "",

        /**************************************
         System
         */
        system: undefined,
        system_name: undefined,
        is_default_admin_url: false,
        existing_systems: [],
        existing_system_details: [],
        too_many_requests: false,
        too_many_requests_message: "",

        /**************************************
         USER DATA
         */
        user_extended_data: {},
        user_settings: null,
        user_token: null,
        user_favorite: {},

        site_refreshes: 0,

        help_tours: [],

        /**************************************
         Dashboard data
         */
        dashboard_data: {
            updated: null
        },

        /**************************************
         Search navigation drawer
         */
        right_navigation_search_init_tab: null,


        /**************************************
         PARTNER DATA
         */
        partner_import_filters: null,
        partner_self_registration_data: null,

        /**************************************
         Multiple tabs synchronization tokens
         */
        refreshing_token: false,
        signing_out: false,

        /**************************************
         Login
         */
        redirect_url_on_login_success: null
    },
    getters: {
        user_extended_data_loaded: (state) => {
            return state.user_extended_data && Object.keys(state.user_extended_data).length
        },
        access_rights: (state) => {
            return state.user_extended_data ? state.user_extended_data.access_rights : undefined
        },
        help_tours_by_route: (state) => (route_name) => {
            return state.help_tours.filter(item => {
                return item.route_name === route_name || item.all_routes
            })
        },
        user_token_json: (state) => {
            if (!state.user_token || !state.user_token.token) return {}
            return VueJwtDecode.decode(state.user_token.token);
        },
        system_user: (state) => {
            if (!state.user_token || !state.user_token.token) return false
            return !!VueJwtDecode.decode(state.user_token.token)['system_user']
        },
        token_type: (state) => {
            if (!state.user_token || !state.user_token.token) return ''
            return VueJwtDecode.decode(state.user_token.token)['token_type'].split('.').pop()
        },
        user_is_authenticated: (state) => {
            if (!state.user_token) return false
            // eslint-disable-next-line no-prototype-builtins
            console.log("User is authenticated", state.user_token.hasOwnProperty("token"))
            // eslint-disable-next-line no-prototype-builtins
            return state.user_token.hasOwnProperty("token");
        },
        user_has_selected_token: (state) => {
            if (!state.user_token) return false
            return state.user_token && state.user_token.token
        },
        navigation_menu: (state) => {
            if (!state.user_extended_data || !('ui_structure' in state.user_extended_data)) return []
            return state.user_extended_data['ui_structure']['navigation_menu']
        },
        search_menu: (state) => {
            if (!state.user_extended_data || !('ui_structure' in state.user_extended_data)) return []
            return state.user_extended_data['ui_structure']['search_menu']
        },
        ui_structure: (state) => {
            if (!('ui_structure' in state.user_extended_data)) return {}
            return state.user_extended_data['ui_structure']['structure']
        },
        partner_first_login: (state) => {
            let is_partner = 'partner' in state.user_extended_data
            return is_partner && state.user_extended_data['first_login']
        },
        unfinished_partner_filters: (state) => {
            if (!state.partner_import_filters || state.partner_import_filters._meta.total < 1) return []
            let today = new Date()
            return state.partner_import_filters._items.filter(x => {
                let day_diff = differenceInDays(today, new Date(x['_created']))
                return !x['is_enabled'] && day_diff < 3
            })
        }
    },
    mutations: {
        mutate(state, payload) {
            if (payload.module) {
                state[payload.module][payload.property] = payload.with;
            } else {
                state[payload.property] = payload.with;
            }
        },
        push(state, payload) {
            if (payload.module) {
                state[payload.module][payload.property].push(payload.with)
            } else {
                state[payload.property].push(payload.with)
            }
        },
    },
    actions: {
        set_state_property({commit}, payload) {
            commit('mutate', {
                property: payload.state_property,
                with: payload.data,
                module: payload.module
            });
        },
        set_state_properties({commit}, payloads) {
            payloads.forEach(payload => {
                commit('mutate', {
                    property: payload.state_property,
                    with: payload.data,
                    module: payload.module
                });
            })
        },
        push_state_property({commit}, payload) {
            commit('push', {
                property: payload.state_property,
                with: payload.data,
                module: payload.module
            })
        }
    },
    modules: {
        window_manager: window_manager,
        dialog_manager: dialog_manager,
        swagger: swagger
    },
    plugins: [
        /* NOTE: Do not use undefined on any properties saved in local storage */
        /* Slow sync of data (Requires site reload) */
        createPersistedState(
            {
                key: 'user_state',
                paths: [
                    'redirect_url_on_login_success',
                    'site_refreshes',

                    'application_details', // TODO: handle as multi tab state?
                ]
            }
        ),
        createPersistedState(
            {
                key: "item_state",
                paths: [
                    'swagger.local_storage_document'
                ]
            }
        ),
    ]
})
