<template>
    <v-card
        color="transparent"
        flat
    >
        <v-row no-gutters justify="center">
            <div
                class="app-icon v-btn v-btn--fab v-btn--round v-btn--disabled pa-2"
                :style="css_props"
            >
                <v-icon
                    :color="color"
                    :dark="dark"
                    :light="light"
                    :dense="dense"
                    :disabled="disabled"

                    :size="size"
                    :large="large"
                    :small="small"
                    :xLarge="xLarge"
                    :xSmall="xSmall"
                >
                    {{ internal_value }}
                </v-icon>

            </div>

            <v-card-title
                v-if="horizontal && label"
                v-text="translate(label)"
                class="pa-0 ml-2"
            />
        </v-row>

        <v-row
            v-if="!horizontal && labelHeader"
            justify="center"
            class="mt-2"
            no-gutters
        >
            <v-card-subtitle
                v-text="locale_key(labelHeader, null, true)"
                class="pa-0"
                :style="`color: var(--v-${color}-base)`"
            />
        </v-row>
        <v-row
            v-if="!horizontal && label"
            :class=" labelHeader ? 'mt-0': 'mt-2'"
            justify="center"
            no-gutters
        >
            <v-card-title
                v-text="translate(label)"
                class="pa-0"
                :style="`color: var(--v-${color}-base)`"
            />
        </v-row>


    </v-card>

</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import common_mixin from "@/mixins/common/mixin"

export default {
    name: "AppIcon",
    props: {
        value: String,
        color: String,
        dark: Boolean,
        light: Boolean,
        dense: Boolean,
        disabled: Boolean,
        large: Boolean,
        small: Boolean,
        xLarge: Boolean,
        xSmall: Boolean,
        size: [Number, String],
        label: String,
        labelHeader: String,
        horizontal: Boolean
    },
    mixins: [common_mixin],
    components: {},
    data() {
        return {
            internal_value: undefined
        }
    },
    computed: {
        css_props() {
            if (!this.color) return {
                '--icon-color': '#fff'
            }
            return {
                '--icon-color': `var(--v-${this.color}-base)`
            }
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style lang="sass" scoped>
.app-icon::before
    content: ''
    color: var(--icon-color)


</style>
