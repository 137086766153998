import {mapActions, mapGetters, mapState} from "vuex";
import Vue from 'vue'

export default {
    mixins: [],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            'user_token_json': 'user_token_json',
            'user_is_authenticated': 'user_is_authenticated',
            'is_primary_window': 'window_manager/is_primary_window'
        }),
        ...mapState({
            'too_many_requests': state => state.too_many_requests,
            'user_token': state => state.user_token,
            'swagger': state => state.swagger.local_storage_document,
        }),
    },
    methods: {
        async api_get(payload) {
            try {
                await this.pre_api_call(payload)

                let response = await Vue.axios.get(
                    process.env.VUE_APP_API_ROOT + payload.url,
                    {
                        headers: {
                            'X-API-Key': payload.token ? payload.token : this.user_token?.token,
                            'Cache-Control': 'no-cache',
                        },
                        params: payload.params,
                    }
                );
                if (payload.commit) {
                    payload['data'] = response['data']
                    this.set_state_property(payload)
                }
                return response;
            } catch (error) {
                this.general_error_handling(error.response)
                throw error.response;
            }
        },
        async api_post(payload) {
            try {
                await this.pre_api_call(payload)
                let config = {}
                if ('token' in payload){
                    config = {
                        headers: {
                            'X-API-Key': payload.token
                        }
                    }
                } else if (this.user_token!==null){
                    config = {
                        headers: {
                            'X-API-Key': this.user_token.token
                        }
                    }
                }
                return await Vue.axios.post(
                    process.env.VUE_APP_API_ROOT + payload.url,
                    payload.data,
                    config
                );
            } catch (error) {
                console.info("POST ERROR:", error)
                this.general_error_handling(error.response)
                throw error.response;
            }
        },
        async api_delete(payload) {
            try {
                await this.pre_api_call(payload)

                let headers = {
                    'X-API-Key': payload.token ? payload.token : this.user_token.token,
                }
                if (payload['if_match']) headers['If-Match'] = payload['if_match']
                return await Vue.axios.delete(
                    process.env.VUE_APP_API_ROOT + payload.url,
                    {
                        headers: headers,
                    }
                )
            } catch (error) {
                this.general_error_handling(error.response)
                throw error.response
            }
        },
        async api_patch(payload) {
            try {
                await this.pre_api_call(payload)

                return await Vue.axios.patch(
                    process.env.VUE_APP_API_ROOT + payload.url,
                    payload.data,
                    {
                        headers: {
                            'X-API-Key': this.user_token.token,
                            'If-Match': payload.if_match
                        }
                    }
                )
            } catch (error) {
                this.general_error_handling(error.response)
                throw error.response
            }
        },
        async api_put(payload) {
            try {
                await this.pre_api_call(payload)

                return await Vue.axios.put(
                    process.env.VUE_APP_API_ROOT + payload.url,
                    payload.data,
                    {
                        headers: {
                            'X-API-Key': this.user_token.token,
                            'If-Match': payload.if_match
                        }
                    }
                )

            } catch (error) {
                this.general_error_handling(error.response)
                throw error.response
            }
        },
        async pre_api_call(payload) {
            if (this.token_expired === undefined){
                throw "You have to include token mixin when you use the api mixin"
            }
            if (this.too_many_requests) {
                this.set_state_property({state_property: "too_many_requests", data: true})
            }
            let do_refresh_token_token = this.user_is_authenticated && this.token_expired() && payload.url !== '/authenticates/refresh-token'
            if (do_refresh_token_token) {
                console.log("Waiting for a token refresh")
                let total_millis_waited = 0
                while (this.token_expired() && this.user_is_authenticated) {
                    await this.sleep(500)
                    total_millis_waited += 500
                    if (total_millis_waited >= 1000 * 40) {
                        break
                    }
                }
            }
        },

        general_error_handling(error) {
            if (error === undefined) return
            if (error.status === 401) {
                this.sign_out()
                console.error("Unauthorized", error)
                // this.sign_out('unauthorized')
            } else if (error.status === 419) {
                this.sign_out()

                // await this.refresh_token()
            }

        },

        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async api_call_wrapper(callback, error_callback = undefined, use_snackbar = true, success_text = 'common.ok') {
            this.loading = true
            let result = undefined
            try {
                result = await callback()
                if (this.snackbar && use_snackbar) {
                    this.snackbar.text = success_text
                    this.snackbar.json = undefined
                    this.snackbar.color = "success"
                }
            } catch (error) {
                if (error_callback) {
                    result = await error_callback(error)
                }
                if (this.snackbar && use_snackbar) {
                    this.snackbar.text = this.deep_get(error, 'data._locale_message') || 'system.error'
                    this.snackbar.json = error.data
                    this.snackbar.color = "error"
                }
            } finally {
                if (this.snackbar && use_snackbar) {
                    this.snackbar.display = true
                }
                this.loading = false
            }
            return result
        },

        async api_get_all_items(resource, where = {}, projection = {}) {
            let page = 1
            let max_results = 25
            let items = []

            let total = undefined
            let continue_to_fetch = true

            while (continue_to_fetch) {
                let request = await this.api_get(
                    {
                        url: `/${resource}`,
                        params: {where, projection, page, max_results}
                    }
                )
                if (total === undefined) {
                    total = request.data['_meta']['total']
                }

                items = items.concat(request.data['_items'])
                if (items.length >= total) {
                    continue_to_fetch = false
                } else {
                    page += 1
                }
            }

            return items
        },
        ...mapActions([
            'set_state_property'
        ]),
    }
}


