<template>
    <v-card
        :height="height"
        color="transparent"
        flat
        tile
    >
        <v-row no-gutters align="center">
            <AppIcon
                v-if="label"
                value="mdi-code-json"
                :small="$vuetify.breakpoint.mdAndDown"
                :label="label"
                color="primary"
                class="py-3"
                horizontal
            />
            <v-spacer/>
            <v-row
                v-if="editing"
                justify="end"
                align="center"
                no-gutters
            >
                <v-btn-toggle
                    v-model="edit_mode"
                    dense
                    group
                >
                    <v-btn
                        :value="'tree'"
                        :color="edit_mode=== 'tree' ? 'primary' : undefined "
                        class="app-object--rounded"
                        text
                    >
                        <v-icon>
                            mdi-file-tree
                        </v-icon>
                    </v-btn>

                    <v-btn
                        :value="'code'"
                        :color="edit_mode === 'code' ? 'primary' : undefined"
                        class="app-object--rounded"
                        text
                    >
                        <v-icon>
                            mdi-format-text
                        </v-icon>
                    </v-btn>
                </v-btn-toggle>
                <v-btn
                    v-if="clearable"
                    @click="clear"
                    color="error"
                    icon
                >
                    <v-icon>
                        {{ clear_icon }}
                    </v-icon>
                </v-btn>
            </v-row>


        </v-row>

        <VJsoneditor
            v-model="internal_value"
            :options="json_editor_options"
            :key="editing + edit_mode"
            :style="css_props"
            height="100%"
        />

        <v-dialog
            :persistent="true"
            :value="dialog"
            width="500"
        >
            <v-card
                flat
                tile
                class="pa-4"
            >
                <v-row no-gutters align="center" class="pb-4">
                    <AppIcon
                        value="mdi-magnify"
                        :small="$vuetify.breakpoint.mdAndDown"
                        color="primary"
                    />
                    <v-card-title>
                        {{ $t('common.search') }}
                    </v-card-title>
                </v-row>
                <v-autocomplete
                    v-model="resource"
                    :label="$t('common.resource')"
                    :items="items"
                    ref="resource"
                    autofocus
                    outlined
                    dense
                />
                <AppAutocomplete
                    v-model="search"
                    :resource="resource"
                    :key="resource"
                    :disabled="!resource"
                    ref="search"
                    label="common.search"
                    item_value="_id"
                    @keyup.native.enter="handle_submit"
                />
                <v-row no-gutters>
                    <v-col class="text-right">
                        <v-btn
                            @click="close_dialog(false)"
                            class="pl-4"
                        >
                            {{ $t('common.cancel') }}
                        </v-btn>
                        <v-btn
                            @click="close_dialog"
                            :disabled="!search"
                            color="primary"
                            class="ml-2"
                        >
                            {{ $t('common.save') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

    </v-card>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import VJsoneditor from 'v-jsoneditor'
import AppIcon from "@/components/app_icon/Index"
import common_mixin from '@/mixins/common/mixin'
import AppAutocomplete from "@/components/app_autocomplete/Index";

export default {
    name: "AppJsonEditor",
    props: {
        value: {
            'type': [Object, Array]
        },
        height: {
            type: String,
            default: '100%'
        },
        editing: {
            type: Boolean
        },
        label: {
            type: String,
            default: ''
        },
        clearable: Boolean,
        clear_icon: {
            type: String,
            default: 'mdi-delete'
        }
    },
    mixins: [common_mixin],
    components: {
        AppAutocomplete,
        VJsoneditor,
        AppIcon
    },
    data() {
        return {
            internal_value: {},
            css_props: {
                '--border': '1px solid',
                '--radius': '4px',
                '--color': this.$vuetify.theme.themes.light.primary
            },
            search: '',
            dialog: false,
            element: null,
            resource: '',
            edit_mode: 'tree'
        }
    },
    computed: {
        json_editor_options() {
            if (!this.editing) return {mode: 'view'}

            return {
                mode: this.edit_mode,
                onEvent: this.handle_event
            }
        },
        items() {
            let resources = []
            for (let resource in this.access_rights) {
                resources.push(resource)
            }
            return resources.filter(r => this.has_get_resource_access(r))
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        },
        resource(val) {
            this.search = null
            if (val) {
                this.$nextTick(() => {
                    this.$refs.resource.blur()
                    this.$refs.search.focus()
                })
            }
        },
    },
    methods: {
        clear() {
            this.internal_value = {}
        },
        async handle_event(node, event) {
            if (event.inputType !== 'insertText') return
            if (event.data === '*') {
                this.dialog = true
                this.element = event.target
            }
        },
        handle_submit() {
            if (!this.search?.length) return
            this.close_dialog()
        },
        close_dialog(save = true) {
            this.dialog = false
            if (save) {
                this.element.innerText = this.search
                this.element.dispatchEvent(new Event('input', {bubbles: true}))
            }
            this.$refs.search.blur()
            this.element = null
            this.search = null
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        if (this.value) {
            this.internal_value = this.value
        }
        if (!this.label) {
            this.css_props['--border'] = 'none'
            this.css_props['--radius'] = '0'
        }
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped lang="sass">
::v-deep .jsoneditor-box
    width: 100vw
    max-width: 100%

::v-deep .jsoneditor-container .min-box
    height: initial

::v-deep .jsoneditor-text-errors
    border: none

::v-deep .jsoneditor,
::v-deep .jsoneditor-statusbar
    border-radius: var(--radius)

::v-deep .jsoneditor
    border: var(--border)
    border-color: var(--color)

::v-deep .jsoneditor-poweredBy,
::v-deep button.max-btn
    display: none !important
</style>
