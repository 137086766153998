<template>
    <div>
        <ContentTitle
            :title="$t('common.welcome') + ' ' + user_token_json.u_name"
            width="90%"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import ContentTitle from "@/components/content_title/Index";
import common_mixin from "@/mixins/common/mixin";


export default {
    name: 'Home',
    props: [
        'status',
        'status_message'
    ],
    mixins: [common_mixin],
    components: {ContentTitle},
    data() {
        return {
            display_status: false
        }
    },
    computed: {
        ...mapGetters([
            'user_token_json',
        ]),
        ...mapState([]),
    },
    watch: {
        display_status(new_val, old_val) {
            if (old_val === true && new_val === false) {
                this.$router.push({'name': 'Home'})
            }
        },
        status(val) {
            if (val) {
                this.display_status = true
            }
        },
    },
    methods: {

        ...mapActions([
            // ...
        ]),
    },
    created() {
        if (this.status) {
            this.display_status = true
        }
    },
    beforeCreate() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
