import local_storage_mixin from "@/mixins/common/local_storage_mixin";

export default {
    namespaced: true,
    state: {
        window_uuid: null,
        windows: [
            /*
            {
                id: uuid-string,
                primary: Boolean,
                tick: time in ms
            }
            */
        ]
        //    Tick is stored in local storage
    },
    getters: {
        is_primary_window: (state) => {
            if (state.window_uuid === null) return false

            let windows = state.windows.filter(x => x.uuid === state.window_uuid)
            if (windows.length === 0) return false
            return windows[0].primary
        }
    },
    mutations: {
        add(state, new_window) {
            if (!state.windows) state.windows = []
            state.window_uuid = new_window.uuid
            state.windows.push(new_window)
            local_storage_mixin.methods.set_local_storage('windows', state.windows)
        },
        run_primary_window(state) {
            // Tick
            let index = state.windows.map(x => x.uuid).indexOf(state.window_uuid)
            if (index === -1) return
            state.windows[index]['tick'] = new Date().getTime()

            //  Remove dead windows
            const now = new Date().getTime()
            const seconds_after_the_window_is_declared_dead = 8.1

            let dead_window_uuids = state.windows
                .filter(x => !x.primary && now - x.tick > seconds_after_the_window_is_declared_dead * 1000)
                .map(x => x.uuid)

            dead_window_uuids.forEach(uuid => {
                let index = state.windows.map(x => x.uuid).indexOf(uuid)

                if (index > -1) {
                    state.windows.splice(index, 1)
                }
            })
            local_storage_mixin.methods.set_local_storage('windows', state.windows)
        },
        run_secondary_window(state) {
            // Reset in case not registered
            let window_is_registered = state.windows.filter(x => x.uuid === state.window_uuid).length
            if (!window_is_registered) {
                state.window_uuid = null
                return
            }

            // Tick
            let index = state.windows.map(x => x.uuid).indexOf(state.window_uuid)
            state.windows[index]['tick'] = new Date().getTime()

            const now = new Date().getTime()
            const seconds_after_the_window_is_declared_dead = 8.1
            let primary_windows = state.windows.filter(x => x.primary)


            // Remove primary window if primary window is dead
            let dead_primary_window_uuids = primary_windows.filter(
                x => now - x.tick > seconds_after_the_window_is_declared_dead * 1000
            ).map(
                x => x.uuid
            )
            dead_primary_window_uuids.forEach(uuid => {
                let index = state.windows.map(x => x.uuid).indexOf(uuid)
                if (index > -1) {
                    state.windows.splice(index, 1)
                }
            })

            // Assign new primary window
            if (primary_windows.length === dead_primary_window_uuids.length) {
                let available_windows = state.windows.filter(
                    x => !x.primary && (now - x.tick < seconds_after_the_window_is_declared_dead * 1000)
                )

                if (!available_windows.length) return

                let new_primary_window = available_windows[0]
                let new_primary_window_index = state.windows.map(x => x.uuid).indexOf(new_primary_window.uuid)
                state.windows[new_primary_window_index]['primary'] = true
            }

            local_storage_mixin.methods.set_local_storage('windows', state.windows)
        },
    },
    actions: {
        window_manager_handler({commit}, payload) {
            commit(payload.method, payload.data)
        },
    },

}
