import {mapGetters, mapState} from "vuex";

export default {
    mixins: [],
    data() {
        return {}
    },
    computed: {
        tabs() {
            let properties_exist = this.dialog_schema?.properties
            if (!properties_exist) return undefined

            let tabs = ['details']
            Object.keys(this.dialog_schema['properties']).forEach(key => {
                let property = this.dialog_schema['properties'][key]
                if (property?.type === 'array') {
                    let allow_view = !property.excluded && !this.create_new && !this.is_editing && ['read', 'write'].indexOf(property['access_right']) > -1
                    let allow_create = (this.create_new || this.is_editing) && property['access_right'] === 'write' && !property['readOnly']

                    if (allow_view || allow_create) {
                        tabs.push(key)
                    }
                }
            })

            return tabs
        },
        properties_by_tab() {
            if(this.tabs === undefined) return {}

            let properties_by_tab = {}
            let properties = this.dialog_schema['properties']
            for (let tab of this.tabs) {
                properties_by_tab[tab] = []
                if (tab === 'details') {
                    for (let key of Object.keys(properties)) {
                        if (key === '_id') continue
                        let property = properties[key]
                        property['name'] = key
                        if (!property.type || property.type !== 'array') {
                            properties_by_tab[tab].push(property)
                        }

                    }
                } else {
                    let property = properties[tab]
                    property['name'] = tab
                    properties_by_tab[tab].push(property)
                }
            }
            for (let key of Object.keys(properties_by_tab)) {
                if (!properties_by_tab[key].length) {
                    delete properties_by_tab[key]
                }
            }
            return properties_by_tab
        },
        required_tabs() {
            if (this.dialog_schema === undefined ) {
                return []
            }
            let properties = this.dialog_schema['properties']
            return Object.keys(this.properties_by_tab)
                .filter(x => this.is_required(properties[x]) && (this.is_editing || this.create_new))
        },

        icon_by_tab_name() {
            let icon_by_tab_name = {}
            let tab_names = Object.keys(this.properties_by_tab)
            tab_names.forEach(x => {
                icon_by_tab_name[x] = this.get_icon(x, "mdi-text-box-multiple")
            })

            /*Only used in the dialog where custom tabs is used*/
            if (this.custom_tabs) {
                this.custom_tabs.forEach((x) => {
                    icon_by_tab_name[x['title']] = x['icon']
                })
            }
            return icon_by_tab_name
        },
        selected_tab_name() {
            /*Tab names received from dialog or zoom_dialog*/
            return this.tab_names && this.tab_names.length ? this.tab_names[this.tab_index] : ''
        },
        ...mapGetters({}),
        ...mapState({})
    },
    methods: {},
}
