<template>
    <div>
        <span class="user-authentication__bg"/>
        <v-alert
            prominent
            type="error"
        >
            <v-row align="center">
                <v-col class="text-center grow">
                    {{ too_many_requests_message }}
                </v-col>
            </v-row>
        </v-alert>
        <v-row no-gutters align="center" justify="center">
            <v-col
                cols="12" xs="12" sm="6" md="6" lg="4" xl="4"
                class="pa-2"
            >
                <v-card
                    min-height="325px"
                    class="mx-auto"
                    :style="'margin-top: ' + ($vuetify.breakpoint.xsOnly ? '10px' : '80px') + ';margin-bottom: 150px'"
                    elevation="12"
                >
                    <v-card-title
                        class="secondary"
                    >
                        <v-spacer/>
                        <span style="color: whitesmoke">{{ system_name }}</span>
                        <v-spacer/>
                    </v-card-title>

                    <v-row
                        justify="center"
                        class="mt-12"
                        no-gutters
                    >
                        <v-btn
                            :small="small_screen"
                            @click="sign_out('manual_sign_out')"
                            color="primary"
                        >
                            <v-icon class="mr-2">mdi-logout</v-icon>
                            {{ $t('common.logout') }}
                        </v-btn>
                    </v-row>
                    <v-row
                        justify="center"
                        class="mt-12"
                        no-gutters
                    >
                        <v-btn
                            :small="small_screen"
                            @click="refresh()"
                            color="secondary"
                        >
                            <v-icon class="mr-2">mdi-refresh-circle</v-icon>
                            {{ $t('common.try_again') }}
                        </v-btn>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import common_mixin from "@/mixins/common/mixin";

export default {
    name: "TooManyRequests",
    mixins: [common_mixin],
    props: [],
    components: {},
    data() {
        return {}
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['too_many_requests', 'too_many_requests_message', 'system_name']),
    },
    watch: {},
    methods: {
        refresh() {
            console.info("REFRESH")
            location.reload()
        },

        ...mapActions([
            "set_state_property",
        ]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },

}
</script>

<style scoped>
</style>
