<template>
    <v-switch
        v-if="create_new || is_editing"
        :input-value="internal_value"
        :label="label"
        :disabled="property.excluded || property.access_right === 'read'"
        :readonly="(!is_editing && !create_new) || property.excluded || property.access_right === 'read'"
        @change="$emit('input', $event)"
    />
    <v-card-text
        v-else
        class="pa-0"
    >
        <ItemBodyValue
            :value="internal_value"
            :label="label"
            left
            is-boolean
        />
    </v-card-text>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import ItemBodyValue from "@/components/item_body_value/Index";

export default {
    name: "ItemDialogTabDataBooleanSwitch",
    props: {
        value: {
            'type': [String, Object, Number, Boolean, Array]
        },
        create_new: Boolean,
        is_editing: Boolean,
        label: String,
        property: Object,
    },
    mixins: [],
    components: {ItemBodyValue},
    data() {
        return {
            internal_value: undefined
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
