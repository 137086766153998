<template>
    <div>
        <v-snackbar
            v-model="internal_value"
            :color="color"
            :timeout="timeout"
            style="z-index: 99"
            absolute
            bottom
        >
            {{ translate(text) }}

            <v-btn
                v-if="json"
                @click="display_json_dialog = true"
                class="mx-2"
                outlined
                small
            >
                {{
                    $t('common.details')
                }}
            </v-btn>

            <template
                v-slot:action="{ attrs }"
            >
                <v-btn
                    v-bind="attrs"
                    @click="internal_value = false"
                    style="margin-right: 8px !important;"
                    icon
                    small
                >
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </template>
        </v-snackbar>


        <AppDialog
            v-model="display_json_dialog"
            title="common.details"
            height="64vh"
            :remove_border_top_radius="true"
            :remove_border_bottom_radius="true"
            medium
        >
            <template v-slot:default>
                <json-viewer
                    :value="computed_json"
                    preview-mode
                />
            </template>
            <template v-slot:footer>
                <v-toolbar
                    dense
                >
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        @click="display_json_dialog = false"
                    >
                        {{ $t('common.ok') }}
                    </v-btn>
                </v-toolbar>

            </template>
        </AppDialog>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import AppDialog from "@/components/app_dialog/Index";
import JsonViewer from 'vue-json-viewer'
import locale_mixin from "@/mixins/common/locale_mixin";

export default {
    name: "Snackbar",
    props: {
        'value': {
            'type': Boolean /*Choose one*/
        },
        text: String,
        color: String,
        timeout: Number,
        json: Object
    },
    mixins: [locale_mixin],
    components: {AppDialog, JsonViewer},
    data() {
        return {
            'internal_value': undefined,
            display_json_dialog: false
        }
    },
    computed: {
        computed_json() {
            if (!this.json) return {}
            return this.json
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val) {
                this.$emit('input', val)
            },
            deep: true
        },
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value

    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
