<template>
    <div
        :class="right ? 'text-right': 'text-left'"
    >
        <div
            class="font-weight-bold"
        >
            {{ locale_key(label, null, true) }}
        </div>
        <slot name="value">
            <AppChip
                v-if="isChip && color"
                :color="color"
                :value="formatted_value"
                :icon="icon"
                small
            />
            <v-tooltip
                v-else-if="!isBoolean"
                :disabled="(internal_value || '').length < 30"
                max-width="400px"
                bottom
                style="opacity:1"
            >
                <template #activator="{on, attrs}">
                    <div
                        v-bind="attrs"
                        v-on="on"
                        class="app-text--overflow-y-hidden-4"
                    >
                        {{ formatted_value }}
                    </div>
                </template>
                <span class="v-card">
                    {{ formatted_value }}
                </span>

            </v-tooltip>
            <div
                v-else
                :class="left ? '' : 'switch-ml-auto'"
            >
                <v-switch
                    :input-value="internal_value"
                    class="pa-0 ma-0"
                    readonly
                    hide-details
                />
            </div>

        </slot>


    </div>

</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import AppChip from "@/components/app_chip/Index";
import common_mixin from "@/mixins/common/mixin"

export default {
    name: "ItemBodyValue",
    props: {
        value: {
            type: [String, Boolean, Number]
        },
        label: String,
        isBoolean: Boolean,
        left: Boolean,
        right: Boolean,
        isChip: {type: Boolean, default: false},
        color: {type: String, default: null},
        icon: {type: String, default: null},
        isDate: {type: Boolean, default: false},
        isDatetime: {type: Boolean, default: false},
    },
    mixins: [common_mixin],
    components: {AppChip},
    data() {
        return {
            internal_value: undefined
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
        formatted_value() {
            if (this.isBoolean) return this.internal_value
            if (!this.internal_value) return "-"
            if (this.isDate) return this.format_date(this.internal_value)
            if (this.isDatetime) return this.format_date_time(this.internal_value)
            return this.translate(this.internal_value)
        }
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style lang="sass" scoped>


</style>
