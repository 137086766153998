<template>
    <v-app-bar
        :color="bar_color"
        class="app-app-header"
        absolute
    >
        <v-toolbar-title class="pr-3">
            <v-row no-gutters>
                <v-col class="hidden-md-and-up">
                    <v-btn
                        id="#bm_icon"
                        @click="$emit('open_left_navigation_drawer')"
                        :color="environment_color"
                        icon
                    >
                        <v-icon>mdi-menu</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="hidden-sm-and-down ma-auto">
                    <v-btn
                        @click="go_to('Home')"
                        id="help_home"
                        class="title font-weight-light app-object--rounded"
                        :color="environment_color"
                        x-large
                        text
                        tile
                    >
                        <v-icon>mdi-home</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-toolbar-title>

        <v-divider vertical/>

        <TheHeaderMenu
            class="hidden-sm-and-down pl-3"
            style="height: 100%"
        />
        <v-spacer/>
        <TheHeaderIconBar
            @open_right_navigation_drawer="$emit('open_right_navigation_drawer')"
            @right_navigation_component="$emit('right_navigation_component', $event)"
        />

    </v-app-bar>
</template>


<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import common_mixin from "@/mixins/common/mixin";
import TheHeaderMenu from "@/components/the_header/menu/Index";
import TheHeaderIconBar from "@/components/the_header/icon_bar/Index";


export default {
    name: "TheHeader",
    mixins: [common_mixin],
    props: {},
    components: {TheHeaderIconBar, TheHeaderMenu},
    data() {
        return {}
    },
    computed: {
        environment_color() {
                    return 'auto'
        },
        bar_color() {
            if (!this.$vuetify.theme.dark) {
                return "white";
            }
            return ""
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {

        ...mapActions([
            // ...
        ]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}

//{
//    "User": {
//        "name": {
//            "icon": "fa-square",
//            "icon_color": "",
//            "list_filter": False,
//            "list_filter_or": "",
//            "list_headline": False,
//            "list_item": False,
//            "list_sort": False,
//            "string": "",  # one_line|multiline|array
//            "tab": ""
//        }
//    }
//}
</script>

<style scoped>

</style>


