<template>
    <div>
        <v-navigation-drawer
            v-model="internal_value"
            :width="$vuetify.breakpoint.xsOnly ? '100vw' : '400px'"
            @close="internal_value = ''"
            class="app-layout--level-501"
            temporary
            absolute
            left
        >

            <v-toolbar
                color="primary"
                dark
                height="56px"
            >
                <v-row
                    align="center"
                    no-gutters
                >
                    <v-col cols="10">
                        <v-toolbar-title>
                            <v-btn
                                align="center"
                                @click="go_to('Home'), internal_value=false"
                                text
                            >
                                <v-icon
                                    class="mr-3"
                                >mdi-home
                                </v-icon>
                                <div>
                                    {{ $t("title.invoice_control") }}
                                </div>
                            </v-btn>

                        </v-toolbar-title>
                    </v-col>
                    <v-col cols="2">
                        <v-btn
                            icon
                            fab
                            x-large
                            @click="internal_value = false"
                        >
                            <v-icon>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-card
                height="calc(100vh - 56px)"
                style="overflow-y: auto"
            >
                <BurgerMenu
                    @close="internal_value = false"
                />
            </v-card>

        </v-navigation-drawer>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import common_mixin from "@/mixins/common/mixin";

export default {
    name: "TheLeftNavigationDrawer",
    props: {
        value: Boolean
    },
    mixins: [common_mixin],
    components: {
        BurgerMenu: () => import(/*webpackChunkName: "BurgerMenu"*/ "@/components/burger_menu/Index")
    },
    data() {
        return {
            'lazy_value': undefined
        }
    },
    computed: {
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.$emit('input', this.lazy_value)
            },
        },
        ...mapGetters(['user_token_json']),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.lazy_value = val
        },

    },
    methods: {
        update_internal_value(key, value) {
            this.$set(this.internal_value, key, value)
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.lazy_value = this.value

    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
