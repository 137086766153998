import {mapActions, mapGetters, mapState} from "vuex";
import api_mixin from "@/mixins/common/api_mixin";
import local_storage_mixin from "@/mixins/common/local_storage_mixin";
import navigation_mixin from "@/mixins/common/navigation_mixin";
import token_mixin from "@/mixins/common/token_mixin";
import date_mixin from "@/mixins/common/date_mixin";
import locale_mixin from "@/mixins/common/locale_mixin";
import utils_mixin from "@/mixins/common/utils_mixin";
import user_mixin from "@/mixins/common/user_mixin";
import user_settings_mixin from "@/mixins/common/user_settings_mixin"
import object_mixin from "@/mixins/common/object_mixin";
import snackbar_mixin from "@/mixins/common/snackbar_mixin";
import access_mixin from "@/mixins/common/access_mixin";
import swagger_mixin from "@/mixins/common/swagger_mixin";


export default {
    mixins: [
        access_mixin,
        api_mixin,
        date_mixin,
        local_storage_mixin,
        navigation_mixin,
        object_mixin,
        token_mixin,
        locale_mixin,
        utils_mixin,
        user_mixin,
        user_settings_mixin,
        snackbar_mixin,
        swagger_mixin
    ],
    data() {
        return {}
    },
    computed: {
        small_screen() {
            return this.$vuetify.breakpoint.xsOnly
        },
        ...mapGetters([]),
        ...mapState([
            'system',
            'system_name',
            'application_details',
        ])
    },
    methods: {
        responsive_width(xs, s, md, lg, xl) {
            return (
                this.$vuetify.breakpoint.xsOnly ? xs :
                    this.$vuetify.breakpoint.smOnly ? s :
                        this.$vuetify.breakpoint.mdOnly ? md :
                            this.$vuetify.breakpoint.lgOnly ? lg : xl
            )
        },
        async get_application_details() {
            if (!this.system) {
                return
            }
            let request = await this.api_get(
                {
                    url: '/application--details/' + this.system + '/' + process.env.VUE_APP_NAME
                }
            )
            let new_application_details = request.data

            let cleanup_storage = (
                this.application_details && this.application_details['application_version'] &&
                this.format_date_time(new_application_details['storage_cleanup_date']) > this.format_date_time(this.application_details['application_version'])
            )
            if (cleanup_storage) {
                console.log("<<<Cleanup storage>>>")
                await localStorage.clear()
                this.reset_cache()
                return
            }

            let new_application_version = (
                this.application_details && this.application_details['application_version'] &&
                this.format_date_time(new_application_details['application_version']) > this.format_date_time(this.application_details['application_version'])
            )
            let new_api_version = (
                this.application_details && this.application_details['api_version'] &&
                this.format_date_time(new_application_details['api_version']) > this.format_date_time(this.application_details['api_version'])
            )

            this.set_state_property(
                {
                    state_property: 'application_details',
                    data: new_application_details
                }
            )
            if (new_api_version) {
                console.log("<<<New API version => Reset swagger>>>")
                this.set_state_properties([
                    {
                        module: 'swagger',
                        state_property: 'local_storage_document',
                        data: null
                    },
                    {
                        module: 'swagger',
                        state_property: 'extended_schemas',
                        data: {}
                    },
                ])
            }
            if (new_application_version || new_api_version) {
                console.log("<<<New API or Application version => Reset cache>>>")
                this.reset_cache()
            }

        },
        reset_cache() {
            caches.keys().then(cache_keys => {
                cache_keys.forEach(key => {
                    caches.delete(key);
                });
            });
            window.location.reload();
        },
        scroll_to_ref(ref) {
            this.$nextTick(() => {
                let top = ref.offsetTop
                window.scrollTo({
                    top: top,
                    behavior: 'smooth'
                })
            })
        },
        focus_ref(ref, focusout = false) {
            let reference = this.$refs[ref]
            if (Array.isArray(reference) && reference.length) {
                reference = reference[0]
            }
            if (reference) {
                if (focusout) {
                    reference.blur()
                } else {
                    reference.focus()
                }
            }
        },
        console_log(msg) {
            console.log(msg)
        },
        ...mapActions([
            'set_state_properties'
        ]),
    },
    created() {
        if (this.system_name === undefined) {
            document.title = "Esi System"
        } else {
            document.title = this.system_name
        }
    }
}
