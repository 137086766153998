<template>
    <div
        id="actions"
    >
        <v-menu
            v-if="action_menu_treeview.length"
            v-model="internal_display_action_menu"
            :key="action_menu_treeview.length"
            :position-x="position.x"
            :position-y="position.y"
            :origin="origin"
            nudge-bottom="12"
            transition="scale-transition"
            min-width="220px"
            absolute
            offset-y
            tile
        >
            <template v-slot:activator="{ on }">
                <v-btn
                    ref="action_menu_button"
                    v-on="on"
                    @click="calculate_x_y"
                    :color="color"
                    :disabled="internal_display_action_menu"
                    :small="small"
                    icon
                >
                    <v-icon
                        :small="small"
                    >
                        mdi-dots-vertical
                    </v-icon>
                </v-btn>
            </template>

            <v-list dense class="pa-0">
                <ItemActionMenuItem
                    v-for="(item, index) in action_menu_treeview"
                    :key="index"
                    :index="index"
                    :item="item"
                    :position="position"
                    @action="select_action"
                    @issues="handle_issues"
                />
            </v-list>
        </v-menu>

        <ItemDialog
            v-if="internal_value && input_model_schema"
            v-model="input_data"
            :resource="resource"
            :custom_schema="input_model_schema"
            :display="display_action_dialog"
            :custom_ui_structure="custom_ui_structure"
            :custom_toolbar_title="action_item.text"
            :group_unspecified_properties="false"
            :flow_action_errors="flow_action_errors"
            @close="close"
            init_editing
            disable_load_complete_item
            disable_item_actions
            disable_dependency_links
            disable_property_access_check
            hide_oplog
        >
            <template #footer_buttons>
                <v-btn
                    :small="small_screen"
                    @click="close"
                >
                    {{ $t('common.cancel') }}
                </v-btn>
                <v-btn
                    :small="small_screen"
                    @click="perform_action"
                    color="primary"
                >
                    {{ $t('common.ok') }}
                </v-btn>
            </template>

        </ItemDialog>
        <v-dialog
            v-if="display_confirmation_dialog"
            :value="display_confirmation_dialog"
            :width="320"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            @click:outside="close"
            @keydown.esc="close"
            class="pa-0"
        >
            <v-toolbar
                color="primary"
                height="48px"
                flat
                dark
            >
                {{ translate(action_item.text) }}
            </v-toolbar>
            <v-card
                style="overflow-y: hidden; overflow-x: hidden"
                tile
            >
                <v-row no-gutters justify="center">
                    <div class="pt-6 pb-5">
                        {{ $t('actions.do_you_want_to_perform') }}
                    </div>
                </v-row>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :small="small_screen"
                        @click="close"
                    >
                        {{ $t('common.cancel') }}
                    </v-btn>
                    <v-btn
                        :small="small_screen"
                        @click="perform_action"
                        color="primary"
                    >
                        {{ $t('common.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <ItemActionMenuDisabledDialog
            v-model="display_issues_dialog"
            :title="issues_title"
            :issues="issues"
        />
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import item_mixin from "@/mixins/item/mixin"
import ItemActionMenuItem from "@/components/item_action_menu/sub_menu/MenuItem";
import ItemActionMenuDisabledDialog from "@/components/item_action_menu/disabled/Index";

export default {
    name: "ItemActionMenu",
    props: {
        resource: {
            type: String
        },
        value: {
            type: Object
        },
        color: String,
        display_action_menu: {
            type: Boolean
        },
        small: Boolean
    },
    components: {
        ItemActionMenuDisabledDialog,
        ItemActionMenuItem,
        ItemDialog: () => import("@/components/item_dialog/Index")
    },
    mixins: [item_mixin],
    data() {
        return {
            lazy_value: undefined,
            action_item: undefined,
            input_data: {},
            input_model_schema: undefined,
            display_action_dialog: undefined,
            display_confirmation_dialog: undefined,
            display_issues_dialog: undefined,
            custom_ui_structure: undefined,
            error_loading_available_actions: false,
            flow_actions: undefined,
            position: {
                x: 0,
                y: 0
            },
            flow_action_errors: {},
            origin: 'top left',
            issues_title: '',
            issues: []
        }
    },
    computed: {
        load_available_actions() {
            return this.flow_action_reference && this.flow_actions === undefined
        },
        internal_display_action_menu: {
            get: function () {
                return this.display_action_menu
            },
            set: function (val) {
                this.$emit('display_action_menu', val)
            }
        },
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.$emit('input', this.lazy_value)
            },
        },
        action_menu_treeview() {
            let treeview = []

            // if (['list', 'grid'].indexOf(this.location) > -1) {
            treeview.push({
                text: this.$t('common.open'),
                icon: 'mdi-open-in-app',
                custom_event: 'open'
            })
            // }

            if (this.flow_actions) {
                treeview = treeview.concat(this.create_flow_action_treeview(this.flow_actions))
            }

            return treeview
        },
        ...mapGetters([
            "user_token_json",
        ]),
        ...mapState([
            "user_extended_data",
        ]),
    },
    watch: {
        value(val) {
            this.lazy_value = val
        },
        async internal_display_action_menu(val) {
            if (val && this.load_available_actions) {
                await this.get_flow_actions()
            }
        },
        async flow_action_reference(val) {
            if (val && this.load_available_actions) {
                await this.get_flow_actions()
            }
        },
        display_issues_dialog(val) {
            if (val) {
                this.internal_display_action_menu = false
            }
        }
    },
    methods: {
        select_action(item) {
            if (item.custom_event) {
                this.$emit(item.custom_event, item)
            } else {
                this.action_item = item
                this.open_action_dialog()
            }
            this.internal_display_action_menu = false
        },
        close() {
            this.display_action_dialog = false
            this.display_confirmation_dialog = false
            this.input_data = {}
            this.input_model_schema = undefined
            this.action_item = undefined
        },
        async get_flow_actions() {
            this.error_loading_available_actions = false
            let available_actions

            try {
                let response = await this.api_post({
                    url: "/flow--available-actions",
                    data: {
                        reference: this.flow_action_reference,
                        access_level: "solicitor" in this.user_extended_data ? "solicitor" : "creditor",
                    }
                })
                available_actions = response.data.actions
            } catch (error) {
                this.error_loading_available_actions = true
            }

            if (available_actions) {
                this.flow_actions = this.create_flow_action_structure(available_actions)
            }
        },
        create_flow_action_structure(available_actions) {
            let actions_swagger_docs = this.swagger["x-flow-actions"]
            let flow_actions = {}

            Object.keys(available_actions).forEach((x) => {
                // Find the action definition in swagger
                const [flow_name, action_name] = x.split("__")
                let path_to_action = this.deep_find_key(actions_swagger_docs[flow_name]["actions"], action_name)
                let full_path_to_action = `${path_to_action}.${action_name}`
                let action_definition = this.deep_get(actions_swagger_docs[flow_name]["actions"], full_path_to_action)

                // Add action data needed for presentation (action menu) and execution
                let flow_action_item = {
                    ...action_definition,
                    ...available_actions[x],
                    action_id: x
                }
                this.deep_set(flow_actions, full_path_to_action, flow_action_item)

                // Set other properties regarding the menu (icons etc)
                let source = actions_swagger_docs[flow_name]["actions"]
                let target = flow_actions
                for (let key of path_to_action.split(".")) {
                    source = source[key]
                    target = target[key]
                    for (let x of Object.keys(source)) {
                        if (typeof source[x] !== "object" && !(x in target)) {
                            target[x] = source[x]
                        }
                    }
                }
            })

            return flow_actions
        },
        create_flow_action_treeview(node) {
            let treeview = []

            for (let key of Object.keys(node).filter((key) => this.is_object(node[key]))) {
                let treeview_item = {
                    text: this.translate(`common.${key}`),
                    icon: node[key]["icon"]
                }
                if ("input_model" in node[key]) {
                    let flow_action_item = node[key]
                    treeview_item = {
                        ...treeview_item,
                        ...flow_action_item,
                        disabled: !flow_action_item["available"]
                    }
                } else {
                    treeview_item = {
                        ...treeview_item,
                        children: this.create_flow_action_treeview(node[key])
                    }
                }
                treeview.push(treeview_item)
            }

            return treeview
        },
        async perform_action() {
            try {
                await this.api_post({
                    url: "/flow--actions",
                    data: {
                        reference: this.flow_action_reference,
                        action: this.action_item.action_id,
                        access_level: "solicitor" in this.user_extended_data ? "solicitor" : "creditor",
                        initiated_by: {
                            user: this.user_token_json['u_id']
                        },
                        input_data: this.truthy(this.input_data) ? this.input_data : undefined
                    }
                })
                await this.user_activity('flow_action', 'action', this.action_item.action_id)
                this.$emit('display_snackbar', {
                    text: this.$t('common.action_performed'),
                    color: 'success'
                })
                this.close()
                this.set_state_property({
                    state_property: 'signal_update_item_container',
                    data: true,
                    module: 'dialog_manager'
                })
            } catch (error) {
                this.$emit('display_snackbar', {
                    text: this.translate(error.data._error.message || error.data['_locale_message']),
                    color: 'error'
                })
                this.flow_action_errors = {}
                this.set_flow_action_errors(error.data)
            }
        },
        set_flow_action_errors(error) {
            const issues = error['_issues']
            for (const key in issues) {
                const [issue] = issues[key]
                let message = ''
                if (issue.includes('required') || issue.includes('null')) {
                    message = 'common.required'
                } else if (issue.includes('min') || issue.includes('max')) {
                    const rule = issue.split(' ')[0]
                    const value = issue.split(' ')[3]
                    message = this.$t(`rules.${rule}`, [value])
                } else {
                    message = issue
                }
                this.$set(this.flow_action_errors, key, message)
            }
        },
        async open_action_dialog() {
            if (this.truthy(this.action_item['input_model'])) {
                this.input_model_schema = await this.get_action_model()
                this.custom_ui_structure = this.create_custom_ui_structure()
                this.display_action_dialog = true
            } else {
                this.display_confirmation_dialog = true
            }
        },
        async get_action_model() {
            // todo: try-catch
            let response = await this.api_post({
                url: "/flow--action-models",
                data: {
                    reference: this.flow_action_reference,
                    action: this.action_item.action_id,
                    access_level: "solicitor" in this.user_extended_data ? "solicitor" : "creditor"
                }
            })
            return this.deep_copy(response["data"]["swagger_definition"])
        },
        create_custom_ui_structure() {
            let display_group_content = undefined
            let custom_component = undefined

            if (this.input_model_schema.description) {
                let regex = "#item_component:[A-Za-z]*"
                let match = this.input_model_schema.description.match(regex)
                if (match) {
                    custom_component = match[0].split(":").pop()
                }
            }
            if (custom_component) {
                display_group_content = [{
                    custom_component: custom_component
                }]
            } else {
                display_group_content = Object.keys(this.input_model_schema.properties).map((key) => Object({
                    property: key
                }))
            }

            return {
                card: {
                    body: {left_column: [], right_column: []},
                    footer: {},
                    header: {first_existing: []},
                },
                dialog: {
                    display_groups: [{
                        title: "common.input",
                        content: display_group_content
                    }]
                },
                dependency_links: {},
                icons: [],
                inner_objects: {},
                search_filters: [],
                sort: [],
                text_search: {},
            }
        },
        init_x_y() {
            this.$nextTick(() => {
                this.$nextTick(() => {
                    let offset = this.$refs.action_menu_button.$el.getBoundingClientRect()
                    this.position.x = offset.left + offset.width / 2
                    this.position.y = offset.top + offset.height / 2
                })
            })
        },
        calculate_x_y(mouse_event) {
            this.position.x = mouse_event.clientX
            this.position.y = mouse_event.clientY
            const viewport_width = window.innerWidth
            if (this.position.x > viewport_width / 2) {
                this.origin = 'top right'
                this.position.x -= 220
            } else {
                this.origin = 'top left'
            }
        },
        handle_issues(item) {
            this.display_issues_dialog = true
            this.issues_title = item['text']
            this.issues = item['condition_issues']
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.init_x_y()
        window.addEventListener('contextmenu', this.calculate_x_y)
        this.lazy_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
        window.removeEventListener('contextmenu', this.calculate_x_y)
    },
    destroyed() {
    },
}
</script>
<style lang="sass" scoped>

</style>
