export default {
    data() {
        return {}
    },
    methods: {
        success_snackbar_text(text) {
            let data = {
                text: text,
                color: 'success',
                json: undefined
            }
            if (this.snackbar) {
                this.update_snackbar(data)
            }

            return data
        },
        custom_snackbar_error(text) {
            let data = {
                text: text,
                color: 'error',
                json: undefined
            }

            if (this.snackbar) {
                this.update_snackbar(data)
            }

            return data
        },
        snackbar_api_error(error) {
            let data = {
                text: this.deep_get(error, 'data._locale_message') || 'system.error',
                color: 'error',
                json: error.data
            }

            if (this.snackbar) {
                this.update_snackbar(data)
            }

            return data
        },
        update_snackbar(data) {
            Object.entries(data).forEach(([k, v]) => {
                this.snackbar[k] = v
            })
        }
    }
}
