<template>
    <v-row no-gutters justify="space-between">
        <v-col
            v-if="!is_editing && !create_new && chip_color"
            :cols="removable && is_editing ? '11': '12'"
        >
            <v-card-text class="pa-0">
                <div class="font-weight-bold">
                    {{ translate(label) }}
                </div>
            </v-card-text>
            <AppChip
                :label="label"
                :value="locale_key(value, enum_locations, true) || '-'"
                :property="property"
                :resource="resource"
                :color="chip_color"
            />
        </v-col>
        <v-col
            v-else-if="!is_readonly"
            :cols="removable && is_editing ? '11': '12'"
        >
            <v-select
                :value="value"
                :items="key_locale_array(property.enum, enum_locations)"
                :disabled="is_readonly"
                :placeholder="text_field_hint()"
                :label="label"
                :readonly="is_readonly"
                :filled="is_readonly"
                :clearable="(is_editing || create_new)  && !removable"
                :rules="(is_editing || create_new) ? [validate(value, property, original_value)] : []"
                :error-messages="translate(error_message)"
                @input="handle_input"
                item-text="locale"
                item-value="key"
                outlined
                dense
            />

        </v-col>
        <v-col
            v-else
            cols="12"
        >
            <v-text-field
                :value="locale_key(value, enum_locations, true)"
                :type="property.type === 'integer' || property.type === 'number' ? 'number' : property.type"
                :label="label"
                :placeholder="field_placeholder()"
                :hint="field_hint()"
                readonly
                filled
                outlined
                dense
            />
        </v-col>
        <v-col v-if="removable && is_editing" cols="1">
            <v-row no-gutters justify="end">
                <v-btn
                    @click="$emit('remove_value_from_array')"
                    color="error"
                    icon
                    large
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import item_mixin from '@/mixins/item/mixin'
import AppChip from "@/components/app_chip/Index";

export default {
    name: "ItemDialogTabDataSelect",
    props: {
        'value': {
            type: [String, Number],
        },
        'resource': {
            type: String
        },
        'property': {
            type: Object,
            required: true
        },
        'is_editing': {
            type: Boolean,
            default: false
        },
        "create_new": {
            type: Boolean,
            default: false
        },
        'removable': {
            type: Boolean,
            value: false
        },
        'label': {
            type: String,
            required: true
        },
        'error_message': {
            type: String,
            default: ''
        }
    },
    mixins: [item_mixin],
    components: {
        AppChip
    },
    data() {
        return {
            original_value: '',
        }
    },
    computed: {
        enum_locations() {
            return [`${this.resource}.${this.property.name}`, this.resource, this.property.name, 'item']
        },
        is_readonly() {
            return (!this.is_editing && !this.create_new) || this.property.excluded || this.property.access_right === 'read'
        },
        chip_color() {
            return this.get_enum_color(this.property.name, this.value, this.resource)
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        text_field_hint() {
            if (!this.property.pattern) return ''
            return this.$t(`hint.${this.property.name}`)
        },
        handle_input(event) {
            this.$emit('input', event)
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.original_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
