<template>
    <div>
        <v-menu
            transition="scroll-y-transition"
            offset-y
            :close-on-content-click="false"
        >
            <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            v-on="{...menu, ...tooltip}"
                            class="pl-1"
                            width="100%"
                            text
                            icon
                            tile
                        >
                            <CountryFlag
                                :country="current_language_iso_3166_code"
                                size="normal"
                                class="mr-2"
                            />
                            {{ $t(`language.${current_language}`) }}
                            <v-spacer/>
                        </v-btn>
                    </template>
                    <span>{{ $t('common.change_language') }}</span>
                </v-tooltip>
            </template>
            <ScrollableWrapper>
                <template #default>
                    <v-list>
                        <v-list-item
                            v-for="(language, index) in languages.filter(x => x !== current_language)"
                            :key="'language_' + index"
                            @click="change_language(language)"
                        >
                            <v-list-item-avatar
                                tile
                                size="24"
                            >
                                <CountryFlag
                                    :country="to_iso_3166_code(language)"
                                    size="normal"
                                />
                            </v-list-item-avatar>
                            <v-list-item-title>{{ $t(`language.${language}`) }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                            <v-btn
                                color="primary"
                                @click="display_machine_translated_languages = !display_machine_translated_languages"
                                small
                                text
                                class="font-weight-regular"
                            >
                                {{  $t( display_machine_translated_languages ? 'common.hide_machine_translated_languages' :'common.show_machine_translated_languages') }}
                            </v-btn>
                        </v-list-item>


                        <v-list-item
                            v-for="(language, index) in machine_translated_languages.filter(x => (x !== current_language && display_machine_translated_languages))"
                            :key="'machine_translated_language_' + index"
                            @click="change_language(language)"
                            two-line
                        >
                            <v-list-item-avatar
                                tile
                                size="24"
                            >
                                <CountryFlag
                                    :country="to_iso_3166_code(language)"
                                    size="normal"
                                />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t(`language.${language}`) }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ $t('common.machine_translated_language') }}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                        </v-list-item>
                    </v-list>
                </template>
            </ScrollableWrapper>

        </v-menu>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import CountryFlag from 'vue-country-flag'
import common_mixin from "@/mixins/common/mixin"
import ScrollableWrapper from "@/components/scrollable_wrapper/Index";

export default {
    name: "SelectLanguage",
    props: {},
    mixins: [common_mixin],
    components: {ScrollableWrapper, CountryFlag},
    data() {
        return {
            display_machine_translated_languages: false
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        change_language(language) {
            let current_language = "" + this.current_language
            this.update_user_setting('ui.language', language)
            this.set_language(language)
            this.user_activity('user_settings', 'language_switch', {
                from: current_language,
                to: language,
            })
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
