<template>
    <v-dialog
        id="ada-dialog"
        v-model="internal_value"
        :width="responsive_width(width['xs'], width['s'], width['md'], width['lg'], width['xl'])"
        :fullscreen="$vuetify.breakpoint.xsOnly || fullscreen"
        :persistent="persistent"
        @click:outside="$emit('click_outside')"
        @keydown.esc="$emit('click_escape')"
        max-width="100vw"
    >
        <!-- 90% is max height of dialog -->
        <v-card
            id="app_dialog"
            :ref="`app_dialog_${unique_key}`"
            :height="height ? height : '90vh'"
            class="app-object--relative"
            color="transparent"
            tile
            flat
        >

            <v-toolbar
                ref="header"
                v-if="!disable_header"
                width="100%"
                dense
            >
                <v-row no-gutters justify="center" style="max-width: 100%">
                    <v-col cols="9">
                        <v-toolbar-title
                            v-text="this.translate(title)"
                            class="app-text--highlighted title pa-0 app-text--overflow-hidden-ellipsis text-center"
                        />
                    </v-col>
                </v-row>

                <div
                    class="app-object--absolute-top-right mr-2"
                >
                    <slot name="toolbar_actions">
                        <!-- Slotted toolbar_actions go here -->
                    </slot>
                    <v-btn
                        v-if="!preventClose"
                        @click="internal_value=false"
                        icon
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </div>

            </v-toolbar>

            <ScrollableWrapper
                v-if="!disable_scrolling"
                :height="content_height ?  content_height.toString() + 'px' : height ? height : undefined"
                :class="scrollable_wrapper_css_classes"
            >
                <template #default>
                    <slot name="default">
                        <!-- Slotted default go here -->
                    </slot>
                </template>
            </ScrollableWrapper>
            <v-card
                v-else
                :height="content_height ? content_height : 'auto'"
                :color="transparent ? 'transparent' : ''"
                tile
            >
                <slot name="default">
                    <!-- Slotted default go here -->
                </slot>
            </v-card>

            <v-card
                ref="footer"
                tile
            >
                <slot name="footer">
                    <!-- Slotted footer goes here -->
                </slot>
            </v-card>

        </v-card>


    </v-dialog>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import common_mixin from "@/mixins/common/mixin"
import ScrollableWrapper from "@/components/scrollable_wrapper/Index";


export default {
    name: "AppDialog",
    props: {
        'value': {
            'type': Boolean /*Choose one*/
        },
        title: String,
        persistent: Boolean,
        preventClose: Boolean,
        xSmall: Boolean,
        small: Boolean,
        medium: Boolean,
        height: {
            type: String,
            default: 'auto'
        },
        fullscreen: Boolean,
        disable_header: Boolean,
        disable_scrolling: Boolean,
        unique_key: {
            type: String,
            default: ''
        },
        transparent: Boolean,
        height_calculation_key: {
            type: String,
            default: '0'
        },
        remove_border_top_radius: Boolean,
        remove_border_bottom_radius: Boolean
    },
    mixins: [common_mixin],
    components: {ScrollableWrapper},
    data() {
        return {
            'internal_value': undefined,
            content_height: undefined,
            calculate_content_height_timeout: undefined
        }
    },
    computed: {
        width() {
            if (this.xSmall) return {xs: '', s: '60vw', md: '35vw', lg: '30vw', xl: '25vw'}
            if (this.small) return {xs: '', s: '60vw', md: '45vw', lg: '40vw', xl: '35vw'}
            if (this.medium) return {xs: '', s: '90vw', md: '85vw', lg: '80vw', xl: '75vw'}

            return {xs: '', s: '100vw', md: '90vw', lg: '90vw', xl: '90vw'}
        },
        scrollable_wrapper_css_classes() {
            let classes = []
            if (this.remove_border_top_radius) {
                classes.push('app-object--no-border-top-radius')
            }
            if (this.remove_border_bottom_radius) {
                classes.push('app-object--no-border-bottom-radius')
            }
            return classes
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            if (val === true) this.calculate_content_height()
            this.internal_value = val
        },
        height_calculation_key() {
            this.calculate_content_height()
        },
        internal_value: {
            handler(val) {
                this.$emit('input', val)
            },
            deep: true
        },
    },
    methods: {
        calculate_content_height() {
            this.$nextTick(() => {
                if (this.height === 'auto') return null
                let dialog = this.$refs[`app_dialog_${this.unique_key}`]
                let content = [this.$refs.header, this.$refs.footer]

                let content_height = dialog && dialog.$el ? dialog.$el.clientHeight : 0
                if (!content_height && this.internal_value) {
                    this.calculate_content_height()
                }
                let margin = 2
                content.forEach(x => {
                    if (x && x.$el) {
                        content_height = content_height - x.$el.clientHeight
                    } else if (x && x.clientHeight) {
                        content_height = content_height - x.clientHeight
                    }
                })
                this.content_height = content_height - margin
                this.$emit('updated_content_height')
            })
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
        if (this.internal_value === true) this.calculate_content_height()
        window.addEventListener('resize', this.calculate_content_height)
    },
    beforeMount() {
        // if (this.content_height) this.calculate_content_height()
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
        clearTimeout(this.calculate_content_height_timeout)
        window.removeEventListener('resize', this.calculate_content_height)

    },
    destroyed() {
    },
}
</script>
<style lang="sass" scoped>


</style>
