export default {
    namespaced: true,
    state: {
        local_storage_document: null,
        extended_schemas: {},
        resource_titles_in_extended_schemas: [],
        version: null,
    },
    getters: {
        loaded: (state) => {
            return !!state.local_storage_document
        },
        resource_domains_with_no_access_control: (state) => {
            return state.local_storage_document['x-domains-with-no-access-control']
        }
    },
    mutations: {
        update_extended_schema(state, payload) {
            state.extended_schemas[payload.resource_title] = payload.schema
            if (!state.resource_titles_in_extended_schemas.includes(payload.resource_title)) {
                let list_cpy = JSON.parse(JSON.stringify(state.resource_titles_in_extended_schemas))
                list_cpy.push(payload.resource_title)
                state.resource_titles_in_extended_schemas = list_cpy
            }
        }
    },
    actions: {
        update_extended_schema({commit}, payload) {
            commit('update_extended_schema', payload)
        }
    },

}
