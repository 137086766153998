<template>
    <div>
        <v-row no-gutters align="center" justify="center">
            <v-col
                cols="12"
                class="pa-2"
            >
                <v-card
                    min-height="325px"
                    class="mx-auto"
                    elevation="12"
                >
                    <v-card-title
                        class="primary"
                    >
                        <v-spacer/>
                        <span style="color: whitesmoke">Select System</span>
                        <v-spacer/>
                    </v-card-title>
                    <v-row no-gutters align="stretch" justify="center" class="ma-4">
                        <v-col
                            v-for="item in existing_system_details"
                            :key="item.system"
                            cols="12" xs="12" sm="12" md="6" lg="4" xl="3"
                            class="d-flex flex-column"
                        >
                            <v-card
                                class="ma-4 flex d-flex flex-column"
                                hover
                                raised
                                rounded="xl"
                                @click="select_system(item)"
                            >
                                <v-card-title class="grey lighten-3">
                                    <v-icon
                                        class="mr-2"
                                        color="primary"
                                        large
                                    >
                                        {{ item.system_icon }}
                                    </v-icon>
                                    {{ item.system_name }}
                                </v-card-title>
                                <v-divider/>
                                <v-card-text>{{ item.description }}</v-card-text>
                            </v-card>

                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
<!--        <v-row-->
<!--            v-if="!system"-->
<!--            no-gutters align="center" justify="center" class="mt-10">-->
<!--            <v-btn-->
<!--                @click="$router.push('/register-system')"-->
<!--                x-small-->
<!--            >-->
<!--                Register New System-->
<!--            </v-btn>-->
<!--        </v-row>-->
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';

import common_mixin from "@/mixins/common/mixin";

export default {
    name: "SelectSystem",
    props: {
        /*
            prop_name: {
                type: "String",
                required: true
            }
            */
    },
    mixins: [common_mixin],
    components: {},
    data() {
        return {}
    },
    computed: {
        ...mapGetters([]),
        ...mapState([
            'system',
            'existing_system_details',
        ]),
    },
    watch: {},
    methods: {
        select_system(item) {
            this.set_local_storage('system', item.system)
            this.set_local_storage('system_name', item.system_name)
            this.set_state_property({state_property: "system", data: item.system})
            this.set_state_property({state_property: "system_name", data: item.system_name})

            if (this.$route.path==="/"){
                this.location.reload()
            } else {
                this.$router.push('/')
            }
        },
        ...mapActions([
            'set_state_property'
        ]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
