<template>
    <v-card
        :ref="`ada_tabs_${title}_${label}`"
        :style="css_props"
        :disabled="disabled"
        :height="height"
        max-width="100vw"
        max-height="100vh"
        min-height="60vh"
        color="transparent"
        tile
        flat
    >
        <v-row no-gutters ref="primary_header" class="mt-0">
            <slot name="header">
                <v-toolbar
                    v-if="!hideHeader"
                    class="app-object--relative"
                    :height="$vuetify.breakpoint.smAndDown && double_toolbar_height_on_sm_and_down ? '110px' : '64px'"
                    flat
                >

                    <div class="app-object--absolute__left app-object--absolute-center-y app-layout--level-10">
                        <v-card-actions>
                            <slot name="left_toolbar_actions">
                                <!-- Slotted action buttons go here -->
                            </slot>
                        </v-card-actions>
                    </div>


                    <v-card
                        class="app-object--absolute-bottom-center text-center mb-1 app-layout--level-8"
                        width="100%"
                        color="transparent"
                        :style="`left: calc(50% + ${tablist_width / 2}px)`"
                        flat
                        tile
                    >
                        <v-card-text
                            v-text="translate(label)"
                            class="pa-0 app-text--highlighted"
                            style="margin-bottom: -0.3rem"
                        />
                        <v-row no-gutters justify="center">
                            <v-card-title
                                v-text="translate(title)"
                                class="pa-0 title app-text--highlighted"
                            />
                        </v-row>

                    </v-card>

                    <v-card
                        class="app-object--absolute__top-right app-object--absolute-top-right app-layout--level-10"
                        color="transparent"
                        flat
                        tile
                    >
                        <v-card-actions>
                            <slot name="right_toolbar_actions">
                                <!-- Slotted action buttons go here -->
                            </slot>
                        </v-card-actions>
                    </v-card>

                </v-toolbar>
            </slot>
        </v-row>

        <v-row no-gutters ref="secondary_header" class="mt-0">
            <slot name="secondary_header">
                <!--  default secondary header goes here  -->
            </slot>
        </v-row>


        <v-tabs
            ref="vertical_tabs"
            v-model="internal_value"
            :vertical="vertical"
            :horizontal="horizontal"
            icons-and-text
        >
            <v-tab
                :ref="`tab_${index}`"
                v-for="(tab_name, index) in tab_names"
                :key="index + tabs_with_errors"
                :style="(bottom || hide_tab_navigation)  ? 'display: none' : ''"
                :class="index === 0 ? 'ml-4 mr-3 mt-4 app-object--rounded' : 'ml-4 mr-3 mt-2 app-object--rounded' "
            >
                <v-row no-gutters class="text-left">
                    {{ locale_key(tab_name, locale_locations, true) }}
                    {{ required_tab(tab_name) ? '*' : '' }}
                </v-row>
                <v-row no-gutters>
                    <v-icon v-if="icon_by_tab_name">
                        {{ icon_by_tab_name[tab_name] }}
                    </v-icon>
                    <v-icon
                        v-if="tab_with_errors(tab_name)"
                        color="error"
                        class="ml-2"
                    >
                        mdi-alert-outline
                    </v-icon>
                </v-row>
            </v-tab>
            <slot name="tab-items" class="tab-items">
                <!-- Slotted v-tab-item's go here... default inside slot-->
                <v-progress-circular
                    id="ada_tab_spinner"
                    color="primary"
                    class="app-object--absolute-center app-layout--level-10"
                    indeterminate
                />
            </slot>
        </v-tabs>

        <div v-if="!bottom" ref="footer">
            <slot name="footer">
                <!-- Slotted footer go here -->
            </slot>
        </div>

        <v-footer
            v-if="bottom"
            class="pa-0"
            fixed
        >
            <v-card
                width="100%" flat tile
            >
                <slot name="footer">
                    <!-- Slotted footer go here -->
                </slot>
            </v-card>

            <v-tabs
                v-model="internal_value"
                color="primary"
                height="54px"
                slider-size="1"
                class="app-object--rounded"
                show-arrows
                flat
                icons-and-text
            >
                <v-tabs-slider/>
                <v-tab
                    v-for="(tab_name, index) in  tab_names"
                    :key="index"
                    :style="hide_tab_navigation ? 'display: none':''"
                >
                    <span>{{ locale_key(tab_name, locale_locations, true) }}</span>
                    <v-icon
                        small
                        :color="tab_with_errors(tab_name) ? 'error': ''"
                    >
                        {{ icon_by_tab_name[tab_name] }}
                    </v-icon>
                </v-tab>
            </v-tabs>
        </v-footer>

    </v-card>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import common_mixin from "@/mixins/common/mixin"

export default {
    name: "AppTabs",
    props: {
        vertical: Boolean,
        horizontal: Boolean,
        bottom: Boolean,
        disabled: Boolean,
        hideHeader: Boolean,
        value: {
            'type': Number,
            default() {
                return 0
            }
        },
        title: String,
        label: String,
        tab_names: Array,
        tabs_with_errors: {
            type: Array,
            default() {
                return []
            }
        },
        required_tabs: {
            type: Array,
            default() {
                return []
            }
        },
        icon_by_tab_name: Object,
        locale_locations: {
            type: Array,
            default() {
                return []
            }
        },
        height: {
            type: String,
            default: 'auto'
        },
        recalculate_height_key: String,
        disable_scroll: Boolean,
        double_toolbar_height_on_sm_and_down: Boolean,
        hide_tab_navigation: Boolean
    },
    mixins: [common_mixin],
    components: {},
    data() {
        return {
            internal_value: 0,
            toolbar_height: '56px',
            load_css_props: false,
            css_props: {
                '--tab-height': `50vh`
            },
            min_height: '50vh',
            max_height: '100vh',
            tablist_width: 0
        }
    },
    computed: {

        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        tab_names(new_val, old_val) {
            if (old_val && new_val && JSON.stringify(new_val) !== JSON.stringify(old_val)) {
                let old_tab_name = old_val[this.internal_value]

                let new_index = new_val.indexOf(old_tab_name)
                if (new_index > -1) {
                    this.$emit('input', new_index)
                } else {
                    this.$emit('input', 0)
                }
            }
            this.calculate_vertical_tabs_width()
        },
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val) {
                this.$emit('input', val)
            },
            deep: true
        },
        recalculate_height_key() {
            setTimeout(this.create_css_props, 250)
        },
    },
    methods: {
        create_css_props() {
            if (this.disable_scroll) {
                this.css_props = {
                    '--tab-height': 'auto'
                }
                return
            }
            this.$nextTick(() => {
                let ada_tabs_ref = `ada_tabs_${this.title}_${this.label}`
                let ada_tabs = this.$refs[ada_tabs_ref].$el

                let total_height = ada_tabs.clientHeight
                if (!total_height) {
                    return this.create_css_props()
                }

                let content = [this.$refs.primary_header, this.$refs.secondary_header, this.$refs.footer]
                let sum_content_heights = content.map(x => {
                    if (x && x.$el) {
                        return x.$el.clientHeight
                    } else if (x && x.clientHeight) {
                        return x.clientHeight
                    }
                    return 0
                }).reduce((prev, cur) => cur + prev, 0)

                this.css_props = {
                    '--tab-height': `calc(${total_height}px - ${sum_content_heights}px)`
                }
            })
        },
        async calculate_vertical_tabs_width() {
            this.$nextTick(() => {
                let vertical_tabs = this.$refs.vertical_tabs
                let width = vertical_tabs.$el.querySelector('[role="tablist"]').offsetWidth
                if (width && !this.hide_tab_navigation) {
                    this.tablist_width = width - 12 // remove 12 due to margins
                } else {
                    this.tablist_width = 0
                }
            })
        },
        tab_with_errors(tab_name) {
            return this.tabs_with_errors.indexOf(tab_name) > -1
        },
        required_tab(tab_name) {
            return this.required_tabs.indexOf(tab_name) > -1
        },
        on_resize() {
            this.create_css_props()
            this.calculate_vertical_tabs_width()
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
        window.addEventListener('resize', this.on_resize)
    },
    beforeMount() {
    },
    mounted() {
        this.create_css_props()
        this.calculate_vertical_tabs_width()
    },
    beforeUpdate() {
        if (this.css_props['--tab-height'] === 0) this.create_css_props()
    },
    updated() {
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.on_resize)

    },
    destroyed() {
    },
}
</script>
<style lang="sass" scoped>
.bottom_navigation
    position: absolute

#ada_tabs
    overflow-y: hidden

::v-deep > .v-tabs > .v-tabs-items,
::v-deep > .v-tabs--vertical > .v-tabs-bar .v-tabs-bar__content
    height: var(--tab-height) !important
    max-height: var(--tab-height) !important
    overflow-y: auto


.background
    background-color: var(--v-background-base) !important

</style>
