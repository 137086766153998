<template id="ada_menu_item">
    <div>
        <v-menu offset-y
                :open-on-hover="menu_button"
                v-model="menu_open_computed"
                v-if="'sub_menu' in value">
            <template v-slot:activator="{ on, attrs }">
                <v-hover v-model="menu_button" close-delay="20">
                    <v-btn
                        height="100%"
                        v-bind="attrs"
                        v-on="on"
                        text
                        class="font-weight-light app-object--rounded text-capitalize mr-1"
                        :class="menu_selected(value, $route) ? 'v-btn--active  primary--text'
                                                             :  {'v-btn--active': menu_open_computed}"
                    >
                        {{ translate(value['locale_name']) }}
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </v-hover>
            </template>
            <v-hover v-model="sub_menu_open" close-delay="20">
                <v-list dense style="padding: 0">
                    <div v-for="(item, index) in value.sub_menu" :key="index">
                        <TheHeaderMenuSubItem
                            v-if="item['list_menu']"
                            v-model="sub_sub_menu_open_index"
                            :item="item"
                            :index="index"
                            v-on:index="set_sub_menu_index(index)"
                            v-on:index_list="set_sub_menu(index, $event)"
                        />
                        <v-list-item
                            v-else
                            class="font-weight-regular text-capitalize"
                            :to="item"
                            :class="menu_selected(item, $route) ? 'v-btn--active primary--text' : ''">
                            <v-list-item-title>
                                {{ translate(item['locale_name']) }}
                            </v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list>
            </v-hover>
        </v-menu>
        <v-btn
            v-else
            height="100%"
            :to="value"
            text
            class="font-weight-light app-object--rounded text-capitalize mr-1"
            :class="menu_selected(value, $route) ? 'v-btn--active  primary--text'
                                                 :  {'v-btn--active': menu_open_computed}"
        >
            {{ translate(value['locale_name']) }}
        </v-btn>
    </div>
</template>
<script>
import common_mixin from "@/mixins/common/mixin"
import TheHeaderMenuSubItem from "@/components/the_header/menu/SubItem";

export default {
    name: "TheHeaderMenuItem",
    props: {
        value: Object,
        index: Number,
        current_index: Number
    },
    mixins: [common_mixin],
    components: {
        TheHeaderMenuSubItem
    },
    data() {
        return {
            menu_button: false,
            sub_menu_open: false,
            sub_sub_menu_open: {},
            sub_sub_menu_open_index: null,
            sub_sub_menu_checker: null
        }
    },
    watch: {
        current_index(val) {
            if (val !== this.index) {
                if (this.menu_open_computed) {
                    this.sub_sub_menu_checker = false
                    this.sub_menu_open = false
                    this.menu_button = false
                }
            }
        },
        menu_button(val) {
            if (val) {
                this.$emit("current_index", this.index)
            }
        }

    },
    computed: {
        menu_open_computed: {
            get() {
                // Checks if any hover is active.
                return this.menu_button || this.sub_menu_open || this.sub_sub_menu_checker
            },
            set() {
            }
        },
    },
    methods: {
        set_sub_menu(index, val) {
            this.sub_sub_menu_open[index] = val
            this.sub_sub_menu_checker = this.checker(this.sub_sub_menu_open)
        },
        set_sub_menu_index(index) {
            this.sub_sub_menu_open_index = index
        },

        checker(obj) {
            if (obj.length === 0) {
                return false
            }
            return Object.values(obj).some(v => v === true)
        },

    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>

</style>
