<template id="menu_main">
    <v-row
        no-gutters
        height="100%"
    >
        <TheHeaderMenuItem
            v-for="(menu_item, index) in filtered_navigation_menu"
            :key="index"
            :index="index"
            :current_index="menu_index"
            :value="menu_item"
            v-on:current_index="menu_index = $event"
        />
    </v-row>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import item_mixin from "@/mixins/item/mixin";
import TheHeaderMenuItem from "@/components/the_header/menu/Item";

export default {
    name: "TheHeaderMenu",
    mixins: [item_mixin],
    props: [],
    components: {TheHeaderMenuItem},
    data() {
        return {
            menu_index: null
        }
    },
    computed: {
        ...mapGetters([
            "navigation_menu"
        ]),
        ...mapState([]),
    },
    watch: {
        // ...
    },
    methods: {
        ...mapActions([
            // ...
        ]),

    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
.v-tabs-bar .v-tab--disabled {
    color: #1976d2 !important;
    opacity: 1
}
</style>

