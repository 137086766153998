import {mapActions, mapGetters, mapState} from "vuex";
import local_storage_mixin from "@/mixins/common/local_storage_mixin";
import api_mixin from "@/mixins/common/api_mixin";
import token_mixin from "@/mixins/common/token_mixin";

export default {
    mixins: [api_mixin, local_storage_mixin, token_mixin],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            'is_primary_window': 'window_manager/is_primary_window'
        }),
        ...mapState({
            window_uuid: (state) => state.window_manager.window_uuid
        })
    },
    methods: {
        async window_process() {
            if (!this.window_uuid) {
                this.create_window()
                return
            }

            if (this.is_primary_window) {
                if (this.token_expired(15) && this.user_is_authenticated) {
                    await this.refresh_token()
                }

                this.window_manager_handler({
                    'method': 'run_primary_window'
                })
            } else {
                this.window_manager_handler({
                    'method': 'run_secondary_window'
                })
            }
        },
        create_window() {
            const new_window = {
                'uuid': crypto.randomUUID(),
                'primary': false,
                'tick': new Date().getTime()
            }
            this.window_manager_handler(
                {
                    'method': 'add',
                    'data': new_window
                }
            )
        },
        load_multi_tab_data() {
            const payloads = [
                {
                    'state_property': 'user_token',
                    'data': this.get_local_storage('user_token')
                },
                {
                    'state_property': 'system',
                    'data': this.get_local_storage('system')
                },
                {
                    'state_property': 'system_name',
                    'data': this.get_local_storage('system_name')
                },
                {
                    'state_property': 'is_default_admin_url',
                    'data': this.get_local_storage('is_default_admin_url')
                },
                {
                    'state_property': 'existing_system_details',
                    'data': this.get_local_storage('existing_system_details')
                },
                {
                    'state_property': 'existing_systems',
                    'data': this.get_local_storage('existing_systems')
                },
                {
                    'state_property': 'dialogs',
                    'module': 'dialog_manager',
                    'data': this.get_local_storage('dialogs')
                },
                {
                    'state_property': 'windows',
                    'module': 'window_manager',
                    'data': this.get_local_storage('windows')
                }
            ]
            this.set_state_properties(payloads)
        },
        handle_tab_synchronization({key, newValue, oldValue}) {
            // Add methods if a key requires specific handling
            const methods = {
                'windows': this.handle_generic_update,
                'user_token': this.handle_generic_update,
                'dialogs': this.handle_generic_update
            }

            let method = methods[key]
            if (!method) return

            let new_value = JSON.parse(newValue)
            let old_value = JSON.parse(oldValue)
            method(key, new_value, old_value)
        },
        // eslint-disable-next-line no-unused-vars
        handle_generic_update(key, new_value, old_value) {
            let module = this.get_module(key)
            this.set_state_property(
                {
                    state_property: key,
                    data: new_value,
                    module: module
                }
            )
        },
        get_module(key) {
            if (['dialogs'].includes(key)) return 'dialog_manager'
            if (['windows'].includes(key)) return 'window_manager'

            return null
        },
        ...mapActions({
            window_manager_handler: 'window_manager/window_manager_handler',
            set_state_properties: 'set_state_properties',
            set_state_property: 'set_state_property'
        }),
    },
}
