<template>
    <AppTabCard
        :active="maximized"
        :label="resource_title"
        :text="header_value"
        :width="width"
        @click="maximized ? $emit('minimize') : $emit('maximize')"
        @close="$emit('close')"
    />
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import item_mixin from "@/mixins/item/mixin"
import AppTabCard from "@/components/app_tab_card/Index";

export default {
    name: "ItemDialogManagerMinimizedCard",
    props: {
        value: {
            'type': Object
        },
        maximized: Boolean,
        tile: Boolean,
        width: String
    },
    mixins: [item_mixin],
    components: {AppTabCard},
    data() {
        return {
            internal_value: undefined
        }
    },
    computed: {
        header_value() {
            if (!this.card.header) return ''
            return this.translate(this.get_card_value(this.internal_value.item, this.card.header))
        },
        resource_title() {
            return (this.resource_to_resource_title(this.resource) || '')
                .replaceAll('--', '_').replaceAll('-', '_').toLowerCase()
        },
        resource() {
            return this.internal_value.resource
        },
        schema() {
            return this.internal_value.schema
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style lang="sass" scoped>

</style>
