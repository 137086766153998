export default {
    data() {
        return {}
    },
    methods:{
        set_local_storage(key_name, value) {
            return localStorage.setItem(key_name, JSON.stringify(value))
        },
        get_local_storage(key_name) {
            return JSON.parse(localStorage.getItem(key_name))
        },
        get_sub_item(key_name, sub_key) {
            return this.get_local_storage(key_name)[sub_key]
        },
        patch_local_storage(key_name, sub_key, sub_value) {
            let current_storage = this.get_local_storage(key_name)

            if (!current_storage) return false
            current_storage[sub_key] = sub_value
            return this.set_local_storage(key_name, current_storage)
        },

        remove_local_storage(key_name) {
            return localStorage.removeItem(key_name)
        },
    }
}
