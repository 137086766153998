import {mapActions, mapGetters, mapState} from "vuex";

export default {
    mixins: [],
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            'user_token_json': 'user_token_json',
            'token_type': 'token_type',
            'user_is_authenticated': 'user_is_authenticated'

        }),
        ...mapState({
            'user_token': (state) => state.user_token,
            'user_extended_data': state => state.user_extended_data,
            'signing_out': state => state.signing_out,
            'partner_import_filters': state => state.partner_import_filters,
            'partner_self_registration_data': state => state.partner_self_registration_data,
            swagger: state => state.swagger.local_storage_document,
        }),
    },
    methods: {
        async load_user_data() {
            if (this.token_type !== 'UserToken' || !this.user_is_authenticated) return

            console.info("Fetching user data")
            await this.get_user_extended_data()
            await this.get_user_favorite()
            await this.get_user_settings()

            await this.load_partner_user_data()

            return true
        },
        async load_partner_user_data() {

            if (this.user_extended_data['partner']) {
                if (!this.swagger) return
                if (this.has_get_resource_access('partner--import-filters') && !this.partner_import_filters) {
                    await this.get_partner_filters()
                }
                if (this.has_get_resource_access('partner-self-registration--data') && !this.partner_self_registration_data) {
                    await this.get_partner_self_registration_data()
                }
            }

        },
        async get_partner_filters() {
            await this.api_get({
                url: `/partner--import-filters`,
                commit: true,
                state_property: 'partner_import_filters'
            })
        },
        async get_partner_self_registration_data() {
            await this.api_get(
                {
                    url: '/partner-self-registration--data',
                    commit: true,
                    state_property: 'partner_self_registration_data'
                }
            )
        },
        async get_user_favorite() {
            let result = await this.api_get({
                url: `/user--favorites`,
                params: {
                    where: {
                        user: this.user_token_json['u_id']
                    }
                }
            })
            if (result && result.status === 200) {
                if (result.data._items.length === 0) {
                    await this.create_new_user_favorite()

                } else {
                    this.set_state_property({
                        state_property: 'user_favorite',
                        data: result.data._items[0]
                    })
                }

            }
        },
        async create_new_user_favorite() {
            let new_user_favorite = {
                "user": this.user_token_json['u_id'],
                'favorites': {}
            }

            for (let property of ['account', 'partner'].filter(x => !!this.user_extended_data[x])) {
                new_user_favorite[property] = this.user_extended_data[property]
            }

            await this.api_post({
                url: '/user--favorites',
                data: new_user_favorite
            })
            await this.get_user_favorite()
        },
        async get_user_settings() {
            let result = await this.api_get({
                url: `/user--settings`,
                params: {
                    where: {
                        user: this.user_token_json['u_id']
                    }
                }
            })
            if (result && result.status === 200) {
                if (result.data._items.length === 0) {
                    await this.create_new_user_setting()
                } else {
                    this.set_state_property({
                        state_property: 'user_settings',
                        data: result.data._items[0]
                    })
                }
            }
        },
        async create_new_user_setting() {
            let new_user_settings = {
                "user": this.user_token_json['u_id'],
                'settings': {
                    'ui': {
                        'theme': 'light',
                        'language': 'sv'
                    },
                    'item_component': {}
                }
            }

            for (let property of ['account', 'partner'].filter(x => !!this.user_extended_data[x])) {
                new_user_settings[property] = this.user_extended_data[property]
            }

            await this.api_post({
                url: '/user--settings',
                data: new_user_settings
            })
            await this.get_user_settings()
        },
        async get_cases() {
            await this.api_get({
                url: '/cases',
                params: undefined,
                commit: true,
                state_property: 'cases'
            })
            return true
        },
        async get_user_extended_data() {
            await this.api_get(
                {
                    url: '/user--extended-data',
                    params: undefined,
                    commit: true,
                    state_property: 'user_extended_data'
                }
            )
        },
        async user_activity(name, attribute, value, domain_action) {
            if (!this.user_token_json) return
            if (this.token_type !== 'UserToken') return
            let today = new Date().toUTCString();
            let data = {
                name: name,
                timestamp: today,
            }
            data[attribute] = value

            if (domain_action) data["domain_action"] = domain_action

            await this.api_post(
                {
                    url: '/application--user-activities',
                    data: data
                },
            )
        },
        async sign_out(reason=null) {
            if (this.signing_out || !this.user_token) return

            try {
                this.set_state_property(
                    {
                        state_property: 'singing_out',
                        data: true
                    }
                )
                reason = (reason || '').toLowerCase()
                if (!this.token_expired() && reason !== 'unauthorized' && reason !== 'token_expired') {
                    console.log("user signed out manually", this.token_expired())
                    await this.user_activity('top_bar', 'portal', 'logout')
                    this.go_to('Home')
                }

            } catch (e) {
                console.error(e)
            } finally {
                console.log("<<<SIGN OUT>>>")
                const locale_storage_keys = ['user_token', 'dialogs']
                locale_storage_keys.forEach(x => this.remove_local_storage(x))
                this.set_state_properties([
                    {
                        state_property: 'user_token',
                        data: null
                    },
                    {
                        state_property: 'site_refreshes',
                        data: 0
                    },
                    {
                        state_property: 'dialogs',
                        data: [],
                        module: 'dialog_manager'
                    },
                    {
                        state_property: 'current',
                        data: null,
                        module: 'dialog_manager'
                    },
                    {
                        state_property: 'local_storage_document',
                        data: null,
                        module: 'swagger'
                    },
                    {
                        state_property: 'refresh_token_signal',
                        data: false
                    },
                    {
                        state_property: 'loading_application_details',
                        data: false
                    },
                    {
                        state_property: 'signing_out',
                        data: false
                    },
                ])
            }
            // this.reset_cache()
        },
        ...mapActions([
            'set_state_property',
            'set_state_properties'
        ]),
    },
}


