<template>
    <div>
        <span class="user-authentication__bg"/>
        <v-alert
            v-if="error_message!==''"
            prominent
            type="error"
        >
            <v-row align="center">
                <v-col class="text-center grow">
                    {{ error_message }}
                </v-col>
                <v-col class="shrink">
                    <v-btn
                        @click="error_message=''"
                    >
                        {{ $t("common.ok") }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-row no-gutters align="center" justify="center">

            <v-col
                cols="12" xs="12" sm="6" md="6" lg="4" xl="4"
                class="pa-2"
            >

                <v-card
                    min-height="325px"
                    class="mx-auto"
                    :style="'margin-top: ' + ($vuetify.breakpoint.xsOnly ? '10px' : '80px') + ';margin-bottom: 150px'"
                    elevation="12"
                >
                    <v-card-title
                        class="secondary"
                    >
                        <v-spacer/>
                        <span style="color: whitesmoke">{{ system_name }}</span>
                        <v-spacer/>
                    </v-card-title>

                    <v-card
                        flat
                        class="mb-0"
                    >
                        <v-row
                            justify="center"
                            no-gutters
                        >
                            <v-col cols="8">
                                <SelectLanguage width="50%" class="ma-0"/>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card
                        flat
                        class="mt-0"
                    >
                        <v-row
                            justify="center"
                            class="mb-2 mt-0"
                            no-gutters
                        >
                            <v-col cols="10" class="mt-0">
                                <OAuth
                                    @sign_in_oauth="sign_in_oauth($event)"
                                    @error="error_message = $event"
                                />
                            </v-col>
                        </v-row>

                        <v-row
                            align="center"
                            class="px-4 mt-0"
                            no-gutters
                        >
                            <v-divider/>
                            <v-card-subtitle class="pa-0 px-2">
                                {{ $t('common.or') }}

                            </v-card-subtitle>
                            <v-divider/>
                        </v-row>


                        <v-tabs
                            v-model="selected_tab"
                            class="mt-4"
                            centered
                            icons-and-text
                        >
                            <v-tabs-slider></v-tabs-slider>
                            <v-tab
                                v-for="(item, i) in tab_authenticators"
                                :key="'tab-' + i"
                                :href="'#tab-' + i"
                            >
                                {{ $t(locale_key(item.title)) }}
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-tab>

                        </v-tabs>

                        <v-tabs-items v-model="selected_tab">
                            <v-tab-item
                                v-for="(item, i) in tab_authenticators"
                                :key="'tab-content-' + i"
                                :value="'tab-' + i"
                            >
                                <v-row
                                    justify="center"
                                    class="mb-2"
                                    no-gutters
                                >

                                    <v-col cols="10">
                                        <v-card flat class="mt-4">
                                            <component
                                                :is="item.component"
                                            />
                                        </v-card>
                                    </v-col>
                                </v-row>

                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters align="center" justify="center">
            <v-btn
                @click="display_terms=true"
            >
                Privacy Policy
            </v-btn>
        </v-row>
        <v-row
            v-if="is_default_admin_url"
            no-gutters
            align="center"
            justify="center"
            class="mt-10"
        >
            <v-btn
                @click="$router.push('/select-system')"
                x-small
            >
                Other System
            </v-btn>
        </v-row>
        <AppDialog
            v-model="display_terms"
            title="Privacy Policy"
        >
            <template v-slot:default>
                <v-card
                    height="75vh"
                >
                    <iframe
                        width="100%"
                        :height="$vuetify.breakpoint.xsOnly ? '93%' : '100%'"
                        src="/privacy-policy.html"
                    />
                </v-card>
            </template>
        </AppDialog>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import common_mixin from "@/mixins/common/mixin";
import SelectLanguage from "@/components/select_language/Index"

export default {
    name: "TheAuthentication",
    mixins: [common_mixin],
    props: [],
    components: {
        SelectLanguage,
        OAuth: () => import(/**/ './oauth/Index'),
        MobileBankId: () => import(/**/ './mobile_bankid/Index'),
        Email: () => import(/**/ './email/Index'),
        Sms: () => import(/**/ './sms/Index'),
        AppDialog: () => import(/**/ '../app_dialog/Index'),
    },
    data() {
        return {
            display_terms: false,
            error_message: "",
            selected_tab: null,
            tab_authenticators: [
                {
                    "title": "common.email",
                    "icon": "mdi-email-lock",
                    "component": "Email",
                },
                {
                    "title": "common.sms",
                    "icon": "mdi-message-lock",
                    "component": "Sms",
                },
                // {
                //     "title": "common.mobile_bankid",
                //     "icon": "mdi-bank",
                //     "component": "MobileBankId",
                // },
            ],
            display_user_dialog: false,
        }
    },
    computed: {
        ...mapGetters([
            'user_token_json',
            'user_has_selected_token'
        ]),
        ...mapState(['redirect_url_on_login_success', 'system', 'system_name', 'is_default_admin_url']),
    },
    watch: {
        user_has_selected_token() {
            this.display_user_dialog = !this.user_has_selected_token
        }
    },
    methods: {

        sign_in_oauth(oauth_authenticator) {
            let current_url = window.location.href
            this.set_state_property({
                state_property: 'redirect_url_on_login_success',
                data: this.remove_url_query_parameter('oauth_authenticator', current_url)
            })

            let url =
                process.env.VUE_APP_API_ROOT +
                "/authenticates/oauth2" +
                "/" +
                oauth_authenticator +
                "/" +
                this.system +
                "/" +
                process.env.VUE_APP_NAME;

            window.location.replace(url);
        },
        ...mapActions([
            "set_state_property",
        ]),
    },
    beforeCreate() {
    },
    created() {
        let token_from_local = this.get_local_storage("user_token")
        if (token_from_local) {
            return this.set_token(token_from_local)
        }

        let token_from_url = this.get_token_from_url()
        if (token_from_url) {
            this.set_token(token_from_url)
            this.user_activity('Authentication', 'portal', 'login')
            if (this.redirect_url_on_login_success) {
                if (window.location.href !== this.redirect_url_on_login_success) {
                    window.location.replace(this.redirect_url_on_login_success)
                }
                this.set_state_property(
                    {
                        state_property: 'redirect_url_on_login_success',
                        data: undefined
                    }
                )
            }
            return
        }

        let oauth_authenticator_from_url = this.get_url_query_parameter("oauth_authenticator")
        if (oauth_authenticator_from_url) {
            this.sign_in_oauth(oauth_authenticator_from_url)
        }

    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },

}
</script>

<style scoped>
</style>
