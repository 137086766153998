<template>
    <v-card
        v-if="internal_value"
        color="transparent"
        flat
        tile
    >
        <v-card-title
            v-if="title"
            v-text="translate(title)"
            :class="highlight_title ? 'app-text--highlighted pb-2 px-0' : 'pb-2 px-0'"
        />
        <v-card>
            <v-card-actions>
                <v-row no-gutters>
                    <v-btn
                        @click="expanded = !expanded"
                        class="ma-2"
                        icon
                    >
                        <v-icon>
                            {{
                                expanded ? 'mdi-chevron-down' : 'mdi-chevron-right'
                            }}
                        </v-icon>
                    </v-btn>

                    <JsonViewer
                        v-model="internal_value"
                        :expand-depth="0"
                        :key="expanded"
                        :preview-mode="expanded"
                        class="app-object--click-disabled my-4"
                    />

                </v-row>
            </v-card-actions>

        </v-card>

    </v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import JsonViewer from 'vue-json-viewer'
import locale_mixin from "@/mixins/common/locale_mixin";

export default {
    name: "AppJsonViewer",
    props: {
        value: {
            'type': [String, Object, Number, Boolean, Array]
        },
        item: Object,
        title: String,
        highlight_title: Boolean
    },
    mixins: [locale_mixin],
    components: {JsonViewer},
    data() {
        return {
            internal_value: undefined,
            expanded: false,
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        },
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style lang="sass" scoped>
::v-deep .jv-code
    padding: 0 !important

::v-deep .jv-toggle
    display: none !important
</style>
