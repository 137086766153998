var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"app_autocomplete",attrs:{"items":_vm.items,"item-value":_vm.item_value,"item-text":_vm.computed_item_text,"search-input":_vm.search_text,"label":_vm.translate(_vm.label),"loading":_vm.is_loading,"disabled":_vm.disabled,"clearable":_vm.clearable,"return-object":_vm.return_object,"readonly":_vm.readonly,"filled":_vm.readonly,"placeholder":_vm.translate(_vm.placeholder),"prepend-inner-icon":_vm.prependInnerIcon,"rules":_vm.rules,"error-messages":_vm.translate(_vm.error_message),"type":"text","aria-autocomplete":"off","autocomplete":"off","hide-details":_vm.hide_details,"autofocus":_vm.autofocus,"no-filter":"","dense":"","outlined":""},on:{"update:searchInput":function($event){_vm.search_text=$event},"update:search-input":function($event){_vm.search_text=$event},"change":_vm.on_internal_value_change,"focus":function($event){_vm.internal_width = _vm.$refs.app_autocomplete.$el.clientWidth}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"textContent":_vm._s(_vm.deep_get(item, _vm.computed_item_text) || '-')}})]}},{key:"append",fn:function(){return [(_vm.loading_next)?_c('v-progress-circular',{staticClass:"app-object--absolute-center",attrs:{"color":"primary","indeterminate":""}}):(_vm.show_resource_information_icon)?_c('v-tooltip',{staticClass:"ma-0 pa-0",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('div',{staticClass:"ma-2"},_vm._l((_vm.resource_body_attributes.filter(function (k) { return !!_vm.deep_get(_vm.selected_item, k); })),function(key,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm.locale_key(key, ['item'], true))+": "+_vm._s(_vm.translate_unknown_value(_vm.deep_get(_vm.selected_item, key), ['item']))+" ")])}),0)]):_vm._e()]},proxy:true},(_vm.search_card)?{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g({style:(("max-width: " + _vm.internal_width + "px")),attrs:{"disabled":_vm.is_disabled(item)}},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.format_dict_value(_vm.deep_get(item, _vm.search_card.title.value), _vm.search_card.title))+" ")]),_vm._l((_vm.search_card.body),function(sub_title){return _c('v-list-item-subtitle',{key:sub_title.label},[(!sub_title.hide_key)?_c('span',[_vm._v(" "+_vm._s(_vm.translate(sub_title.label))+": "+_vm._s(_vm.format_dict_value(_vm.deep_get(item, sub_title.value), sub_title))+" ")]):_vm._e()])}),(_vm.is_disabled(item) && _vm.disabled_text)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.translate(_vm.disabled_text) || _vm.$t('common.disabled'))+" ")]):_vm._e()],2)],1)]}}:{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item',_vm._g({class:{'app-object--highlighted': _vm.selected_item === item},style:(("max-width: " + _vm.internal_width + "px")),attrs:{"disabled":_vm.is_disabled(item)}},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.deep_get(item, _vm.computed_item_text) || '-')+" ")]),_vm._l((_vm.resource_body_attributes.filter(function (x) { return !!_vm.deep_get(item, x); })),function(key){return _c('v-list-item-subtitle',{key:key,staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.locale_key(key, ['item'], true))+": "+_vm._s(_vm.translate_unknown_value(_vm.deep_get(item, key), ['item']))+" ")])})],2),_c('v-list-item-action',[(_vm.is_disabled(item) && _vm.disabled_text)?_c('v-list-item-action-text',[_vm._v(" "+_vm._s(_vm.translate(_vm.disabled_text) || _vm.$t('common.disabled'))+" ")]):_vm._e()],1)],1)]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.get_next_items),expression:"get_next_items"}]})]},proxy:true}],null,true),model:{value:(_vm.internal_value),callback:function ($$v) {_vm.internal_value=$$v},expression:"internal_value"}})}
var staticRenderFns = []

export { render, staticRenderFns }