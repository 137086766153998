<template>
    <AppDialog
        :value="display"
        @input="$event === false ? $emit('close'): undefined"
        :fullscreen="fullscreen"
        @click_outside="create_new ? $emit('close') : $emit('minimize')"
        @click_escape="$emit('close')"
        @updated_content_height="recalculate_ada_tab_height_key += 1"
        :height_calculation_key="`${fullscreen}_${display_toolbar}`"
        height="90vh"
        persistent
        disable_header
        disable_scrolling
    >
        <template #default>
            <v-progress-circular
                id="dialog_spinner"
                v-if="show_spinner"
                color="primary"
                class="app-object--absolute-center app-layout--level-10"
                indeterminate
            />
            <component
                v-if="custom_component"
                :is="custom_component"
                v-model="internal_value"
                :fullscreen="fullscreen"
                @close="$emit('close')"
            />
            <AppTabs
                v-else
                id="app_tabs"
                v-model="tab_index"
                :title="toolbar_title || '-'"
                :label="toolbar_label"
                :tab_names="tab_names"
                :required_tabs="required_tabs"
                :icon_by_tab_name="icon_by_tab_name"
                :locale_locations="[resource_title.toLowerCase(), 'item']"
                :top="!small_screen"
                :bottom="small_screen"
                :disabled="show_spinner"
                :hide_tab_navigation="display_as_json || tab_names.length === 1"
                :recalculate_height_key="recalculate_ada_tab_height_key.toString()"
                :tabs_with_errors="tabs_with_errors"
                class="app-tabs--content_application-background"
                height="100%"
                double_toolbar_height_on_sm_and_down
                vertical
            >
                <template
                    #left_toolbar_actions
                >

                    <v-chip
                        v-if="is_editing"
                        color="info"
                        small
                    >
                        {{ $t('common.editing') }}
                    </v-chip>
                    <v-chip
                        v-if="create_new"
                        color="info"
                        small
                    >
                        {{ $t('common.creating_new') }}
                    </v-chip>

                </template>

                <template
                    v-slot:right_toolbar_actions
                    v-if="!disable_item_actions"
                >
                    <ItemButtons
                        v-model="internal_value"
                        :resource="resource"
                        :is_favorite="is_favorite"
                        :is_editing="is_editing || create_new"
                        :create_new="create_new"
                        :embedded_object="embedded_object"
                        :fullscreen="fullscreen"
                        :display_action_menu="display_action_menu"
                        @display_action_menu="$emit('display_action_menu', $event)"
                        @display_snackbar="$emit('display_snackbar', $event)"
                        @toggle_fullscreen="toggle_fullscreen($event)"
                        @delete_item="delete_item"
                        @copy_item="internal_value = $event"
                        @remove="$emit('remove')"
                        @edit="toggle_edit"
                        @close="$emit('close')"
                        @minimize="$emit('minimize')"
                        @open="$emit('open')"
                        location="dialog"
                    />
                </template>

                <template
                    v-slot:tab-items
                    v-if="!display_as_json && (complete_item_loaded || create_new || disable_load_complete_item)"
                >
                    <v-tab-item
                        v-for="(tab_name, index) in tab_names"
                        :key="index"
                    >
                        <Tab
                            v-if="tab_index === index && Object.keys(properties_by_tab).includes(tab_name)"
                            v-model="internal_value"
                            :after_procedures.sync="after_procedures"
                            :resource="resource"
                            :properties="properties_by_tab[tab_name]"
                            :schema="dialog_schema"
                            :is_editing="is_editing"
                            :currency="currency"
                            :create_new="create_new"
                            :dotted_path="tab_name !== 'details' ? tab_name : null"
                            :custom_ui_structure="custom_ui_structure"
                            :disable_property_access_check="disable_property_access_check"
                            :group_unspecified_properties="group_unspecified_properties"
                            :payload_errors="payload_errors"
                            @inner_object_click="open_zoom_dialog($event)"
                            @toggle_edit="$emit('toggle_edit')"
                            class="pa-2"
                        />
                        <component
                            v-else-if="tab_index === index"
                            v-model="internal_value"
                            :is="custom_tab_component(tab_name)"
                            :dependency_resource="dependency_link_resource(tab_name)"
                            :resource="resource"
                            :schema="dialog_schema"
                            :is_editing="is_editing"
                            :create_new="create_new"
                            class="pa-0 mr-2 ml-2 mb-1"
                        />
                    </v-tab-item>
                </template>
                <template
                    v-slot:tab-items
                    v-else-if="complete_item_loaded"
                >
                    <v-tab-item>
                        <AppJsonEditor
                            v-model="internal_value"
                            :height="$vuetify.breakpoint.xsOnly ? `calc(90vh - ${footer_header_bar_height}px)`: '74vh'"
                            :value="value"
                            :editing="is_editing || create_new"
                        />
                    </v-tab-item>

                </template>
                <template v-slot:footer>
                    <v-toolbar
                        dense
                        :key="display_toolbar"
                        :height="display_toolbar ? '48px': '0px'"
                        class="pa-0"
                    >
                        <DependenciesLinks
                            v-if="!disable_dependency_links && !create_new"
                            :item="internal_value"
                            :resource="resource"
                            @number_of_dependency_links="number_of_dependency_links = $event"
                            @minimize="$emit('minimize')"
                        />
                        <v-spacer/>
                        <v-card-actions
                            v-if="create_new || is_editing"
                        >
                            <slot
                                name="footer_buttons"
                                :item="internal_value"
                            >
                                <!-- Default goes inside! -->
                                <v-container v-if="!create_new && is_editing">
                                    <v-btn
                                        @click="cancel_edit"
                                        :small="small_screen"
                                    >
                                        {{ $t('common.cancel') }}
                                    </v-btn>

                                    <v-btn
                                        @click="save_item"
                                        :small="small_screen"
                                        color="primary"
                                        class="ml-2"
                                        :disabled="is_editing && !allow_edit"
                                    >
                                        {{ $t('common.save') }}
                                    </v-btn>
                                </v-container>
                                <v-container v-else-if="create_new">
                                    <v-btn
                                        @click="$emit('close')"
                                        :small="small_screen"
                                    >
                                        {{ $t('common.abort') }}
                                    </v-btn>
                                    <v-btn
                                        @click="post_item"
                                        color="primary"
                                        class="ml-2"
                                        :small="small_screen"
                                    >
                                        {{ $t('common.create') }}
                                    </v-btn>
                                </v-container>
                            </slot>
                        </v-card-actions>
                    </v-toolbar>
                    <Snackbar
                        v-model="snackbar.display"
                        :text="snackbar.text"
                        :color="snackbar.color"
                        :timeout="snackbar.timeout"
                        :json="snackbar.json"
                    />
                </template>

            </AppTabs>

            <ZoomDialog
                v-model="internal_value"
                :after_procedures.sync="after_procedures"
                :resource="resource"
                :item_schema="dialog_schema"
                :is_editing="is_editing"
                :create_new="create_new"
                :currency="currency"
                :custom_ui_structure="custom_ui_structure"
                :disable_property_access_check="disable_property_access_check"
                :group_unspecified_properties="group_unspecified_properties"
                :init_dotted_path="zoom_dialog_init_dotted_path"
                :key="zoom_dialog_init_dotted_path"
                :payload_errors="payload_errors"
                @close="zoom_dialog_init_dotted_path = null"
            />
        </template>
    </AppDialog>
</template>


<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import Tab from "./tab/Index";
import item_mixin from "@/mixins/item/mixin"
import item_dialog_mixin from "@/mixins/item/dialog_mixin"
import ItemButtons from "@/components/item_buttons/Index"
import DependenciesLinks from "@/components/item_dialog/dependencies/Index"
import AppTabs from "@/components/app_tabs/Index"
import Snackbar from "@/components/snackbar/Index"
import AppDialog from "@/components/app_dialog/Index";

// Custom Tab components need to be imported here
import ZoomDialog from "@/components/item_dialog/zoom_dialog/Index";
import AppJsonViewer from "@/components/app_json_viewer/Index";
import AppJsonEditor from "@/components/app_json_editor/Index";

export default {
    name: "ItemDialog",
    mixins: [item_mixin, item_dialog_mixin],
    props: {
        'value': {
            type: Object
        },
        'resource': {
            type: String
        },
        'display': {
            type: Boolean
        },
        init_editing: Boolean,
        'is_favorite': {
            type: Boolean
        },
        'create_new': {
            type: Boolean
        },
        disable_item_actions: Boolean,
        disable_load_complete_item: Boolean,
        disable_dependency_links: Boolean,
        disable_property_access_check: Boolean,
        'currency': {
            type: String
        },
        'custom_ui_structure': {
            type: Object // used in item mixin
        },
        'custom_toolbar_title': {
            type: String
        },
        'group_unspecified_properties': {
            type: Boolean,
            default: true,
        },
        'display_action_menu': {
            type: Boolean
        },
        custom_schema: Object,
        'embedded_object': {
            type: Boolean
        },
        hide_oplog: Boolean,
        flow_action_errors: {
            type: Object,
            default: () => {
            }
        },
    },
    components: {
        AppJsonEditor,
        AppJsonViewer,
        ZoomDialog,
        AppDialog, Tab, ItemButtons, DependenciesLinks, AppTabs, Snackbar,

        /*Custom dialog components*/
        FinanceDailySettlement: () => import(/*webpackChunkName: "FinanceDailySettlement"*/ '@/components/item_dialog/custom_dialog_components/finance_daily_settlement/Index'),

        /*Custom tab components*/
        DependencyLinkTab: () => import(/*webpackChunkName: "DependencyLinkTab"*/ './custom_tab_components/dependency_link_tab/Index'),
        CaseHistory: () => import(/*webpackChunkName: "CaseHistory"*/ './custom_tab_components/case_history/Index'),
        Prints: () => import(/*webpackChunkName: "Prints"*/ './custom_tab_components/prints/Index'),
        Oplog: () => import(/*webpackChunkName: "OpLog"*/ './custom_tab_components/oplog/Index'),
    },
    data() {
        return {
            lazy_value: undefined,
            tab_index: 0,
            original_item: undefined,
            error: undefined,
            error_message: undefined,
            show_spinner: false,
            snackbar: {
                display: false,
                text: '',
                color: '',
                json: undefined,
                timeout: 10000
            },
            fullscreen: false,
            is_editing: false,
            number_of_dependency_links: 0,
            zoom_dialog_init_dotted_path: undefined,
            after_procedures: [
                /*
                    {
                        origin: 'files.0.file',
                        type: 'api_post / api_get / etc.',
                        payload: {payload_data},
                        url: endpoint_url,
                        reload_item: true, // reloads item after procedures have run
                    }
                */
            ],
            complete_item_loaded: false,
            recalculate_ada_tab_height_key: 0,
            payload_errors: {},
            tabs_with_errors: []
        }
    },
    computed: {
        display_toolbar() {
            return this.create_new || this.is_editing || this.number_of_dependency_links > 0
        },
        dialog_schema() {
            console.log("==== GENERATE DIALOG SCHEMA ====")

            if (this.custom_schema) return this.custom_schema
            if (!this.internal_value) return {}
            return this.create_item_schema(this.internal_value)
        },
        custom_component() {
            if (!this.dialog_schema || !this.dialog_schema.description) return undefined
            let components = Object.keys(this.$options.components)
            let regex = '#item_component:[A-Za-z]*'
            let match = this.dialog_schema.description.match(regex)
            if (!match) return ''
            let custom_component_tag = match[0]
            let custom_component_name = custom_component_tag.split(':').pop().toLowerCase()

            let found_index = components.map(x => x.toLowerCase()).indexOf(custom_component_name)
            if (found_index > -1) {
                return components[found_index]
            }

            return undefined
        },
        custom_tabs() {
            let custom_tabs = this.item_ui_structure?.['dialog']?.['custom_tabs'] || []
            let dependency_link_tabs = this.item_ui_structure?.['dependency_links']
            let tabs = []
            let input_data = {
                value: this.lazy_value,
                resource: this.resource,
            }
            for (let custom_tab of custom_tabs) {
                let component = this.$options.components[custom_tab["custom_component"]]
                if (!component) continue
                let filter_function = component.methods && component.methods["__is_mountable"]

                if (filter_function) {
                    let custom_tab_is_mountable = filter_function(this, input_data)
                    if (custom_tab_is_mountable) {
                        tabs.push(custom_tab)
                    }
                }
            }

            if (dependency_link_tabs) {
                for (let dependency_resource of Object.keys(dependency_link_tabs)) {
                    if (dependency_link_tabs[dependency_resource]['display_in_tabs'] /*&& this.has_get_resource_access(dependency_resource)*/) {
                        tabs.push({
                            'title': this.ui_structure[dependency_resource]["search_header"],
                            'icon': this.get_resource_icon(dependency_resource),
                            'custom_component': 'DependencyLinkTab',
                            'dependency_resource': dependency_resource
                        })
                    }
                }
            }

            if (!this.hide_oplog && !this.create_new && this.has_get_resource_access('oplog')) {
                tabs.push(
                    {
                        'title': 'common.oplog',
                        'icon': 'mdi-history',
                        'custom_component': 'Oplog',
                    }
                )
            }
            return tabs
        }
        ,
        tab_names() {
            if (this.display_as_json) return ['details']

            return [
                ...Object.keys(this.properties_by_tab).filter(k => {
                    let is_excluded = this.deep_get(
                        this.dialog_schema,
                        `properties.${k}.excluded`,
                        false
                    )
                    if (is_excluded) return false
                    if (this.create_new || this.is_editing) return true

                    for (let p of this.properties_by_tab[k]) {
                        if (this.internal_value[p.name]) {
                            return true
                        }
                    }
                    return false
                }),
                ...this.custom_tabs.map((x) => x['title'])
            ]
        }
        ,
        allow_load_complete_item() {
            return this.display && !this.disable_load_complete_item && !this.create_new
        },
        toolbar_label() {
            if (!this.resource_title) return ''
            let resource_title = this.resource_title.replaceAll('--', '_').replaceAll('-', '_').toLowerCase()

            let resource_title_locale = this.locale_key(
                resource_title,
                [this.resource_translate_location, 'item']
            )
            return this.translate(resource_title_locale)
        },
        toolbar_title() {
            if (this.custom_toolbar_title) return this.custom_toolbar_title
            return this.translate(this.get_card_value(this.internal_value, this.card.header))
        },
        display_as_json() {
            if (!this.resource_schema || !this.resource_schema['description']) return false
            return this.resource_schema['description'].indexOf('#item_component:json') > -1
        },
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.$emit('input', this.lazy_value)
            },
        },
        footer_header_bar_height() {
            let header_height = 56
            let footer_height = 48
            let bottom_navigation_height = 42

            return header_height + footer_height + bottom_navigation_height
        },
        allow_put() {
            if (this.user_token_json.system_user) {
                let swagger_paths = this.swagger_paths(this.resource)
                return 'put' in swagger_paths['item_methods']
            }
            let has_access_to_put = this.access_right ? this.access_right.methods.indexOf('PUT') > -1 : false

            if (!has_access_to_put) return false

            let attribute_access = this.access_right.attribute_access
            if (!attribute_access) return false

            let resource_params = Object.keys(this.resource_schema.properties).filter(x => !x.startsWith('_'))
            for (let param in resource_params) {
                if (!attribute_access[param] || attribute_access[param] !== 'write') {
                    return false
                }
            }
            return true
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            let update_from_parent = JSON.stringify(val) !== JSON.stringify(this.internal_value)
            if (update_from_parent && this.allow_load_complete_item) this.load_complete_item()
            this.lazy_value = val
        },
        async display(val) {
            if (val) {
                await this.load_complete_item()
            }
        },
        flow_action_errors: {
            handler(val) {
                this.payload_errors = val
            },
            immediate: true
        },
        tab_names(new_val, old_val) {
            if (new_val && old_val && old_val.length !== new_val && !this.is_editing && !this.create_new) {
                this.$nextTick(() => {
                    this.tab_index = 0
                })
            }
        },
    },
    methods: {
        custom_tab_component(tab_name) {
            return this.custom_tabs.filter(x => x.title === tab_name)[0].custom_component
        }
        ,
        dependency_link_resource(tab_name) {
            return this.custom_tabs.filter(x => x.title === tab_name)[0].dependency_resource
        }
        ,
        toggle_edit() {
            this.is_editing = !this.is_editing
            this.user_activity('item', 'domain', this.resource, 'edit')
        }
        ,
        update_internal_value(key, value) {
            this.$set(this.internal_value, key, value)
        }
        ,
        remove_and_close() {
            this.$emit('remove')
            this.$emit('close')
        },
        cancel_edit() {
            this.reset_item()
            this.toggle_edit()
            this.payload_errors = {}
            this.after_procedures = []
        },
        async run_procedure(procedures) {
            let reload_item = false
            for (let procedure of procedures) {
                if (procedure.url instanceof Function) {
                    procedure.url = procedure.url(this.internal_value)
                }
                await this.api_call_wrapper(
                    async () => {
                        await this[procedure.type]({
                            url: procedure.url,
                            data: procedure.payload
                        })
                    },
                    procedure.error_callback
                )

                if (procedure.reload_item) {
                    reload_item = true
                }
            }

            if (reload_item) {
                await this.load_complete_item()
                this.set_state_property(
                    {
                        module: 'dialog_manager',
                        state_property: 'signal_update_item_container',
                        data: true
                    }
                )
            }

        },
        async load_complete_item() {
            if (!this.allow_load_complete_item) return
            this.show_spinner = true

            let item = await this.api_get({
                url: `/${this.resource}/${this.internal_value['_id']}`
            })

            console.log("COMPLETE ITEM", item.data)
            this.internal_value = item.data
            this.original_item = JSON.parse(JSON.stringify(item.data))
            this.show_spinner = false
            if (!this.complete_item_loaded) this.complete_item_loaded = true

        },
        async save_item() {
            this.show_spinner = true

            let method = this.allow_put ? 'PUT' : 'PATCH'

            let data = this.payload_data(this.internal_value, method)

            if (Object.keys(this.payload_errors).length) {
                return this.generic_dialog_error('common.validation_error')
            }

            let payload = {
                url: `/${this.resource}/${this.internal_value._id}`,
                data,
                if_match: this.internal_value._etag
            }
            let api_patch_or_put = this.allow_put ? this.api_put : this.api_patch

            try {
                let result = await api_patch_or_put(payload)
                this.handle_result(result, 'save')
                await this.run_procedure(this.after_procedures)
                this.cancel_edit()
            } catch (error) {
                this.handle_dialog_error(error)
            } finally {
                this.snackbar.display = true
                this.show_spinner = false
            }

        },
        async post_item() {
            this.show_spinner = true

            let data = this.payload_data(this.internal_value, 'POST')

            if (Object.keys(this.payload_errors).length) {
                return this.generic_dialog_error('common.validation_error')
            }

            let payload = {
                url: '/' + this.resource,
                data
            }
            try {
                let result = await this.api_post(payload)
                this.handle_result(result, 'create')

                await this.run_procedure(this.after_procedures)

                this.open_dialog(
                    {
                        item: this.internal_value,
                        resource: this.resource,
                    }
                )
            } catch (error) {
                this.handle_dialog_error(error)
            } finally {
                this.snackbar.display = true
                this.show_spinner = false
            }
        },
        async delete_item() {
            this.show_spinner = true

            let payload = {
                url: `/${this.resource}/${this.internal_value['_id']}`,
                if_match: this.internal_value['_etag']
            }
            try {
                await this.api_delete(payload)
                this.snackbar.text = 'common.deleted'
                this.snackbar.color = 'success'
                this.snackbar.json = undefined
                setTimeout(() => {
                    this.set_state_property(
                        {
                            module: 'dialog_manager',
                            state_property: 'signal_update_item_container',
                            data: true
                        }
                    )
                    this.$emit('close')
                }, 500)

            } catch (error) {
                this.handle_dialog_error(error)

            } finally {
                this.snackbar.display = true
                this.show_spinner = false
            }
        },
        payload_data(item, method) {
            let data = {}
            Object.keys(item).forEach(x => {
                if (!x.startsWith('_')) {
                    data[x] = item[x]
                }
            })
            this.payload_errors = {}
            this.tabs_with_errors = []
            return this.format_for_db_transaction(data, this.original_item, this.dialog_schema['properties'], true, method)
        },
        format_for_db_transaction(item, original_item, properties, copy, method, dotted_path = '') {
            let item_copy = copy ? JSON.parse(JSON.stringify(item)) : item
            for (let key of Object.keys(item_copy)) {
                let value = item_copy[key]
                let original_value = original_item?.[key]
                let property = properties[key]

                let value_dotted_path = dotted_path ? `${dotted_path}.${key}` : key
                if (!this.item_ui_structure['skip_ui_validation']) {
                    let value_issue = this.validate(value, property, original_value)
                    if (value_issue !== true && !property.excluded) {
                        this.$set(this.payload_errors, value_dotted_path, value_issue)
                    }
                }

                let delete_key_from_item = (
                    !property ||
                    property['readOnly'] ||
                    property.access_right !== 'write' ||
                    (property.description?.includes('#item_component:MediaFile') && !value?.id) ||
                    (value === null && property.description?.includes('#item_component:json') && property.type === 'string') ||
                    (value === null && (method === 'POST' || [null, undefined].includes(original_value))) ||
                    (value === null && property.type === 'object' && method === 'PUT')
                )

                if (delete_key_from_item) {
                    delete item_copy[key]
                } else if (value === null && property.type === 'string' && !property.nullable && !property.enum) {
                    item_copy[key] = ''
                } else if (property.enum) {
                    this.format_enum(value, property, item_copy, key)
                } else if (property.description?.includes('#item_component:HtmlArea') && ['PATCH', 'PUT'].includes(method)) {
                    item_copy[key] = this.format_html_area_for_patch_put(value, item_copy, key)
                } else if (value && property.type === 'array' && property?.items?.type === 'object') {
                    for (let i = 0; i < value.length; i++) {
                        let array_value_dotted_path = `${value_dotted_path}.${i}`
                        item_copy[key][i] = this.format_for_db_transaction(value[i], original_value?.[i], property['items']['properties'], false, method, array_value_dotted_path)
                    }
                } else if (value && ('properties' in property || property.type === 'object')) {
                    item_copy[key] = this.format_for_db_transaction(value, original_value, property['properties'], false, method, value_dotted_path)
                }
            }

            return item_copy
        },
        format_html_area_for_patch_put(value) {
            const mapping_table = {
                '<': '&lt;',
                '>': '&gt;'
            }

            Object.entries(mapping_table).forEach(([k, v]) => {
                value = value.replaceAll(k, v)
            })
            return value

        },
        format_enum(value, property, item_copy, key) {
            if (Array.isArray(value)) {
                let incorrect_value_indexes = []
                for (let i = 0; i < value.length; i++) {
                    let index_value = value[i]
                    if (property.enum.indexOf(index_value) === -1) {
                        incorrect_value_indexes.push(i)
                    }
                }

                for (let i = incorrect_value_indexes.length - 1; i > -1; i--) {
                    value.splice(incorrect_value_indexes[i], 1)
                }
            } else {
                if (value !== null && property.enum.indexOf(value) === -1) {
                    delete item_copy[key]
                }
            }
        },
        handle_result(result, action) {
            Object.keys(result.data).forEach(key => {
                this.update_internal_value(key, result.data[key])
            })
            this.original_item = JSON.parse(JSON.stringify(this.internal_value))
            this.$emit('saved_item', this.original_item)
            this.user_activity('item', "domain", this.resource, action)

            this.set_state_property(
                {
                    module: 'dialog_manager',
                    state_property: 'signal_update_item_container',
                    data: true
                }
            )

            this.snackbar.text = `common.${action}`
            this.snackbar.json = undefined
            this.snackbar.color = "success"
        }
        ,
        handle_dialog_error(error) {
            this.snackbar.text = this.deep_get(error, 'data._locale_message') || 'system.error'
            this.snackbar.json = error.data
            this.snackbar.color = "error"
            this.set_payload_errors(error.data['_issues'])
        },
        generic_dialog_error(text) {
            this.snackbar.text = text
            this.snackbar.json = undefined
            this.snackbar.color = "error"
            this.snackbar.display = true
            this.show_spinner = false
        },
        set_payload_errors(errors, dotted_path = '') {
            for (let key in errors) {
                let value = errors[key]
                let value_dotted_path = dotted_path ? `${dotted_path}.${key}` : key

                if (this.tabs.includes(key)) {
                    this.tabs_with_errors.push(key)
                }

                if (typeof value !== 'string') {
                    this.set_payload_errors(value, value_dotted_path)
                } else if (value.includes('is not unique')) {
                    this.$set(this.payload_errors, value_dotted_path, 'common.value_not_unique')
                } else if (value.includes('null value not allowed')) {
                    this.$set(this.payload_errors, value_dotted_path, 'common.value_cannot_be_null')
                } else if (value.includes('required field')) {
                    this.$set(this.payload_errors, value_dotted_path, 'common.required')
                }
            }
        },
        icon(name) {
            return name in this.item_ui_structure['icons'] ? this.item_ui_structure['icons'][name] : "mdi-text-box-multiple"
        },
        reset_item() {
            let original_variables = Object.keys(this.original_item)
            let added_variables = Object.keys(this.internal_value).filter(x => original_variables.indexOf(x) === -1)

            //remove added variables
            added_variables.forEach(v => {
                this.internal_value[v] = undefined
            })

            //reset original variables
            original_variables.forEach(v => {
                this.internal_value[v] = this.original_item[v]
            })
        },
        toggle_fullscreen(new_value) {
            this.fullscreen = new_value
        },
        open_zoom_dialog(dotted_path) {
            this.zoom_dialog_init_dotted_path = dotted_path
        },
        media_files_equals(media_file, other_media_file) {

            if (!media_file?.['id']) {
                return false
            }

            let equals = true
            let media_file_keys = ['content_type', 'filename', 'id', 'size']
            for (let k of media_file_keys) {
                if (media_file?.[k] !== other_media_file?.[k]) {
                    equals = false
                    break
                }
            }
            return equals
        },
        ...mapActions({
            open_dialog: 'dialog_manager/open_dialog',
            set_state_property: 'set_state_property'
        }),
    },
    beforeCreate() {
    },
    created() {
        this.lazy_value = this.value
        if (this.init_editing) {
            this.is_editing = !!this.init_editing
            this.$emit('reset_init_editing')
        }
        this.original_item = JSON.parse(JSON.stringify(this.value))

        this.load_complete_item()
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped lang="sass">
.secondary_header
    overflow: visible

.secondary_header .v-card::before
    content: ""
    position: absolute
    bottom: 0
    left: 100%
    width: 24px
    height: 24px
    border-left: 24px solid var(--v-secondary-base)
    border-radius: 0 0 100px 0

</style>
