<template>
    <v-dialog
        v-model="internal_value"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        width="500"
    >
        <v-toolbar
            color="primary"
            height="48px"
            flat
            dark
        >
            {{ String.format($t('actions.may_not_be_performed'), title) }}
        </v-toolbar>
        <v-card
            tile
        >
            <v-row no-gutters class="pa-4">
                <ul>
                    <li
                        v-for="(issue, index) in issues"
                        :key="index"
                    >
                        {{ translate(issue) }}
                    </li>
                </ul>
            </v-row>
            <v-card-actions class="pt-8">
                <v-spacer/>
                <v-btn
                    :small="$vuetify.breakpoint.xsOnly"
                    @click="internal_value = false"
                    color="primary"
                >
                    {{ $t('common.ok') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import locale_mixin from "@/mixins/common/locale_mixin";

export default {
    name: "ItemActionMenuDisabledDialog",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        },
        issues: {
            type: Array,
            default: () => []
        }
    },
    mixins: [locale_mixin],
    components: {},
    data() {
        return {
            lazy_value: undefined,
        }
    },
    computed: {
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.$emit('input', this.lazy_value)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.lazy_value = val
        },
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.lazy_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>