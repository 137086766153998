<template>
    <v-footer
        color="white"
        max-width="100vw"
        fixed
        class="justify-center"
    >
        <span>
            <v-img
                src="@/assets/esi-logo.png"
            />
        </span>
    </v-footer>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
    name: "TheFooter",
    props: [],
    components: {},
    data() {
        return {
            // ...
        }
    },
    computed: {
        ...mapGetters([
            // ...
        ]),
        ...mapState([
            // ...
        ]),
    },
    watch: {
        // template(v) {
        //     do stuff;
        // }
    },
    methods: {
        // template() {
        //
        // },

        ...mapActions([
            // ...
        ]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>

<style scoped>

</style>
