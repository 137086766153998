import {mapGetters, mapState} from "vuex";

export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            'user_token_json',
            'access_rights'
        ]),
        ...mapState(['user_extended_data', 'site_refreshes']),
    },
    methods: {
        has_post_access(resource) {
            return this.has_access_to_resource_method(resource, 'POST')
        },
        has_put_resource_access(resource) {
            return this.has_access_to_resource_method(resource, 'PUT')
        },
        has_get_resource_access(resource) {
            return this.has_access_to_resource_method(resource, 'GET')
        },
        has_put_item_access(resource) {
            return this.has_access_to_item_method(resource, 'PUT')
        },
        has_patch_access(resource) {
            return this.has_access_to_item_method(resource, 'PATCH')
        },

        has_delete_access(resource) {
            return this.has_access_to_item_method(resource, 'DELETE')
        },

        resource_access(endpoint) {
            return this.access_rights ? this.access_rights[endpoint] : undefined
        },
        has_access_to_resource_method(resource, method) {
            return this.resource_method_exists(resource, method) && this.has_method_access(resource, method)
        },
        has_access_to_item_method(resource, method) {
            return this.item_method_exists(resource, method) && this.has_method_access(resource, method)
        },
        resource_method_exists(resource, method) {
            let swagger_paths = this.swagger_paths(resource)
            return method.toLowerCase() in swagger_paths['resource_methods']
        },
        item_method_exists(resource, method) {
            let swagger_paths = this.swagger_paths(resource)
            return method.toLowerCase() in swagger_paths['item_methods']
        },
        has_method_access(resource, method) {
            if (this.user_token_json['system_user']) return true

            let resource_access = this.resource_access(resource)
            let methods = resource_access ? resource_access['methods'] || [] : []
            return methods.indexOf(method.toUpperCase()) > -1
        }
    },
}
