<template>
    <v-menu
        v-model="internal_menu_open"
        :open-on-hover="menu_open"
        :left="left"
        offset-x
        tile
    >
        <template v-slot:activator="{ on }">
            <v-hover
                v-if="item.children"
                v-model="menu_open"
                close-delay="20"
            >
                <v-list-item
                    v-on="on"
                    :style="css_props"
                    @click="handle_click(item)"
                >
                    <v-icon
                        v-if="item.icon"
                        class="pr-2"
                        small
                    >
                        {{ item.icon }}
                    </v-icon>
                    <v-list-item-title>
                        {{ item.text }}
                    </v-list-item-title>
                    <v-icon
                        v-if="item.children"
                        style="margin-left: auto"
                        small
                    >
                        mdi-chevron-right
                    </v-icon>
                </v-list-item>
            </v-hover>
            <v-list-item
                v-else
                v-model="menu_open"
                :style="css_props"
                @click="handle_click(item)"
            >
                <v-icon
                    v-if="item.icon"
                    class="pr-2"
                    small
                >
                    {{ item.icon }}
                </v-icon>
                <v-list-item-title>
                    {{ item.text }}
                </v-list-item-title>
            </v-list-item>
        </template>


        <v-hover
            v-model="sub_menu_open"
            close-delay="20"
        >
            <v-list
                class="pa-0"
                dense
            >
                <ItemActionMenuMenuSubItem
                    v-for="(item, index) in item.children"
                    v-model="sub_sub_menu_open"
                    v-on:index="sub_sub_menu_open = index"
                    v-on:index_list="set_sub_sub_menu(index, $event)"
                    :key="index"
                    :item="item"
                    :index="index"
                    :position="position"
                    :css_props="css_props"
                    @action="handle_click"
                    @issues="$emit('issues', $event)"
                />
            </v-list>
        </v-hover>


    </v-menu>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import ItemActionMenuMenuSubItem from "@/components/item_action_menu/sub_menu/MenuSubItem";

export default {
    name: "ItemActionMenuItem",
    props: {
        index: {
            type: Number
        },
        item: {
            type: Object
        },
        position: {
            type: Object
        }
    },
    mixins: [],
    components: {
        ItemActionMenuMenuSubItem
    },
    data() {
        return {
            menu_open: false,
            sub_menu_open: false,
            sub_sub_menu_open: null,
            sub_sub_menu_indexes: [],
            sub_sub_menu_checker: false,
        }
    },
    computed: {
        internal_menu_open: {
            get() {
                return this.menu_open || this.sub_menu_open || this.sub_sub_menu_checker
            },
            set(val) {
                this.menu_open = val
                this.sub_menu_open = val
            },
        },
        left() {
            const viewport_width = window.innerWidth
            return (this.position.x + 440) > viewport_width;
        },
        theme() {
            return this.$vuetify.theme.isDark ? 'dark' : 'light'
        },
        css_props() {
            return {
                '--background': this.$vuetify.theme.themes[this.theme].background,
            }
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        set_sub_sub_menu(index, val) {
            this.sub_sub_menu_indexes[index] = val
            this.sub_sub_menu_checker = this.sub_sub_menu_indexes.some(v => v === true)
        },
        handle_click(item) {
            if (item?.children) return
            this.$emit('action', item)
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped lang="sass">
.v-list-item
    min-height: 35px
    background-color: var(--background) !important
    cursor: pointer

.v-list-item:hover
    opacity: 0.75

.v-list-item::before
    display: none !important
</style>