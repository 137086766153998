import local_storage_mixin from "@/mixins/common/local_storage_mixin";

export default {
    namespaced: true,
    state: {
        current: null /*current displayed dialog*/,
        dialogs: null,

        /*
        dialogs: {
            item: object (document),
            resource: string ( the specific resource),
            init_editing: init_editing (opens dialog in edit mode),
            create_new: bool (starts create new item flow),
        }
         */


        signal_update_item_container: false
    },
    getters: {},
    mutations: {
        add(state, dialog) {
            if (!state.dialogs) state.dialogs = []
            console.log(state.dialogs, dialog)
            if (state.dialogs.filter(x => x['item']['_id'] === dialog['item']['_id']).length) {
                return
            }
            state.dialogs.push(dialog)
            local_storage_mixin.methods.set_local_storage('dialogs', state.dialogs)
        },
        remove(state, dialog) {
            let index = state.dialogs.map(x => x['item']['_id']).indexOf(dialog['item']['_id'])
            if (index === -1) return
            state.dialogs.splice(index, 1)
            local_storage_mixin.methods.set_local_storage('dialogs', state.dialogs)

        },
        set_current_dialog(state, dialog) {
            state['current'] = dialog
        },
        edit_dialog(state, dialog) {
            let index = state.dialogs.map(x => x['item']['_id']).indexOf(dialog['item']['_id'])
            state.dialogs[index] = dialog
            local_storage_mixin.methods.set_local_storage('dialogs', state.dialogs)
        },
        reset(state) {
            state.dialogs = []
            local_storage_mixin.methods.set_local_storage('dialogs', state.dialogs)
        },
    },
    actions: {
        open_dialog({commit, state}, dialog) {
            if (!state['dialogs']) {
                commit('reset')
            }

            if (!dialog.create_new) {
                commit('add', dialog);
            }
            commit('set_current_dialog', dialog)
        },
        remove_dialog({commit, state}, dialog) {
            commit('remove', dialog)
            if (state.current && state.current.item['_id'] === dialog.item['_id']) {
                commit('set_current_dialog', null)
            }
        },
        minimize_dialog({commit, state}, dialog) {
            if (state.current.item['_id'] === dialog.item['_id']) {
                commit('set_current_dialog', null)
            }
        },
        maximize_dialog({commit}, dialog) {
            console.log("maximize_dialog", dialog)
            commit('set_current_dialog', dialog)
        },
        edit_dialog({commit}, dialog) {
            commit('edit_dialog', dialog)
        },
        reset({commit}) {
            commit('reset')
        },
    },

}
