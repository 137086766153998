<template>
    <v-menu
        v-if="dependency_links_list.length"
        offset-x
        top
        class="app-layout--level-502"
    >
        <template #activator="{attrs, on}">
            <v-btn
                icon
            >
                <v-icon
                    v-on="on"
                    v-bind="attrs"
                >
                    mdi-link-variant
                </v-icon>
            </v-btn>

        </template>
        <v-card
            height="450px"
            min-width="250px"
            style="overflow-y: auto"
        >
            <v-list>
                <v-list-item
                    v-for="(dependency_resource, key) in dependency_links_list"
                    :key="key"
                >
                    <Link
                        :value="dependency_resource"
                        :resource="resource"
                        :link_results="items_per_dependency[dependency_resource]"
                        :icon="get_resource_icon(dependency_resource)"
                        :multiple_dependencies="get_dependency(dependency_resource).length > 1"
                        :single_dependency="get_dependency(dependency_resource).length === 1"
                        :display_create_new="has_post_access(dependency_resource)"
                        @click="go_to_dependency(dependency_resource, $event)"
                        @create_new="create_new_dependency_document(dependency_resource, $event)"
                        show-label
                        full-width
                    />
                </v-list-item>
            </v-list>
        </v-card>


    </v-menu>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import item_mixin from "@/mixins/item/mixin"
// import Link from "@/components/item_container/item/dialog/DependenciesLinks/DependencyLink";
import dependency_mixin from "@/mixins/item/dependency_mixin";
import Link from "@/components/item_dialog/dependencies/Link";

export default {
    name: "ItemDialogDependencies",
    props: {
        "resource": String,
        "item": Object
    },
    mixins: [item_mixin, dependency_mixin],
    components: {Link},
    data() {
        return {
            items_per_dependency: {},
        }
    },
    computed: {
        dependency_links_list() {
            let dependency_links = this.item_ui_structure['dependency_links'] || {}

            if (!Object.keys(this.items_per_dependency).length) return this.items_per_dependency

            return Object.keys(dependency_links)
                .filter(resource => {
                    return (
                        dependency_links[resource] &&
                        dependency_links[resource]['display_in_list'] &&
                        this.has_get_resource_access(resource) &&
                        (
                            this.number_of_resource_dependencies(this.items_per_dependency[resource]) > 0 ||
                            this.has_post_access(resource)
                        )
                    )
                })

        },
        ...mapGetters({}),
        ...mapState({'swagger': state => state.swagger.local_storage_document}),
    },
    watch: {
        items_per_dependency(val) {
            let number_of_dependency_links = 0
            for (let v of Object.values(val)) {
                if (this.number_of_resource_dependencies(v) > 0) {
                    number_of_dependency_links += 1
                }
            }
            this.$emit('number_of_dependency_links', number_of_dependency_links)
        },
        item_ui_structure() {
            this.load_items_per_dependency_link()
        },
    },
    methods: {
        go_to_dependency(dependency_resource, property) {
            if (!property) {
                property = this.swagger['x-domain-dependencies'][this.resource][dependency_resource][0]
            }

            let filter = {
                [property]: {
                    $ref: this.item['_id']
                }
            }

            this.$emit('minimize')
            this.go_to(
                "ItemSearch",
                {
                    resource: dependency_resource,
                    parent: this.resource,
                },
                {
                    filter: JSON.stringify(filter),
                    text_search: ''
                }
            )

        },
        create_new_dependency_document(dependency_resource, property) {
            this.go_to_dependency(dependency_resource, property)
            this.open_dialog(
                {
                    item: this.init_dependency_item(this.item, dependency_resource),
                    resource: dependency_resource,
                    create_new: true,
                }
            )
        },
        get_dependency(dependency_resource) {
            return this.swagger['x-domain-dependencies'][this.resource][dependency_resource] || []
        },
        async load_items_per_dependency_link() {
            let items_per_dependency_copy = JSON.parse(JSON.stringify(this.items_per_dependency))
            let dependency_links = this.item_ui_structure['dependency_links'] || {}
            for (let dependency_resource of Object.keys(dependency_links)) {
                if (!items_per_dependency_copy[dependency_resource]) {
                    items_per_dependency_copy[dependency_resource] = {}
                }

                for (let dependency_property of this.get_dependency(dependency_resource)) {
                    let result = await this.api_get({
                        'url': `/${dependency_resource}`,
                        'params': {
                            where: {[dependency_property]: this.item['_id']}
                        },
                        projection: {'_id': 1},
                        page: 1,
                        max_results: 1
                    })
                    items_per_dependency_copy[dependency_resource][dependency_property] = result['data']['_meta']['total']
                }
            }

            this.items_per_dependency = items_per_dependency_copy
        },
        number_of_resource_dependencies(resource_dependency) {
            return Object.keys(resource_dependency)
                .map(x => resource_dependency[x])
                .reduce((acc, cur) => acc + cur, 0)
        },
        ...mapActions({open_dialog: 'dialog_manager/open_dialog'}),
    },
    beforeCreate() {
    },
    created() {
        this.load_items_per_dependency_link()
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
