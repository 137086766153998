import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes: {
            light: {
                primary: '#007ACA',

                secondary: '#2196F3',

                /*Accent*/
                accent: '#82B1FF',

                /*Semantic colors*/
                background: '#F1F4F8',
                error: '#EE4057',
                info: '#F39F21',
                success: '#0FAB4D',
                warning: '#FFC107',
                app_red: '#e70641',


                /* Extended colors */
                extended_1: '#FF8A11',
                extended_2: '#5FD86C',
                extended_3: '#A60FCA',
                extended_4: '#68C4FF',
                extended_5: '#FFCA38',
                extended_6: '#A98C70',
                extended_7: '#FF7777',


                icon: 'mdi-weather-sunny',
                locale: 'common.light',
                font: 'Work Sans'
            },
            dark: {
                icon: 'mdi-weather-night',
                locale: 'common.dark',
                font: 'Work Sans',

                /*Semantic colors*/
                background: '#111',
                error: '#EE4057',
                info: '#F39F21',
                success: '#0FAB4D',
                warning: '#FFC107',
                app_red: '#e70641',

                /* Extended colors */
                extended_1: '#FF8A11',
                extended_2: '#5FD86C',
                extended_3: '#A60FCA',
                extended_4: '#68C4FF',
                extended_5: '#FFCA38',
                extended_6: '#A98C70',
                extended_7: '#FF7777',
            }
        },
        options: {
            customProperties: true,
            light: {
                icon: 'mdi-weather-sunny',
                locale: 'common.light',
                font: 'Work Sans'
            },
            dark: {
                icon: 'mdi-weather-night',
                locale: 'common.dark',
                font: 'Roboto',
            }
        }
    },
});
