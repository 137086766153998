<template>
    <component
        v-if="custom_component"
        :is="custom_component"
        v-model="internal_value"
        :after_procedures.sync="internal_after_procedures"
        :item="item"
        :property="property"
        :is_editing="is_editing"
        :removable="removable"
        :label="label"
        :resource="resource"
        :currency="currency"
        :create_new="create_new"
        :array_data="array_data"
        :dotted_path="dotted_path"
        @remove_value_from_array="$emit('remove_value_from_array')"
        @toggle_edit="$emit('toggle_edit')"
        @inner_object_click="$emit('inner_object_click', $event)"
    />
    <v-col
        v-else
        :cols="cols['cols']"
        :sm="cols['sm']"
        :md="cols['md']"
        :lg="cols['lg']"
        :xl="cols['xl']"
    >
        <Array
            v-if="property.type === 'array'"
            v-model="internal_value"
            :after_procedures.sync="internal_after_procedures"
            :item="item"
            :dotted_path="dotted_path"
            :property="property"
            :is_editing="is_editing"
            :create_new="create_new"
            :currency="currency"
            :resource="resource"
            @inner_object_click="$emit('inner_object_click', $event)"
            @toggle_edit="$emit('toggle_edit')"
        />
        <BooleanSwitch
            v-else-if="property.type === 'boolean'"
            v-model="internal_value"
            :create_new="create_new"
            :is_editing="is_editing"
            :label="label"
            :property="property"
        />

        <AppAutocomplete
            v-else-if="property.$ref && (is_editing || create_new)"
            v-model="internal_value"
            :resource="property_reference_to_resource(property.$ref)"
            :prepend-inner-icon="get_icon(property.name, 'mdi-database-search')"
            :readonly="property ? property.excluded || property.access_right === 'read' : false"
            :label="label"
            :property="property"
            :error_message="error_message"
            :init_filter="extra_filter"
            :rules="[validate(value, property)]"
            placeholder="item.api_search_placeholder"
            item_value="_id"
            clearable
        />
        <ItemContainer
            v-else-if="internal_value && property.$ref && !is_editing"
            :resource="property_reference_to_resource(property.$ref)"
            :init_filter="{'_id': value instanceof Object ? value['_id'] : value}"
            :display_header="false"
            :display_search_bar="false"
            :display_pagination="false"
            :custom_card_header="label"
            embedded_object
            class="pa-0"
        />
        <!-- Inner Objects   -->
        <Object
            v-else-if="display_object"
            v-model="internal_value"
            :item="item"
            :resource="resource"
            :property="property"
            :is_editing="is_editing"
            :removable="removable || create_new || is_editing"
            :currency="currency"
            :create_new="create_new"
            :array_data="array_data"
            :error_message="error_message"
            @inner_object_click="$emit('inner_object_click', $event)"
            @remove_value_from_array="$emit('remove_value_from_array')"
            @toggle_edit="$emit('toggle_edit')"
        />
        <StringDateNumberInteger
            v-else-if="display_string_date_number_integer"
            :value="string_and_number_formatted_value"
            @input="internal_value = $event"
            :property="property"
            :resource="resource"
            :is_editing="is_editing"
            :create_new="create_new"
            :removable="removable"
            :label="label"
            :currency="currency"
            :error_message="error_message"
            :dotted_path="dotted_path"
            @remove_value_from_array="$emit('remove_value_from_array')"
        />
        <Select
            v-else-if="property.enum && property.type !== 'array' && !display_string_date_number_integer"
            :value="internal_value === true ? '' :  internal_value"
            @input="internal_value = $event"
            :property="property"
            :resource="resource"
            :is_editing="is_editing"
            :create_new="create_new"
            :label="label"
            :removable="removable"
            :error_message="error_message"
            @remove_value_from_array="$emit('remove_value_from_array')"
        />
    </v-col>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
// import debounce from 'lodash/debounce'
import StringDateNumberInteger from "@/components/item_dialog/tab/data/StringDateNumberInteger";
import Select from "@/components/item_dialog/tab/data/Select";
import item_mixin from "@/mixins/item/mixin"
import BooleanSwitch from "@/components/item_dialog/tab/data/BooleanSwitch";
import AppAutocomplete from "@/components/app_autocomplete/Index";

export default {
    name: "ItemDialogTabData",
    props: {
        value: {
            type: [String, Object, Array, Number, Boolean]
        },
        after_procedures: Array,
        property: {
            type: Object
        },
        dotted_path: String,
        is_editing: {
            type: Boolean,
            default: false
        },
        removable: {
            type: Boolean,
            default: false
        },
        resource: {
            type: String,
        },
        currency: {
            type: String
        },
        create_new: {
            type: Boolean,
            default: false
        },
        array_data: Boolean,
        item: Object,
        payload_errors: Object,
        extra_filter: Object
    },
    mixins: [item_mixin],
    components: {
        AppAutocomplete,
        BooleanSwitch,
        Select,
        StringDateNumberInteger,
        Object: () => import('@/components/item_dialog/tab/data/Object'),
        Array: () => import('@/components/item_dialog/tab/data/Array'),
        ItemContainer: () => import('@/components/item_container/Index'),

        /*Custom data components*/
        Icon: () => import(/* webpackChunkName: "Icon"*/ '@/components/item_dialog/tab/data/custom_data_components/icon/Index'),
        TextArea: () => import(/* webpackChunkName: "TextArea"*/ '@/components/item_dialog/tab/data/custom_data_components/text_area/Index'),
        HtmlArea: () => import(/* webpackChunkName: "HtmlArea"*/ '@/components/item_dialog/tab/data/custom_data_components/html_area/Index'),
        PartnerExternalCustomer: () => import(/*webpackChunkName: "PartnerExternalCustomer"*/ '@/components/item_dialog/tab/data/custom_data_components/partner_external_customer/Index'),
        PartnerImportVariables: () => import(/*webpackChunkName: "PartnerExternalCustomer"*/ '@/components/item_dialog/tab/data/custom_data_components/partner_import_variables/Index'),
        UiStructure: () => import (/*webpackChunkName: "UiStructure"*/ '@/components/item_dialog/tab/data/custom_data_components/ui_structure/Index'),
        AccessRight: () => import (/*webpackChunkName: "AccessRight"*/ '@/components/item_dialog/tab/data/custom_data_components/access_right/Index'),
        MediaFile: () => import (/*webpackChunkName: "MediaFile"*/ '@/components/item_dialog/tab/data/custom_data_components/media_file/Index'),
        CaseListComponent: () => import (/*webpackChunkName: "CaseListComponent"*/ '@/components/item_dialog/tab/data/custom_data_components/case_list_component/Index'),
        Json: () => import (/*webpackChunkName: "AppJsonEditor"*/ '@/components/item_dialog/tab/data/custom_data_components/json_editor_stringify/Index')
    },
    data() {
        return {
            lazy_value: null,
            array_cols: {
                'view': {
                    cols: 12, sm: 12, md: 12, lg: 12, xl: 12
                },
                'edit': {
                    cols: 12, sm: 12, md: 12, lg: 12, xl: 12
                }
            },
            boolean_cols: {
                'view': {
                    cols: 6, sm: 4, md: 3, lg: 3, xl: 3
                },
                'edit': {
                    cols: 12, sm: 6, md: 6, lg: 6, xl: 6
                }
            },
            field_cols: {
                'view': {
                    cols: 6, sm: 4, md: 3, lg: 3, xl: 3
                },
                'edit': {
                    cols: 12, sm: 12, md: 6, lg: 6, xl: 6
                }
            },
            object_cols: {
                'view': {
                    cols: 12, sm: 12, md: 6, lg: 6, xl: 6
                },
                'edit': {
                    cols: 12, sm: 12, md: 6, lg: 6, xl: 6
                }
            },
            error_message: '',
            internal_after_procedures: null
        }
    },
    computed: {
        cols() {
            let mode = (this.is_editing || this.create_new) ? 'edit' : 'view'
            if (this.property.type === 'array' || this.array_data) {
                return this.array_cols[mode]
            } else if (this.property.type === 'boolean') {
                return this.boolean_cols[mode]
            } else if (this.display_object || (this.property.$ref && !this.is_editing)) {
                return this.object_cols[mode]
            } else {
                return this.field_cols[mode]
            }
        },
        label() {
            if (!this.resource || !this.property.name) return ''
            let required_sign = this.is_required(this.property) && (this.is_editing || this.create_new) ? '*' : ''
            return this.locale_key(this.property.name, [this.resource_translate_location, 'item'], true) + required_sign
        },
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.error_message = ''
                this.$emit('input', this.lazy_value)
            },
        },

        custom_component() {
            if (!this.property.description) return undefined
            let components = Object.keys(this.$options.components)
            let regex = '#item_component:[A-Za-z]*'
            let match = this.property.description.match(regex)
            if (!match) return ''
            let custom_component_tag = match[0]
            let custom_component_name = custom_component_tag.split(':').pop().toLowerCase()

            let found_index = components.map(x => x.toLowerCase()).indexOf(custom_component_name)
            if (found_index > -1) {
                return components[found_index]
            }

            return undefined
        },
        display_string_date_number_integer() {
            if (this.is_editing || this.create_new) {
                return (this.property.type === 'string' || this.property.type === 'number' || this.property.type === 'integer') && !this.property.enum
            }
            return (this.property.type === 'string' || this.property.type === 'number' || this.property.type === 'integer') && !this.get_enum_color(this.property.name, this.value, this.resource)
        },
        display_object() {
            if (this.is_editing || this.create_new) {
                return this.property.type === 'object' && Object.keys(this.property.properties).length
            }
            return this.property.type === 'object' && this.internal_value
        },
        string_and_number_formatted_value() {
            if (this.property.type !== 'string' && this.property.type !== 'number' || !this.internal_value) return this.internal_value
            let empty_string_received_boolean_bug = typeof this.internal_value === "boolean"
            if (empty_string_received_boolean_bug) return ''
            return (this.property.enum ? this.locale_key(this.internal_value, [this.resource_translate_location, 'item'], true) : this.internal_value)
        },
        ...mapGetters({}),
        ...mapState({'swagger': state => state.swagger.local_storage_document}),
    },
    watch: {
        value: {
            handler(val) {
                this.lazy_value = val
            },
            immediate: true
        },
        after_procedures: {
            handler(val) {
                this.internal_after_procedures = val
            },
            immediate: true
        },
        internal_after_procedures: {
            handler(val) {
                this.$emit('update:after_procedures', val)
            },
            deep: true
        },
        payload_errors: {
            handler(errors) {
                if (!errors) return
                for (let [key, value] of Object.entries(errors)) {
                    const name = this.property.name
                    const path = key.split('.')
                    if (path.includes(name) && !this.property.excluded) {
                        this.error_message = value
                    }
                }
            }, immediate: true
        },
    },
    methods: {
        ...mapActions([]),
    }
    ,
    beforeCreate() {
    }
    ,
    created() {
    }
    ,
    beforeMount() {
    }
    ,
    mounted() {
    }
    ,
    beforeUpdate() {
    }
    ,
    updated() {
    }
    ,
    beforeDestroy() {
    }
    ,
    destroyed() {
    }
    ,
}
</script>
<style scoped>
</style>
