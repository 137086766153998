export default {
    data() {
        return {}
    },
    methods: {
        is_object(x) {
            return x && typeof x === "object" && !Array.isArray(x)
        },
        object_equals(a, b) {
            if (!Object.keys(a).every((x) => x in b && a[x] === b[x])) return false
            if (!Object.keys(b).every((x) => x in a && b[x] === a[x])) return false
            return true
        },
        _entries(x) {
            if (!this.is_object(x) && !Array.isArray(x)) return []
            return this.is_object(x) ? Object.entries(x) : x.map((v, index) => [index, v])
        },
        deep_copy(target) {
            if (!this.is_object(target) && !Array.isArray(target)) {
                return target
            }
            return JSON.parse(JSON.stringify(target))
        },
        deep_set(target, dotted_key_path, new_value) {
            if ([null, undefined, ''].indexOf(dotted_key_path) > -1) {
                console.log("updating entire target")
                target = JSON.parse(JSON.stringify(new_value))
                return
            }

            if (dotted_key_path.indexOf(".") > -1) {
                let keys = dotted_key_path.split(".")
                let first_key = this.is_object(target) ? keys[0] : parseInt(keys[0])
                if (!(first_key in target)) {
                    target[first_key] = {}
                }
                this.deep_set(target[first_key], keys.slice(1).join("."), new_value)
            } else {
                target[dotted_key_path] = new_value
            }
        },
        deep_get(target, dotted_key_path, return_copy = true) {
            let obj = target
            try {
                for (let key of dotted_key_path.split(".")) {
                    if (obj[key] === undefined) return null
                    obj = this.is_object(target) ? obj[key] : obj[parseInt(key)]
                }
            } catch (e) {
                return null
            }
            return return_copy ? this.deep_copy(obj) : obj
        },
        deep_find(target, find, path = "", perform_copy = true) {
            target = perform_copy ? this.deep_copy(target) : target
            let found = true

            for (let dotted_path of Object.keys(find)) {
                let value = find[dotted_path]
                // todo: fix comparison, doesnt work if value = object or array since
                //       object/array comparison doesnt work like in python
                if (this.deep_get(target, dotted_path, false) !== value) {
                    found = false
                    break
                }
            }
            if (!found) {
                for (let [k, v] of this._entries(target)) {
                    if (Array.isArray(v) && v.length && !this.is_object(v[0])) continue

                    let new_path = path ? path + `.${k}` : String(k)
                    if (this.is_object(v) || Array.isArray(v)) {
                        [found, new_path] = this.deep_find(v, find, new_path, false)
                    }

                    if (found) {
                        path = new_path
                        break
                    }
                }
            }

            return [found, path]
        },
        deep_find_key(target, find, path = "") {
            if (find in target) {
                return path
            }

            for (let [k, v] of this.is_object(target) ? Object.entries(target) : target.map((v, ix) => [ix, v])) {
                if (this.is_object(v) || Array.isArray(v)) {
                    let new_path = path.length ? path + `.${k}` : k
                    let found = this.deep_find_key(v, find, new_path)
                    if (found) {
                        return found
                    }
                }
            }
        },
        deep_find_all_keys(target, find, path = "", paths = []) {
            if (find in target && paths.indexOf(path) === -1) {
                paths.push(
                    path.length ? `${path}.${find}` : find
                )
            }

            let object_entries_or_enumeration = this.is_object(target) ? Object.entries(target) : target.map((v, ix) => [ix, v])
            for (let [k, v] of object_entries_or_enumeration) {
                if (this.is_object(v) || Array.isArray(v)) {
                    let new_path = path.length ? path + `.${k}` : k
                    paths = this.deep_find_all_keys(v, find, new_path, paths)
                }
            }

            return paths
        },
        recursive_cleanup(object) {
            let values_to_clean = [undefined, null, [], '']
            let keys_to_delete = []
            Object.entries(object).forEach(([key, value]) => {
                    if (value instanceof Object && !Array.isArray(value)) {
                        value = this.recursive_cleanup(value)
                        if (Object.keys(value).length === 0) {
                            keys_to_delete.push(key)
                        }
                    }
                    if (values_to_clean.indexOf(value) > -1) {
                        keys_to_delete.push(key)
                    }
                }
            )

            keys_to_delete.forEach(key => delete object[key])

            return object
        },
    }
}
