import { render, staticRenderFns } from "./MenuSubItem.vue?vue&type=template&id=14ce9908&scoped=true&"
import script from "./MenuSubItem.vue?vue&type=script&lang=js&"
export * from "./MenuSubItem.vue?vue&type=script&lang=js&"
import style0 from "./MenuSubItem.vue?vue&type=style&index=0&id=14ce9908&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14ce9908",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VHover,VIcon,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu})
