<template>
    <div>
        <v-row no-gutters align="center" justify="center">
            <v-col
                cols="12" xs="12" sm="12" md="10" lg="8" xl="5"
                class="pa-2"
            >
                <v-card
                    v-if="!system_is_registered"
                    min-height="325px"
                    class="mx-auto"
                    :style="'margin-top: ' + ($vuetify.breakpoint.xsOnly ? '10px' : '80px') + ';margin-bottom: 150px'"
                    elevation="12"
                >
                    <v-card-title
                        class="secondary"
                    >
                        <v-spacer/>
                        <span style="color: whitesmoke">Register System</span>
                        <v-spacer/>
                    </v-card-title>
                    <v-card-text class="mt-4">
                        <ul>
                            <li>
                                Fill out all information and accept the terms.
                            </li>
                            <li>
                                When you click on <b>Send Code</b>
                                you will receive two codes that should be filled out in the code boxes.
                            </li>
                            <li>
                                <b>Observe</b> that the email code might end up in you <b>spam folder</b>!
                            </li>
                            <li>
                                When selecting OAuth provider make sure that your email is registered at the provider!
                            </li>

                        </ul>
                    </v-card-text>
                    <v-card-text>
                        <v-row no-gutters align="center" justify="center">
                            <v-col cols="6">
                                <v-text-field
                                    v-model="mobile_number"
                                    label="Mobile Number"
                                    :rules="[mobile_number_rule]"
                                    placeholder="country code first +46"
                                    :disabled="code_is_sent"
                                    autofocus
                                    type="tel"
                                    clearable
                                    outlined
                                    dense
                                />
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                            <v-col cols="5">
                                <v-text-field
                                    v-model="sms_code"
                                    :disabled="!code_is_sent"
                                    :background-color="code_is_sent ? 'warning' : ''"
                                    ref="sms_code"
                                    placeholder="must be six digest"
                                    type="number"
                                    prepend-icon="mdi-message-text-lock"
                                    label="Sms Code"
                                    :rules="[code_rule]"
                                    clearable
                                    outlined
                                    dense
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters align="center" justify="center">
                            <v-col cols="6">
                                <v-text-field
                                    v-model="email"
                                    label="Email"
                                    :rules="[email_rule]"
                                    placeholder="write you email"
                                    :disabled="code_is_sent"
                                    type="email"
                                    clearable
                                    outlined
                                    dense
                                />
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                            <v-col cols="5">
                                <v-text-field
                                    v-model="email_code"
                                    :disabled="!code_is_sent"
                                    :background-color="code_is_sent ? 'warning' : ''"
                                    placeholder="must be six digest"
                                    type="number"
                                    prepend-icon="mdi-email-lock"
                                    label="Email Code"
                                    :rules="[code_rule]"
                                    clearable
                                    outlined
                                    dense
                                />
                            </v-col>
                        </v-row>


                        <v-row no-gutters align="center" justify="center">
                            <v-col cols="6">
                                <v-text-field
                                    v-model="system_name"
                                    :disabled="code_is_sent"
                                    placeholder="Name of you System"
                                    label="System Name"
                                    clearable
                                    outlined
                                    dense
                                />
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                            <v-col cols="5">
                                <v-select
                                    v-model="oauth_provider"
                                    :items="oauth_items"
                                    :disabled="code_is_sent"
                                    label="OAuth Provider"
                                    clearable
                                    outlined
                                    dense
                                />
                            </v-col>
                        </v-row>


                        <v-row no-gutters align="center" justify="center">
                            <v-col cols="6">
                                <v-text-field
                                    v-model="system"
                                    :disabled="code_is_sent"
                                    placeholder=""
                                    label="System"
                                    :rules="[system_rule]"
                                    clearable
                                    outlined
                                    dense
                                />
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                            <v-col cols="5">

                                <IconSelect
                                    v-model="system_icon"
                                    :is_editing="!code_is_sent"
                                    label="System Icon"
                                />
                            </v-col>
                        </v-row>

                        <v-textarea
                            v-model="system_description"
                            :disabled="code_is_sent"
                            label="System Description"
                            hint="What can you system be used for?"
                            outlined
                            dense
                        ></v-textarea>

                        <v-row no-gutters align="center" justify="center">
                            <v-col cols="6">
                                <v-checkbox
                                    v-model="accept_terms"
                                    :disabled="code_is_sent"
                                    label="Accept Terms"
                                />
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                            <v-col cols="5" class="text-center">
                                <v-btn
                                    class="ma-2"
                                    outlined
                                    color="primary"
                                    @click="display_terms=true"
                                >
                                    View Terms
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-text
                        v-if="show_error_message"
                    >
                        <div class="bg-error">
                            <v-alert
                                elevation="3"
                                icon="mdi-alert"
                                type="error"
                            >
                                <pre>{{ error_message | pretty }}</pre>
                            </v-alert>
                        </div>

                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            v-if="!code_is_sent"
                            @click="send_code"
                            :disabled="!form_ok_to_send"
                            color="primary"
                        >
                            Send Code
                        </v-btn>
                        <v-btn
                            v-if="code_is_sent"
                            @click="register_system"
                            :disabled="!code_ok_to_send"
                            color="primary"
                        >
                            Register System
                        </v-btn>
                        <v-spacer/>
                        <v-btn
                            v-if="code_is_sent"
                            @click="cancel"
                            outlined
                        >
                            Cancel
                        </v-btn>

                    </v-card-actions>
                </v-card>
                <v-card
                    v-else
                    min-height="325px"
                    class="mx-auto"
                    :style="'margin-top: ' + ($vuetify.breakpoint.xsOnly ? '10px' : '80px') + ';margin-bottom: 150px'"
                    elevation="12"
                >
                    <v-card-title
                        class="secondary"
                    >
                        <v-spacer/>
                        <span style="color: whitesmoke">Register System</span>
                        <v-spacer/>
                    </v-card-title>
                    <v-card-text class="mt-4 text-center">
                        Your system was registered successfully.
                        <br/>
                        Continue to the authentication
                        <br/>
                        and sign in with your selected OAuth provider
                        <br/>
                        <br/>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            @click="redirect_to_authentication"
                            color="primary"
                        >
                            Continue to Authentication
                        </v-btn>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row
            v-if="!code_is_sent"
            no-gutters align="center" justify="center">
            <v-btn
                @click="redirect_to_authentication"
                outlined
            >
                Cancel
            </v-btn>
        </v-row>
        <AppDialog
            v-model="display_terms"
            title="Terms"
        >
            <template v-slot:default>
                <v-card
                    height="75vh"
                >
                    <iframe
                        width="100%"
                        :height="$vuetify.breakpoint.xsOnly ? '93%' : '100%'"
                        src="/terms-of-use.html"
                    />
                </v-card>
            </template>
        </AppDialog>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import AppDialog from "@/components/app_dialog/Index";
import IconSelect from "@/components/icon_select/Index";
import api_mixin from "@/mixins/common/api_mixin";
import token_mixin from "@/mixins/common/token_mixin";

export default {
    name: "RegisterSystem",
    props: [],
    mixins: [
        api_mixin,
        token_mixin,
    ],
    components: {
        AppDialog,
        IconSelect,
    },
    data() {
        return {
            mobile_number: "+46",
            email: "",
            email_code: "",
            sms_code: "",
            system_name: "",
            oauth_provider: "",
            system: "",
            system_icon: "",
            system_description: "",
            accept_terms: false,

            pre_auth_token: "",
            error_message: undefined,
            code_is_sent: false,
            system_is_registered: false,
            display_terms: false,
            oauth_items: [
                {text: "Google", value: "google",},
                {text: "Microsoft", value: "microsoft",},
                {text: "Facebook", value: "facebook",},
                {text: "LinkedIn", value: "linkedin",},
            ]
        }
    },
    filters: {
        pretty: function (value) {
            return JSON.stringify(value, null, 2);
        }
    },
    computed: {
        show_error_message() {
            return this.error_message !== undefined
        },
        form_ok_to_send() {
            return this.mobile_number_rule(this.mobile_number) === true &&
                this.email_rule(this.email) === true &&
                this.system_rule(this.system) === true &&
                (this.system_name && this.system_name.trim().length > 0) &&
                this.accept_terms
        },
        code_ok_to_send() {
            return this.code_rule(this.email_code) === true && this.code_rule(this.sms_code) === true
        },

        ...mapGetters([
            // ...
        ]),
        ...mapState([
            // ...
            'existing_systems',
        ]),
    },
    watch: {
        // template(v) {
        //     do stuff;
        // }
    },
    methods: {
        send_code() {
            this.code_is_sent = true
            this.api_post({
                url: "/esi-system-registration/send_codes",
                data: {
                    "application": process.env.VUE_APP_NAME,
                    "email": this.email,
                    "mobile_number": this.mobile_number,
                    "system": this.system,
                    "system_name": this.system_name,
                    "system_icon": this.system_icon,
                    "system_description": this.system_description,
                    "name": this.email,
                    "oauth_provider": this.oauth_provider,
                }
            }).then((response) => {
                if (response && response['status'] === 200) {
                    this.pre_auth_token = response['data']['token']
                }
                this.error_message = undefined
                this.$refs.sms_code.focus()
            }).catch((error) => {
                console.info("ERROR", error)
                this.code_is_sent = false
                this.error_message = error.data
            })
        },
        register_system() {
            this.api_post({
                url: "/esi-system-registration/register-system",
                data: {
                    "email_code": this.email_code,
                    "sms_code": this.sms_code,
                },
                token: this.pre_auth_token
            }).then((response) => {
                console.info(response)
                this.system_is_registered = true
                this.error_message = undefined

                this.set_state_property({state_property: "system", data: this.system})
                this.set_state_property({state_property: "system_name", data: this.system_name})

                if (this.$route.path === "/") {
                    this.location.reload()
                } else {
                    this.$router.push('/')
                    this.location.reload()
                }

            }).catch((error) => {
                console.info("ERROR", error)
                this.system_is_registered = false
                this.error_message = error.data ? error.data : error
            })
        },
        cancel() {
            this.email_code = ""
            this.sms_code = ""
            this.code_is_sent = false
        },
        redirect_to_authentication() {
            this.$router.push("/")
        },
        email_rule(value) {
            if (!value) return false
            let pattern = "^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,24}))$"
            let regex = new RegExp(pattern)
            return regex.test(value) ? true : "invalid email"
        },
        code_rule(value) {
            if (!value) return false
            let pattern = "^[0-9]{6}$"
            let regex = new RegExp(pattern)
            return regex.test(value) ? true : "6 digits"
        },
        system_rule(value) {
            if (!value) return false
            if (this.existing_systems.includes(value)) return "System already exists"
            let pattern = "^[a-z]([0-9a-z-])+[a-z]{1,48}$"
            let regex = new RegExp(pattern)
            return regex.test(value) ? true : "a-z, 0-9 or dash (-)"
        },
        mobile_number_rule(value) {
            if (!value) return false
            let pattern = "^(\\+)([0-9]+){8,20}$"
            let regex = new RegExp(pattern)
            return regex.test(value) ? true : "+46701234567..."
        },

        ...mapActions([
            // ...
        ]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>

<style scoped>

</style>
