<template>
    <v-autocomplete
        v-model="internal_value"
        :items="icons"
        :label="label"
        :readonly="readonly"
        :filled="readonly"
        :clearable="clearable"
        outlined
        dense
    >
        <template v-slot:item="{item}">
            <v-icon class="mr-4">
                {{ item }}
            </v-icon>
            <span>
                {{ item }}
            </span>
        </template>
        <template v-slot:selection="{item}">
            <v-icon class="mr-4">
                {{ item }}
            </v-icon>
            {{ item }}
        </template>
    </v-autocomplete>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import icons from "@/configs/icons"

export default {
    name: "IconSelect",
    props: {
        value: String,
        readonly: Boolean,
        clearable: Boolean,
        label: String,
    },
    mixins: [],
    components: {},
    data() {
        return {
            'lazy_value': undefined,
            icons: icons
        }
    },
    computed: {
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.$emit('input', this.lazy_value)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.lazy_value = val
        }
    },
    methods: {
        update_internal_value(key, value) {
             this.$set(this.internal_value, key, value)
        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.lazy_value = this.value

    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
