<template>
    <AppDialog
        id="zoom_dialog"
        :value="zoom_tab_paths.length > 0"
        :height="small_screen ? '100vh' : '80vh'"
        :fullscreen="small_screen"
        @click_escape="close_zoom_tab(zoom_tab_index)"
        @updated_content_height="recalculate_ada_tab_height_key += 1"
        style="box-shadow: none !important"
        unique_key="zoom_dialog"
        transparent
        persistent
        disable_header
        disable_scrolling
        medium
    >
        <AppTabs
            v-if="dialog_schema"
            v-model="tab_index"
            :tab_names="Object.keys(properties_by_tab)"
            :hide_tab_navigation="Object.keys(properties_by_tab).length === 1"
            :icon_by_tab_name="icon_by_tab_name"
            :required_tabs="required_tabs"
            :top="!small_screen"
            :bottom="small_screen"
            :locale_locations="[resource_title.toLowerCase(), 'item']"
            :recalculate_height_key="recalculate_ada_tab_height_key.toString()"
            height="100%"
            class="app-tabs--content_application-background"
            vertical
        >
            <template #header>
                <v-row no-gutters>
                    <v-col
                        cols="4" sm="3" md="3" lg="2" xl="2"
                        v-for="(dotted_path, index) in zoom_tab_paths"
                        :key="index"
                    >
                        <v-tooltip
                            bottom
                            :disabled="$t(locale_key(dotted_path_title(dotted_path))).length < 11 || $vuetify.breakpoint.mdAndDown"
                        >
                            <template #activator="{on, attrs}">
                                <AppTabCard
                                    v-on="on"
                                    v-bind="attrs"
                                    :active="index  === zoom_tab_index"
                                    :text="locale_key(dotted_path_title(dotted_path))"
                                    @click="zoom_tab_index = index"
                                    @close="close_zoom_tab(index)"
                                />

                            </template>
                            {{
                                $t(locale_key(dotted_path_title(dotted_path)))
                            }}
                        </v-tooltip>
                    </v-col>
                </v-row>

            </template>
            <template
                #tab-items
            >
                <v-tab-item
                    v-for="(tab_item, index) in Object.keys(properties_by_tab)"
                    :key="index"
                >
                    <ItemDialogTab
                        v-if="tab_index === index"
                        v-model="displayed_value"
                        :after_procedures.sync="internal_after_procedures"
                        :key="current_dotted_path"
                        :item="internal_value"
                        :resource="resource"
                        :properties="properties_by_tab[tab_item]"
                        :schema="dialog_schema"
                        :is_editing="is_editing"
                        :currency="currency"
                        :create_new="create_new"
                        :custom_ui_structure="custom_ui_structure"
                        :disable_property_access_check="disable_property_access_check"
                        :group_unspecified_properties="group_unspecified_properties"
                        :inner_object="dialog_schema.name"
                        :payload_errors="payload_errors"
                        :dotted_path="current_dotted_path"
                        @inner_object_click="add_zoom_tab($event)"
                        @toggle_edit="$emit('toggle_edit')"
                        class="pa-2 pt-4"
                    />
                </v-tab-item>
            </template>

            <template #footer v-if="is_editing || create_new">
                <v-toolbar
                    dense
                    height="48px"
                    class="pa-0"
                >
                    <v-spacer/>
                    <v-btn
                        v-text="$t('common.ok')"
                        color="primary"
                        @click="$emit('close')"
                    />

                </v-toolbar>
            </template>
        </AppTabs>
    </AppDialog>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import AppDialog from "@/components/app_dialog/Index";
import AppTabs from "@/components/app_tabs/Index";
import item_mixin from "@/mixins/item/mixin"
import item_dialog_mixin from "@/mixins/item/dialog_mixin"
import AppTabCard from "@/components/app_tab_card/Index";
import ItemDialogTab from "@/components/item_dialog/tab/Index";


export default {
    name: "ItemDialogZoomDialog",
    props: {
        value: {
            'type': Object
        },
        after_procedures: Array,
        resource: String,
        init_dotted_path: String,
        item_schema: Object,
        is_editing: Boolean,
        create_new: Boolean,
        currency: String,
        custom_ui_structure: Object,
        disable_property_access_check: Boolean,
        group_unspecified_properties: Boolean,
        payload_errors: Object
    },
    mixins: [item_mixin, item_dialog_mixin],
    components: {ItemDialogTab, AppTabCard, AppTabs, AppDialog},
    data() {
        return {
            lazy_value: null,
            tab_index: 0,
            zoom_tab_index: 0,
            zoom_tab_paths: [
                /*
                    dotted_paths
                */
            ],
            recalculate_ada_tab_height_key: 0,
            internal_after_procedures: null
        }
    },
    computed: {
        displayed_value: {
            get: function () {
                return this.deep_get(
                    this.internal_value,
                    this.current_dotted_path,
                    false
                ) || {}
            },
            set: function (val) {
                if (this.current_dotted_path_includes_array) {
                    let internal_value_cpy = JSON.parse(JSON.stringify(this.internal_value))
                    this.deep_set(internal_value_cpy, this.current_dotted_path, val)
                    this.internal_value = internal_value_cpy
                } else {
                    this.deep_set(this.internal_value, this.current_dotted_path, val)
                }

            }
        },
        current_dotted_path_includes_array() {
            let keys = this.current_dotted_path.split('.')
            return keys.filter(x => parseInt(x) >= 0).length
        },
        dialog_schema() {
            console.log("==== GENERATE ZOOM DIALOG SCHEMA ====")
            if (!this.current_dotted_path) return null
            let dialog_schema = this.item_schema
            let paths = this.current_dotted_path.split('.')

            for (let path of paths) {
                if (parseInt(path) >= 0) continue

                dialog_schema = dialog_schema['properties'][path]
                if (dialog_schema.type === 'array') {
                    dialog_schema = this.format_property_schema_for_array(dialog_schema)
                }
            }
            return dialog_schema
        },

        current_dotted_path() {
            if (!this.zoom_tab_paths) return null
            return this.zoom_tab_paths[this.zoom_tab_index]
        },

        tab_names() {
            return Object.keys(this.properties_by_tab)
        },
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.$emit('input', this.lazy_value)
            },
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value: {
            handler(val) {
                this.lazy_value = val
            },
            immediate: true
        },
        after_procedures: {
            handler(val) {
                this.internal_after_procedures = val
            },
            immediate: true
        },
        internal_after_procedures: {
            handler(val) {
                this.$emit('update:after_procedures', val)
            },
            deep: true
        },
        init_dotted_path(val, old_val) {
            if (val && val !== old_val) {
                this.zoom_tab_paths = [val]
                this.zoom_tab_index = 0
            }
            if (!val) {
                this.zoom_tab_paths = []
                this.zoom_tab_index = null
            }
        },
    },
    methods: {
        dotted_path_title(dotted_path) {
            let paths = dotted_path.split('.')

            if (parseInt(paths.at(-1)) >= 0) {
                return paths.at(-2)
            }
            return paths.at(-1)
        },

        close_zoom_tab(index) {
            if (this.zoom_tab_paths.length === 1) {
                this.$emit('close')
                return
            }

            if (
                (this.zoom_tab_index > 0 && index <= this.zoom_tab_index) ||
                (this.zoom_tab_index === this.zoom_tab_paths.length - 1)
            ) {
                this.zoom_tab_index -= 1
            }

            this.zoom_tab_paths.splice(index, 1)
        },
        add_zoom_tab(sub_dotted_path) {
            let new_dotted_path = `${this.current_dotted_path}.${sub_dotted_path}`

            let index = this.zoom_tab_paths.indexOf(new_dotted_path)

            if (index === -1) {
                this.zoom_tab_paths.push(new_dotted_path)
                this.zoom_tab_index = this.zoom_tab_paths.length - 1
            } else {
                this.zoom_tab_index = index
            }

        },
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        if (this.init_dotted_path && this.zoom_tab_paths.indexOf(this.init_dotted_path) === -1) {
            this.zoom_tab_paths.push(this.init_dotted_path)
        }
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
