<template>
    <div>
        <v-expansion-panels
            :value="expanded_panels"
            flat
            multiple
        >
            <v-expansion-panel
                v-for="(group, i_1) in display_groups"
                :key="i_1"
                class="app-object--transparent-background mt-0"
            >
                <v-expansion-panel-header
                    v-if="existing_display_groups > 1 && group.content_by_type && Object.keys(group.content_by_type).length && group['title']"
                    class="app-text--highlighted pt-1 pb-0 px-3"
                    color="transparent"
                >
                    <v-card-title
                        class="pl-0 pt-0 pb-0 title app-text--highlighted"
                    >
                        {{ $t(group['title']) }}
                    </v-card-title>
                </v-expansion-panel-header>

                <v-expansion-panel-content
                    v-if=" Object.keys(group.content_by_type).length"
                    color="transparent"
                    class="no-gutters"
                >
                    <div
                        v-for="(filter, index) in [non_card_type_filter, card_type_filter]"
                        :key="`${index}_${is_editing}_${create_new}`"
                    >
                        <v-card
                            v-if="get_properties(group.content_by_type, Object.keys(group.content_by_type).filter(filter)).length"
                            :class="index === 1 ? 'pa-0' : is_editing || create_new ? 'px-2 pt-4 pb-0 mx-2 mt-0 mb-2' : 'px-2 py-4 mx-2 mt-0 mb-2'"
                            :flat="index === 1"
                            :tile="index === 1"
                            :color="index === 1 ? 'transparent' : ''"
                        >
                            <v-row
                                style="width:100%"
                                no-gutters
                            >
                                <TabData
                                    v-for="(property, index) in get_properties(group.content_by_type, Object.keys(group.content_by_type).filter(filter).sort(type_sorter)).filter(prop => prop.type !== 'custom_component' && show_property(prop))"
                                    :value="internal_value[property.name]"
                                    @input="update_internal_value(property.name, $event)"
                                    :after_procedures.sync="internal_after_procedures"
                                    :key="`${property['name']}_${property['excluded']}_${property['dependency_required']}_${index}`"
                                    :item="item || internal_value"
                                    :resource="resource"
                                    :property="property"
                                    :is_editing="is_editing"
                                    :create_new="create_new"
                                    :currency="currency"
                                    :schema="schema"
                                    :payload_errors="payload_errors"
                                    :extra_filter="extra_filters[property.name]"
                                    :dotted_path="dotted_path"
                                    @inner_object_click="$emit('inner_object_click', $event)"
                                    @toggle_edit="$emit('toggle_edit')"
                                    class="pa-2"
                                />
                                <component
                                    v-for="custom_component_property in get_properties(group.content_by_type, Object.keys(group.content_by_type).filter(filter).sort(type_sorter)).filter(prop => prop.type === 'custom_component')"
                                    :is="custom_component_property.name"
                                    :key="`${custom_component_property.name}_${internal_value['_id']}`"
                                    v-model="internal_value"
                                    :item="item || internal_value"
                                    :resource="resource"
                                    :schema="schema"
                                    :is_editing="is_editing"
                                    :create_new="create_new"
                                    :dotted_path="dotted_path"
                                />
                            </v-row>
                        </v-card>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import TabData from "@/components/item_dialog/tab/data/Index"
import item_mixin from "@/mixins/item/mixin"

export default {
    name: "ItemDialogTab",
    props: {
        'value': Object,
        'item': Object,
        'properties': Array,
        'dotted_path': String,
        'is_editing': Boolean,
        'resource': String,
        'schema': Object,
        'currency': String,
        'create_new': Boolean,
        'inner_object': String,
        'custom_ui_structure': Object,  // used in mixin
        'disable_property_access_check': Boolean,
        'group_unspecified_properties': Boolean,
        'payload_errors': Object,
        after_procedures: Array
    },
    mixins: [item_mixin],
    components: {
        TabData,

        /*Custom properties */
        CaseSummary: () => import(/*webpackChunkName: "CaseSummary"*/ "@/components/item_dialog/tab/custom_property_components/case_summary/Index"),
        DebtorSummary: () => import(/*webpackChunkName: "DebtorSummary"*/ "@/components/item_dialog/tab/custom_property_components/debtor_summary/Index"),
        RemainingDebt: () => import(/*webpackChunkName: "RemainingDebt"*/ '@/components/item_dialog/tab/custom_property_components/remaining_debt/Index'),

        /*Action custom tabs*/
        AddNote: () => import(/*webpackChunkName: "AddNote"*/ '@/components/item_dialog/tab/custom_property_components/add_note/Index'),
        AddNoteOnContact: () => import(/*webpackChunkName: "AddNote"*/ '@/components/item_dialog/tab/custom_property_components/add_note_on_contact/Index'),
    },
    data() {
        return {
            lazy_value: null,
            expanded_panels: [],
            custom_component_properties: [
                '#item_component:json'
            ],
            internal_after_procedures: null
        }
    },
    computed: {
        extra_filters() {
            if (!this.schema.description) return {}

            let extra_filters = {}

            let regex = '#extra_filter->[a-z_]*:[a-z._]*=\\w+'
            let matches = this.schema.description.match(regex) || []
            for (let match of matches) {
                match = match.split('->')[1]
                let [property_name, filter_string] = match.split(':')
                let [filter_attribute, filter_value] = filter_string.split('=')

                extra_filters[property_name] = {
                    [filter_attribute]: filter_value
                }
            }

            return extra_filters
        },
        existing_display_groups() {
            let count = 0
            // eslint-disable-next-line no-unused-vars
            for (let v of this.display_groups) {
                if (Object.keys(v['content_by_type']).length > 0) count++
            }
            return count
        },
        internal_value: {
            get: function () {
                return this.lazy_value
            },
            set: function (val) {
                this.lazy_value = val
                this.$emit('input', this.lazy_value)
            },
        },
        display_groups() {
            if (!this.properties) return []

            if (this.properties.length === 1 && this.properties[0].type === 'array') {

                return [{
                    'title': 'array',
                    'content_by_type': {'array': this.properties}
                }]
            }


            let display_groups_exist = this.dialog && this.dialog['display_groups']
            let handled_property_names = []
            let display_groups = []
            if (display_groups_exist) {
                for (let display_group of this.dialog['display_groups']) {
                    let content_by_type = this.group_content_by_type(display_group['content'])
                    display_groups.push({
                        'title': display_group['title'],
                        'content_by_type': content_by_type
                    })

                    handled_property_names = handled_property_names.concat(
                        display_group['content'].map(x => x['property']).filter(x => !!x)
                    )
                    handled_property_names = handled_property_names.concat(display_group['group_properties'])

                }
            }

            if (this.group_unspecified_properties) {
                let unhandled_property_names = this.properties
                    .filter(x => {
                        return (!x.excluded || this.create_new || this.is_editing) && handled_property_names.indexOf(x.name) === -1
                    })
                    .map(x => x.name)

                if (unhandled_property_names.length) {
                    display_groups.push({
                        'title': "common.other",

                        'content_by_type': this.group_content_by_type(
                            unhandled_property_names.map(x => {
                                return {'property': x}
                            })
                        )
                    })
                }
            }
            return display_groups
        },

        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value: {
            handler(val) {
                this.lazy_value = val
            },
            immediate: true
        },
        after_procedures: {
            handler(val) {
                this.internal_after_procedures = val
            },
            immediate: true
        },
        internal_after_procedures: {
            handler(val) {
                this.$emit('update:after_procedures', val)
            },
            deep: true
        }
    },
    methods: {
        update_internal_value(key, value) {
            this.$set(this.internal_value, key, value)
        },
        has_access_to_property(property) {
            if (this.inner_object) return true // Access is not performed on inner items
            let is_editing_or_create_new = this.is_editing || this.create_new
            let available_for_mediafile = undefined
            if (property.description &&
                property.description.includes('#item_component:MediaFile')) {
                available_for_mediafile = property.name
            }
            let exist_and_is_not_excluded = (
                ![null, undefined].includes(this.get_value(this.internal_value, property.name)) ||
                available_for_mediafile &&
                !property.excluded
            )
            let read_or_write_access = property.access_right === 'read' || property.access_right === 'write'
            let write_access = property.access_right === 'write'


            let has_access_to_property = exist_and_is_not_excluded && read_or_write_access || (is_editing_or_create_new && write_access)
            if (property.$ref) {
                if (!has_access_to_property) return has_access_to_property
                //    TODO: if no access to resource return false ?
                let ref_resource = this.property_reference_to_resource(property.$ref)

                let ref_access = this.user_extended_data['access_rights'][ref_resource]
                let access_to_reference = ref_access && ref_access['methods'] ? ref_access['methods'].indexOf('GET') > -1 : false
                has_access_to_property = has_access_to_property && access_to_reference
            }

            return has_access_to_property
        },
        group_content_by_type(content) {
            let content_properties = content.map(x => x['property']).filter(x => !!x)
            let targeted_properties = this.properties
                .filter(p => {
                    let found = content_properties.indexOf(p.name) > -1
                    return found && (this.disable_property_access_check || this.has_access_to_property(p))
                })

            let group_obj = {}
            for (let property of targeted_properties) {
                let type = this.property_type(property)
                if (type in group_obj) {
                    group_obj[type].push(property)
                } else {
                    group_obj[type] = [property]
                }
            }

            let content_custom_components = content.map(x => x['custom_component']).filter(x => !!x)
            if (content_custom_components.length) {
                group_obj['custom_component'] = content_custom_components.map(x => {
                    return {
                        'name': x,
                        type: 'custom_component'
                    }
                })
            }
            return group_obj
        },
        type_sorter(a, b) {
            if (b === 'object') return -1
            if (b === '$ref' && a !== 'object') return -1

            return 0
        },
        non_card_type_filter(x) {
            let card_types = ['object', 'array', 'custom_component']
            if (!this.is_editing && !this.create_new) {
                card_types.push('$ref')
            }
            return card_types.indexOf(x) === -1
        },
        card_type_filter(x) {
            return !this.non_card_type_filter(x)
        },
        get_properties(content_by_type, content_types) {
            return content_types.reduce((prev, curr) => prev = prev.concat(content_by_type[curr]), [])
        },
        property_type(property) {
            if (property.description) {
                let is_custom_component = this.custom_component_properties.filter(x => property.description.toLowerCase().includes(x)).length
                if (is_custom_component) {
                    return 'custom_component'
                }
            }
            if (property.type) {
                return property.type
            }

            if (property.$ref) {
                return '$ref'
            }

            return null
        },
        show_property(property) {
            return (!property.excluded && this.internal_value[property.name] !== null && this.internal_value[property.name] !== undefined) || this.create_new || this.is_editing
        },
        ...mapActions([
            // ...
        ]),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
        if (this.display_groups && this.dialog?.display_groups) {
            for (let i = 0; i <= this.dialog.display_groups.length; i++) {
                this.expanded_panels.push(i)
            }
        } else {
            this.expanded_panels = [0]
        }
    },
    beforeUpdate() {
    },
    updated() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
