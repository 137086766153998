<template>
    <v-hover
        #default="{hover}"
        close-delay="100"
    >
        <v-card
            @click="$emit('click')"
            :elevation="tile ? '0' : '4'"
            :color="!hover && !active ?  inactive_color: active_color"
            :tile="tile"
            :width="width ? width : ''"
            :class="$vuetify.theme.dark ? `${card_class} darken-3` : `${card_class} lighten-2`"
            style="user-select: none; margin-right:0.1rem !important;"
        >
            <v-card-subtitle
                v-if="label"
                id="label"
                class="pa-0 v-label"
            >
                {{ locale_key(label, null, true) }}
            </v-card-subtitle>

            <v-row
                no-gutters
                justify="space-between"
                align="center"
            >
                <v-col
                    cols="10"
                >
                    <v-row no-gutters>
                        <v-card-text
                            :class="label ? `${text_class} pt-1` : text_class"
                        >
                            {{ translate(text) || '-' }}
                        </v-card-text>
                    </v-row>
                </v-col>
                <v-btn
                    @click.stop=""
                    @click="$emit('close')"
                    class="app-object--absolute-top-right app-object--absolute-center-y"
                    icon
                    small
                >
                    <v-icon
                        small
                    >
                        mdi-close-box
                    </v-icon>
                </v-btn>

            </v-row>
        </v-card>
    </v-hover>

</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import common_mixin from "@/mixins/common/mixin"

export default {
    name: "AdaTabCard",
    props: {
        value: {
            'type': Boolean
        },
        active: Boolean,
        tile: Boolean,
        label: String,
        text: String,
        width: String
    },
    mixins: [common_mixin],
    components: {},
    data() {
        return {
            internal_value: undefined,
            card_class: 'ada-tab-card px-2 pt-2 pb-1 app-object--rounded-top',
            text_class: 'ada-tab-card app-text--overflow-hidden-ellipsis pa-0'
        }
    },
    computed: {
        inactive_color() {
            return this.$vuetify.theme.dark ? 'black' : 'grey'
        },
        active_color() {
            return this.$vuetify.theme.dark ? 'grey' : 'white'
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style lang="sass" scoped>
#label
    position: absolute
    left: 0.5rem
    top: 0.2rem
    padding: 0.1rem
    width: auto
    font-size: 12px

.ada-tab-card.v-card--link:focus:before
    opacity: 0
</style>
