<template>
    <div
        class="app-chip"
        :style="css_props"
    >
        <v-chip
            color="transparent"
            :dark="dark"
            :light="light"
            :dense="dense"
            :disabled="disabled"
            :size="size"
            :large="large"
            :small="small"
            :xLarge="xLarge"
            :xSmall="xSmall"
        >
            <v-icon
                v-if="icon !== undefined"
                left
            >
                {{ icon }}
            </v-icon>
            {{ internal_value }}
        </v-chip>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
    name: "AppChip",
    props: {
        value: String,
        color: String,
        dark: Boolean,
        light: Boolean,
        dense: Boolean,
        disabled: Boolean,
        large: Boolean,
        small: Boolean,
        xLarge: Boolean,
        xSmall: Boolean,
        size: [Number, String],
        icon: String,
    },
    mixins: [],
    components: {},
    data() {
        return {
            internal_value: undefined
        }
    },
    computed: {
        css_props() {
            if (!this.color) return {
                '--chip-color': 'vars(primary--text)'
            }
            return {
                '--chip-color': `var(--v-${this.color}-base)`,
            }
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style lang="sass" scoped>
.app-chip::v-deep > .v-chip
    background-color: unset

.app-chip::v-deep > .v-chip::before
    color: var(--chip-color)
    border: var(--chip-color)

.app-chip::v-deep > .v-chip > .v-chip__content
    color: var(--chip-color)
    opacity: 1
    font-weight: bold

</style>
