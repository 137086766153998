<template>
    <v-card
        v-scroll.self="on_scroll_callback ? on_scroll_callback : function(){}"
        :height="height ? height : '75vh'"
        :max-height="height ? height : '75vh'"
        :min-height="height ? height : '75vh'"
        class="overflow-y-auto"
        :tile="tile"
        :flat="flat"
        :transparent="transparent"
        :color="transparent ? 'transparent' : ''"
    >
        <slot name="default"></slot>
    </v-card>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
    name: "ScrollableWrapper",
    props: {
        value: [String, Object, Number, Boolean, Array],
        on_scroll_callback: Function,
        height: String,
        tile: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        transparent: {
            type: Boolean,
            default: false
        }
    },
    mixins: [],
    components: {},
    data() {
        return {
            internal_value: undefined
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {
    },
    created() {
        this.internal_value = this.value
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>
<style scoped>
</style>
