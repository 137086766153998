import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        props: route => ({
            status_message: route.query.status_message,
            status: route.query.status
        })
    },
    {
        path: '/register-system',
        name: "RegisterSystem",
        component: import(/*webpackChunkName "page_not_found"*/ "../views/RegisterSystem")

    },
    {
        path: '/select-system',
        name: "SelectSystem",
        component: import(/*webpackChunkName "page_not_found"*/ "../views/SelectSystem")

    },
    {
        path: '/item-search/:parent?/:resource',
        name: 'ItemSearch',
        component: () => import(/*webpackChunkName "item_search"*/ "@/views/ItemSearch"),
        props: route => ({
            text_search: route.query.text_search,
            auto_open: route.query.auto_open,
            filter: route.query.filter,
            parent: route.params.parent,
            resource: route.params.resource
        })
    },
    {
        path: '/open',
        name: 'Open',
        component: () => import(/*webpackChunkName "open"*/ "@/views/Open"),
        props: route => ({
            resource: route.query.resource,
            id: route.query.id,
            route: route.query.route
        })
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: import(/*webpackChunkName "page_not_found"*/ "../views/PageNotFound")
    }
]

const router = new VueRouter({
    mode: "history",
    routes
})

export default router
