import {mapState, mapGetters} from "vuex";

export default {
    mixins: [],
    data() {
        return {}
    },
    computed: {
        filtered_navigation_menu() {
            if (!this.navigation_menu || !this.user_extended_data) return []

            return JSON.parse(JSON.stringify(this.navigation_menu)).map(menu_item => {
                if (!this.display_menu(menu_item)) return undefined
                if ('sub_menu' in menu_item) {

                    menu_item['sub_menu'] = menu_item['sub_menu'].map(sub_menu_item => {
                        if (!this.display_menu(sub_menu_item)) return undefined
                        if ('list_menu' in sub_menu_item) {

                            sub_menu_item['list_menu'] = sub_menu_item['list_menu'].map(list_menu_item => {
                                if (!this.display_menu(list_menu_item)) return undefined
                                return list_menu_item
                            }).filter(x => x !== undefined)

                            if (sub_menu_item['list_menu'].length === 0) return undefined
                        }
                        return sub_menu_item

                    }).filter(x => x !== undefined)

                    if (menu_item['sub_menu'].length === 0) return undefined
                }

                return menu_item
            }).filter(x => x !== undefined).concat(mockup_views())
        },
        ...mapGetters([
            'navigation_menu'
        ]),
        ...mapState([
            'user_extended_data'
        ])
    },
    methods: {
        go_to(name, params, query) {
            params = params ? params : {}
            query = query ? query : {}
            let same_name = name === this.$router.currentRoute.name
            let same_params = this.same_params(params, this.$router.currentRoute.params)
            let same_query = JSON.stringify(query) === JSON.stringify(this.$router.currentRoute.query)

            if (name === undefined || (same_name && same_params && same_query)) {
                console.log("Same Navigation - reload")
                window.location.reload()
                return false
            }
            return this.$router.push({name, params, query})
        },
        same_params(new_params, router_params) {
            if (Object.keys(new_params).length !== Object.keys(router_params).length) return false

            return !Object.keys(new_params).map(x => new_params[x] === router_params[x]).filter(x => x === false).length
        },
        remove_url_query_parameter(name, url = "") {
            if (!url) {
                url = window.location.href
            }
            let regex = new RegExp("[?&](" + name + "(=([^&#]*)|&|#|$))")
            let results = regex.exec(url)

            if (!results || !results[1]) return url

            let string_to_remove = results[0].startsWith('&') ? results[0] : results[1]
            url = url.split(string_to_remove).join('')
            url = url.endsWith('?') ? url.slice(0, -1) : url

            return url
        },
        get_url_query_parameter(name, url = "") {
            if (!url) {
                url = window.location.href;
            }
            let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url)

            if (!results || !results[1]) return "";

            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },

        menu_selected(menu_item, current_route) {
            let menu_found = this.menu_found(current_route, menu_item)
            if (menu_found) return true
            if (menu_item['list_menu'] || menu_item['sub_menu']) {
                let list_or_sub_menu = menu_item['list_menu'] || menu_item['sub_menu']
                for (let sub_or_list_menu_item of list_or_sub_menu) {
                    if (this.menu_selected(sub_or_list_menu_item, current_route)) {
                        return true
                    }
                }
            }

            return false
        },
        menu_found(current_route, menu_item) {
            menu_item['params'] = menu_item['params'] ? menu_item['params'] : {}
            let same_name = menu_item['name'] === current_route.name
            let same_params = this.obj_equals(menu_item['params'], current_route.params)
            return same_name && same_params
        },

        display_menu(menu_item) {
            return !(menu_item['name'] === "ItemSearch" && !this.has_access_to_resource(menu_item['params']['resource']));
        },
        has_access_to_resource(resource) {
            let resource_without_access_control = this.exists(this.swagger) ? this.swagger['x-domains-with-no-access-control'] : []
            let resource_access_right = this.user_extended_data['access_rights'][resource]
            return resource_without_access_control.indexOf(resource) > -1 || (
                !!resource_access_right &&
                !!resource_access_right['methods'] &&
                resource_access_right['methods'].length > 0
            )
        },
    }
}

function mockup_views() {
    return []
}
