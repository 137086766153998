import {mapActions, mapGetters, mapState} from "vuex";

export default {
    mixins: [],
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            'user_token_json',
            'token_type'
        ]),
        ...mapState([
            'user_token'
        ]),
    },
    watch: {},
    methods: {
        get_token_from_url() {
            let token = this.get_url_query_parameter("token");
            if (!token.length) return false
            window.history.replaceState("", "", "/");

            return {
                token: token
            }
        },
        set_token_and_go_to_home(token) {
            this.set_token(token)
            this.go_to("Home")
        },
        set_token(token) {
            this.set_local_storage('user_token', token)
            this.set_state_property({
                state_property: "user_token",
                data: token
            });
        },
        token_expired(safety_margin_seconds = 0) {
            return Date.now() >= ((this.user_token_json?.exp || 0) * 1000 - (safety_margin_seconds * 1000))
        },
        async refresh_token() {
            if (!this.user_token) {
                return
            }
            try {
                console.log(`<<<Refresh Token - ${new Date().getTime()} >>>`)

                let result = await this.api_post(
                    {
                        url: '/authenticates/refresh-token',
                    }
                )

                if (result.status === 202) {
                    console.log("Token recently refreshed")
                } else if (result.status === 200) {
                    this.set_token(result.data)
                    await this.get_application_details()
                } else {
                    await this.sign_out('token_expired')
                }
            } catch (err) {
                await this.sign_out('token_expired')
            }
        },
        ...mapActions([
            'set_state_property'
        ]),
    }
}


