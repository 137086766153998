import {mapGetters, mapState} from "vuex";
import item_mixin from "@/mixins/item/mixin";

export default {
    mixins: [item_mixin],
    data() {
        return {}
    },
    computed: {

        ...mapGetters({}),
        ...mapState({})
    },
    methods: {
        init_dependency_item: function (item, dependency_resource) {
            let init_item = {}
            let dependency_resource_title = this.resource_to_resource_title(dependency_resource)
            let dependency_resource_schema = JSON.parse(JSON.stringify(this.swagger.components.schemas[dependency_resource_title]))
            let dependency_model_rules = this.swagger['x-model-rules'][dependency_resource_title]


            let resource_relations = {}
            let property_names = Object.keys(item).filter(x => x === '_id' || !x.startsWith('_'))
            for (let p_name of property_names) {
                let property = this.resource_schema['properties'][p_name]

                if (!property.$ref || !property) continue
                resource_relations[p_name] = property['$ref']
            }

            let dependency_relations = {}
            for (let [k, v] of Object.entries(dependency_resource_schema['properties'])) {
                if (!v.$ref || !v) continue

                dependency_relations[k] = v['$ref']
            }


            for (let [resource_property, resource_reference] of Object.entries(resource_relations)) {
                for (let [dependency_property, dependency_reference] of Object.entries(dependency_relations)) {
                    if (resource_reference === dependency_reference) {
                        init_item[dependency_property] = item[resource_property]
                    }
                }
            }

            if (dependency_model_rules) {
                this.apply_model_rules(init_item, dependency_resource_schema['properties'], dependency_model_rules)
                for (let key of Object.keys(init_item)) {
                    if (dependency_resource_schema['properties'][key]['excluded']) {
                        delete init_item[key]
                    }
                }
            }
            return init_item
        },
    },
}
