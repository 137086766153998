<template>
    <div
        :style="app_css_props"
    >
        <div v-if="!(user_is_authenticated && user_has_selected_token)">
            <v-app>
                <RegisterSystem
                    v-if="$route.name==='RegisterSystem'"
                />
                <SelectSystem
                    v-else-if="$route.name==='SelectSystem' || !system"
                />
                <TheAuthentication
                    v-else
                />
            </v-app>
        </div>
        <div v-else-if="user_is_authenticated && token_type === 'UserToken'">
            <v-app v-if="!too_many_requests">
                <TheHeader
                    @open_left_navigation_drawer="left_navigation_drawer = true"

                    @open_right_navigation_drawer="right_navigation_drawer = true"
                    @right_navigation_component="right_navigation_component = $event"
                />
                <TheLeftNavigationDrawer
                    v-model="left_navigation_drawer"
                />
                <TheRightNavigationDrawer
                    v-model="right_navigation_drawer"
                    :component="right_navigation_component"
                />

                <v-main class="mt-12">
                    <v-container fluid>
                        <v-row
                            no-gutters
                            align="center"
                            justify="center"
                            class="mt-4 mb-12"
                        >
                            <v-col
                                xs="12"
                                sm="11"
                                md="11"
                                lg="11"
                                xl="11"
                            >
                                <router-view/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-main>
                <TheFooter/>
                <ItemDialogManager/>
                <v-tour
                    v-for="nav in help_tours()"
                    :name="nav.name"
                    :key="nav.name"
                    :steps="nav.steps"
                    :options="tour_options()"
                />
            </v-app>
            <v-app v-else>
                <TooManyRequests/>
            </v-app>
        </div>
        <div v-else-if="user_is_authenticated && token_type === 'PreAuthenticationToken'">
            <v-app>
                <v-row
                    align="center"
                    justify="center"
                    no-gutters
                >
                    <v-col
                        xs="12" sm="10" md="9" lg="6" xl="6"
                    >
                        <span class="self-registration__bg"/>
                    </v-col>
                </v-row>
            </v-app>
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

import common_mixin from "@/mixins/common/mixin";
import multi_tab_state_mixin from "@/mixins/multi_tab_state/mixin"

import TheFooter from "@/components/the_footer/Index";
import ItemDialogManager from "@/components/item_dialog_manager/Index";
import TheLeftNavigationDrawer from "@/components/the_left_navigation_drawer/Index";
import TheHeader from "@/components/the_header/Index";
import TheRightNavigationDrawer from "@/components/the_right_navigation_drawer/Index";
import TheAuthentication from "@/components/the_authentication/Index";
import RegisterSystem from "@/views/RegisterSystem";
import SelectSystem from "@/views/SelectSystem";
import TooManyRequests from "@/components/too_many_requests/Index"


export default {
    name: 'App',
    mixins: [common_mixin, multi_tab_state_mixin],
    props: [],
    components: {
        TheAuthentication,
        TheRightNavigationDrawer,
        TheHeader,
        TheLeftNavigationDrawer,
        ItemDialogManager,
        TheFooter,
        SelectSystem,
        RegisterSystem,
        TooManyRequests,
    },
    data() {
        return {
            bm_open: false,
            left_navigation_drawer: false,
            app_greeting_popup: false,

            right_navigation_drawer: false,
            right_navigation_component: null
        }
    },
    computed: {
        app_css_props() {
            return {
                '--font-family': this.$vuetify.theme.currentTheme.font
            }
        },
        ...mapGetters({
            "user_is_authenticated": "user_is_authenticated",
            "user_has_selected_token": "user_has_selected_token",
            "token_type": "token_type",
            "user_token_json": "user_token_json",
            "swagger_loaded": "swagger/loaded"
        }),
        ...mapState([
            'system',
            'too_many_requests',
            'site_refreshes',
            'existing_systems',
        ]),
    },
    watch: {
        swagger_loaded(val) {
            if (val === false && this.user_is_authenticated) {
                this.get_swagger()
            }
        },
        user_is_authenticated(val) {
            console.info("user_is_authenticated", val)
            if (val === true) {
                this.get_swagger()
            }
        },
        $route(to) {
            let activity_name = to.params && to.params.definition ? to.params.definition : 'navigation'
            this.user_activity(activity_name, 'route', to.name)
        },
        async user_token_json(val, old_val) {
            if (val && old_val && val['token_id'] === old_val['token_id']) return
            await this.load_user_data()
        },
        async swagger(val) {
            if (val) {
                await this.load_partner_user_data()
            }
        }
    },
    methods: {
        toggle_bm(event) {
            this.bm_open = event
            if (!event) {
                this.$tours['navigation_tour'].stop()
            }
        },
        tour_options() {
            return {
                useKeyboardNavigation: true,
                labels: {
                    buttonSkip: this.$t("help.skip_tour"),
                    buttonPrevious: this.$t("help.previous"),
                    buttonNext: this.$t("help.next"),
                    buttonStop: this.$t("help.end_tour"),
                }
            }
        },
        help_tours() {
            // todo: Cleaner solution
            let navigation
            if (this.$vuetify.breakpoint.mdAndUp) {
                navigation = {
                    name: "navigation_tour",
                    label: "help.navigation",
                    all_routes: true,
                    steps: [
                        {
                            target: '#help_home',
                            content: this.$t("help.home"),
                            before: () => new Promise(resolve => {
                                this.user_activity('top_menu', 'help_tour', 'navigation_tour')
                                resolve()
                            })
                        },
                        {
                            target: '#help_search',
                            content: this.$t("help.search"),
                        },
                        {
                            target: '#help_help',
                            content: this.$t("help.help"),
                        },
                        {
                            target: '#help_tickets',
                            content: this.$t("help.tickets"),
                        },
                        {
                            target: '#help_favorites',
                            content: this.$t("help.favorites"),
                        },
                        {
                            target: '#help_settings',
                            content: this.$t("help.settings"),
                        },
                    ],
                }
            } else {
                navigation = {
                    name: "navigation_tour",
                    label: "help.navigation",
                    all_routes: true,
                    steps: [
                        {
                            target: '#bm_icon',
                            content: "Press here for navigation",
                            before: () => new Promise(resolve => {
                                this.user_activity('burger_menu', 'help_tour', 'navigation_tour')
                                resolve()
                            })
                        },
                        {
                            target: '#bm_help_home',
                            content: this.$t("help.home"),
                            before: () => new Promise((resolve) => {
                                this.bm_open = true
                                setTimeout(resolve, 150)
                            })
                        },
                        {
                            target: '#help_search',
                            content: this.$t("help.search"),
                            before: () => new Promise((resolve) => {
                                this.bm_open = false
                                this.$nextTick(() => {
                                    resolve()
                                })
                            })
                        },
                        {
                            target: '#help_help',
                            content: this.$t("help.help"),
                        },
                        {
                            target: '#help_tickets',
                            content: this.$t("help.tickets"),
                        },
                        {
                            target: '#help_favorites',
                            content: this.$t("help.favorites"),
                        },
                        {
                            target: '#help_settings',
                            content: this.$t("help.settings"),
                        },
                    ],
                }
            }
            return [
                navigation,
                {
                    name: "register_case_file_tour",
                    label: "help.register_case_file",
                    route_name: "RegisterCaseFile",
                    steps: [
                        {
                            target: '#help_register_case_file_step_1',
                            content: this.$t("help.register_case_file_step_1"),
                            before: () => new Promise(resolve => {
                                this.user_activity('top_menu', 'help_tour', 'register_case_file_tour')
                                resolve()
                            })
                        },
                        {
                            target: '#help_register_case_file_step_2',
                            content: this.$t("help.register_case_file_step_2"),
                        },
                        {
                            target: '#help_register_case_file_step_3',
                            content: this.$t("help.register_case_file_step_3"),
                        },
                        {
                            target: '#help_register_case_file_step_4',
                            content: this.$t("help.register_case_file_step_4"),
                        },
                        {
                            target: '#help_register_case_file_step_5',
                            content: this.$t("help.register_case_file_step_5"),
                        }
                    ],
                },
            ]
        },
        site_refreshed() {
            this.set_state_property({
                state_property: 'site_refreshes',
                data: this.site_refreshes + 1
            })
        },
        ...mapActions([
            "set_state_property",
        ]),
    },
    beforeCreate() {
    },
    async created() {
        this.load_multi_tab_data()
        const three_minutes_millis = (60 * 3 * 1000)
        let no_active_windows = !this.windows || !this.windows.length || !this.windows.filter(x => (x.tick + three_minutes_millis) > new Date().getTime()).length
        if (no_active_windows) {
            await this.refresh_token()
        }
        this.api_get(
            {
                url: `/esi-systems`,
            }
        ).then((result) => {
            if (result.status === 200) {
                let existing_systems = []
                let existing_system_details = []

                result.data.esi_systems.forEach((item) => {
                    existing_systems.push(item.system)
                    existing_system_details.push(item)
                })
                let system_esi_admin = result.data.admin_urls.find(x => x.url === window.location.origin)
                if (window.location.origin === result.data.default_admin_url.url) {
                    this.set_local_storage('is_default_admin_url', true)
                    this.set_state_property({state_property: "is_default_admin_url", data: true})

                }
                if (window.location.origin === result.data.default_admin_url.url && this.$route.query.system && this.$route.query.system.length > 0) {
                    let system = this.$route.query.system
                    if (existing_systems.includes(system)) {
                        let system_name = existing_system_details.find(x => x.system === system).system_name
                        this.set_local_storage('system', system)
                        this.set_local_storage('system_name', system_name)
                        this.set_state_property({state_property: "system", data: system})
                        this.set_state_property({state_property: "system_name", data: system_name})
                    }
                    const query = Object.assign({}, this.$route.query);
                    delete query.system;
                    this.$router.replace({query});

                    this.get_application_details()
                } else if (system_esi_admin && system_esi_admin.url !== result.data.default_admin_url.url) {
                    let system = system_esi_admin.system
                    if (existing_systems.includes(system)) {
                        let system_name = existing_system_details.find(x => x.system === system).system_name
                        this.set_local_storage('system', system)
                        this.set_local_storage('system_name', system_name)
                        this.set_state_property({state_property: "system", data: system})
                        this.set_state_property({state_property: "system_name", data: system_name})
                    }
                }
                this.set_local_storage('existing_systems', existing_systems)
                this.set_local_storage('existing_system_details', existing_system_details)
                this.set_state_property({state_property: "existing_systems", data: existing_systems})
                this.set_state_property({state_property: "existing_system_details", data: existing_system_details})
            }
        })

        if (this.user_is_authenticated) {
            if (!this.swagger_loaded) {
                this.get_swagger()
            }
            this.site_refreshed()
            this.load_user_data()
        }

        this.get_application_details()

        /*Start window management*/
        window.addEventListener('storage', this.handle_tab_synchronization)
        this.create_window()
        // this.window_process() // Init run - in order to claim primary
        // this.window_process() // Init run - in order to refresh token
        this.$nextTick(() => {
            this.window_process_interval_id = setInterval(this.window_process, 1000 * 4)
        })

    },
    beforeMount() {
        this.set_state_property({state_property: "help_tours", data: this.help_tours()});
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
};
</script>
