import {mapActions, mapGetters, mapState} from 'vuex';

export default {
    data() {
        return {
            updating_user_settings: false,
            right_to_left_locales: ['ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi'],
        }
    },
    computed: {
        current_theme_options() {
            return this.$vuetify.theme.options[this.current_theme_name]
        },
        current_theme_name() {
            return this.$vuetify.theme.isDark ? 'dark' : 'light'
        },
        themes() {
            return Object.keys(this.$vuetify.theme.themes)
        },
        ...mapGetters([]),
        ...mapState(['user_settings'])
    },
    watch: {
        user_settings(val) {
            if (val === undefined || !Object.keys(val).length) return
            let settings = val['settings']
            this.set_ui_settings(settings['ui'])
        }
    },
    methods: {
        async update_user_setting(dotted_path, value) {
            if (!this.user_settings || this.updating_user_settings) return
            this.updating_user_settings = true

            dotted_path = dotted_path.startsWith('settings') ? dotted_path : `settings.${dotted_path}`
            try {
                let user_settings_copy = JSON.parse(JSON.stringify(this.user_settings))
                let result = await this.api_patch({
                    url: `/user--settings/${user_settings_copy._id}`,
                    data: {
                        [dotted_path]: value
                    },
                    if_match: user_settings_copy._etag
                })
                if (result.status === 200) {

                    Object.keys(result.data).forEach(key => {
                        user_settings_copy[key] = result.data[key]
                    })
                    this.deep_set(user_settings_copy, dotted_path, value)

                    this.set_state_property({
                        state_property: 'user_settings',
                        data: user_settings_copy
                    })
                    console.log("updated settings")
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.updating_user_settings = false
            }
        },
        set_ui_settings(ui_settings) {
            let theme = ui_settings['theme']
            let language = ui_settings['language']

            this.set_theme(theme)
            this.set_language(language)
        },
        set_theme(theme) {
            let available_themes = Object.keys(this.$vuetify.theme.themes)
            available_themes.filter(x => x !== 'light').forEach(k => {
                this.$vuetify.theme[k] = false
            })

            if (theme === 'light') return // light theme is when all other themes are disabled

            this.$vuetify.theme[theme] = true
        },
        set_language(locale) {
            this.$i18n.locale = locale
            this.$vuetify.rtl = this.right_to_left_locales.includes(locale)
        },
        ...mapActions(['set_state_property'])
    },

}
