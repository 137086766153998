<template>
    <div
        id="icons"
    >
        <v-btn
            v-for="(x, index) in icon_bar"
            :key="index"
            @click="open_right_navigation_drawer(x)"
            :small="small_screen"
            :class="small_screen ? 'ml-2' : ''"
            icon
        >
            <v-icon
                :color="selected_menu === x ? 'primary' : x.color"
            >
                {{ x.icon }}
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import common_mixin from "@/mixins/common/mixin"

export default {
    name: "TheHeaderIconBar",
    props: ['displayed_content'],
    components: {},
    mixins: [common_mixin],
    data() {
        return {
            navigation_drawer: false,
            selected_menu: undefined,
            shortcut_code: "",
            init_search_tab: undefined,
            icon_bar: [
                {
                    'component': 'SearchMenu',
                    'icon': 'mdi-magnify'
                },
                {
                    'component': 'Favorites',
                    'icon': 'mdi-heart',
                    'color': 'app_red'
                },
                {
                    'component': 'Settings',
                    'icon': 'mdi-account-cog'
                },
                // {
                //     'component': 'Help',
                //     'icon': 'mdi-magnify'
                // }
            ]

        }
    },
    computed: {

        ...mapGetters([
            "search_menu"
        ]),
        ...mapState([]),
    },
    watch: {
        displayed_content(val) {
            this.selected_menu = val
        }
    },
    methods: {
        open_right_navigation_drawer(icon_bar_config) {
            this.$emit('open_right_navigation_drawer')
            this.$emit('right_navigation_component', icon_bar_config.component)
        },
        keyboard_pressed(e) {
            let new_letter = String.fromCharCode(e.keyCode);
            this.shortcut_code += new_letter;
            let valid_non_complete_shortcut_attempt = ((this.shortcut_code.startsWith("+") || this.shortcut_code.startsWith("++")) && this.shortcut_code.length < 4)
            if (valid_non_complete_shortcut_attempt) return

            let menu_index = this.search_menu
                .map(k => k['shortcut_code'])
                .indexOf(this.shortcut_code)
            if (menu_index > -1) {
                console.log("MENU INDEX", menu_index)
                this.set_state_property(
                    {
                        state_property: 'right_navigation_search_init_tab',
                        data: menu_index,
                    }
                )
                this.open_right_navigation_drawer({component: 'Search'})
            }
            this.shortcut_code = ''
        },
        ...mapActions([
            "set_state_property",
            'set_state_properties'
        ]),
    },
    beforeCreate() {
    },
    created() {
        window.addEventListener('keypress', this.keyboard_pressed);
    },
    beforeMount() {
    },
    mounted() {
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
        window.removeEventListener('keypress', this.keyboard_pressed);
    },
}
</script>

<style scoped>

</style>
